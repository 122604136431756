/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, colors, misc } from 'styles/utilities';

export const Dots = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${colors.mildGrey};
  border-radius: 50%;
  cursor: pointer;

  background-color: ${({ light }) => light && colors.lightGrey};

  &.selected {
    background-color: ${colors.primaryAnchorDefaultColor};
  }

  &.show {
    background-color: ${colors.green};
  }

  &.showSelected {
    background-color: ${colors.blackTransparent20};
  }

  &:hover {
    opacity: 0.6;
  }
`;

export const matricesContainer = css`
  display: flex;
  ${breakpoints({
    justifyContent: ['center', '', '', '', 'space-between']
  })};
  align-items: center;
  flex-wrap: wrap;
`;

export const matrixWrapper = css`
  max-width: 290px;
  border: ${misc.border.lightGrey};
  border-radius: ${misc.smallBorderRadius};
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const wrapper = css`
  display: flex;
  ${breakpoints({
    justifyContent: ['center', '', '', 'space-between', '']
  })};
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  border: ${misc.border.lightGrey};
  border-radius: ${misc.smallBorderRadius};
  ${breakpoints({
    width: ['100%', '', '', '', '66%']
  })};
`;

export const infoWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem 2rem;

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
