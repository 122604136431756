/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useCallback } from 'react';
// import PropTypes from 'prop-types';

// Components
import { Container, Row, Col } from 'components/Grid';
import A from 'components/htmlElements/A';
import EmptySectionsBand from 'components/EmptySectionsBand';

import { Formik, Form, Field } from 'formik';
// import * as Yup from 'yup';
import { Input, FieldLabel, FieldWrapper } from 'components/htmlElements/Fields';
import SubmissionBand from 'components/QuestionTypes/shared-components/SubmissionBand';

import { Redirect, useNavigate, useParams } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';

import LessonsContainer from 'hooks/useCourseLessons';
import useSearchQuery from 'hooks/useSearchQuery';
import { useGetLesson, useUpdateLesson } from 'api/courseLessons';
import LoaderOverlay from 'components/LoaderOverlay';

// Styling
import { bgColor, spacer } from 'styles/utilities';

const LessonForm = () => {
  const { lessons, deleteLesson } = LessonsContainer.useContainer();

  const { useParseQueryString, setQueryParams } = useSearchQuery();
  const queryStringData = useParseQueryString();

  const { updateLesson, isSuccess } = useUpdateLesson();
  const routeParams = useParams();

  // Get lesson data from api
  const [lessonId, updateLessonId] = useState(queryStringData.lessonId);
  const { data: lessonDataFromApi, status, error } = useGetLesson(lessonId);
  const [lessonData, updateLessonData] = useState(lessonDataFromApi.data || {});
  useEffect(() => {
    updateLessonData(lessonDataFromApi.data || {});
  }, [lessonDataFromApi]);

  useEffect(() => {
    updateLessonId(queryStringData.lessonId);
  }, [queryStringData.lessonId]);

  // Toggle overlay
  const [overlay, toggleOverlay] = useState(false);

  // To remove lesson from query string
  const navigate = useNavigate();
  const removeQuestionFromQueryString = useCallback(
    (updatedQueryString = queryStringData) => {
      navigate(
        `?${setQueryParams(
          {
            ...updatedQueryString
          },
          true
        )}`,
        { replace: true }
      );
    },
    [navigate, queryStringData, setQueryParams]
  );

  // Delete question function. Calling it here since it's common across all question types
  const handleLessonDelete = async (lessonIdToDelete) => {
    toggleOverlay(true);
    await deleteLesson(lessonIdToDelete);
    toggleOverlay(false);
    // Remove question from query string
    if (queryStringData.lessonId) {
      delete queryStringData.lessonId;
      removeQuestionFromQueryString(queryStringData);
    }
  };

  // Formik functions
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    await updateLesson({ lessonId: lessonData._id, lessonData: values });

    actions.setSubmitting(false);

    if (isSuccess) return true;
    return null;
  };

  if (error) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (status !== 'success' || !lessonData) {
    return <PageLoader isFetching isRelative height="400px" />;
  }

  return (
    <div css={[bgColor.mediumGrey, spacer.padT20]} className="is-relative">
      {lessonData && lessons && !lessons.length ? (
        <Container css={spacer.mrT40}>
          <Row>
            <Col>
              <EmptySectionsBand questions />
            </Col>
          </Row>
        </Container>
      ) : (
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={{
            title: lessonData.title,
            link: lessonData.link,
            description: lessonData.description
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Container>
                <Row>
                  <Col>
                    <FieldWrapper>
                      <FieldLabel htmlFor="title">Title</FieldLabel>
                      <Field as={Input} type="text" name="title" id="title" />
                    </FieldWrapper>

                    <FieldWrapper>
                      <FieldLabel htmlFor="link">Link</FieldLabel>
                      <Field as={Input} type="text" name="link" id="link" />
                    </FieldWrapper>

                    <FieldWrapper>
                      <FieldLabel htmlFor="description">Description</FieldLabel>
                      <Field as={Input} type="text" name="description" id="description" />
                    </FieldWrapper>

                    <div
                      css={[spacer.mrT20, spacer.mrLR15, spacer.mrB40]}
                      className="d-flex justify-content-end"
                    >
                      <A
                        as="span"
                        small
                        workSans
                        semiBold
                        color="red"
                        onClick={() => handleLessonDelete(lessonData._id)}
                        className="m-0"
                      >
                        Delete lesson
                      </A>
                    </div>

                    <div css={spacer.mrT20}>
                      <SubmissionBand
                        isSubmitting={isSubmitting}
                        submitForm={submitForm}
                        formSubmitCallbackUrl={`/course/${routeParams.courseId}`}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      )}
      {overlay && <LoaderOverlay />}
    </div>
  );
};

export default LessonForm;
