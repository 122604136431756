/* eslint-disable import/prefer-default-export */
import { has } from 'lodash-es';
import convertTimeDuration from 'utils/convertTimeDuration';

function getQuestionTypeKey(questionObject) {
  const questionObjectKeys = Object.keys(questionObject);
  const questionKeyIndex = questionObjectKeys.indexOf(questionObject.type);
  return questionObjectKeys[questionKeyIndex];
}

function updateTimersInQuestion(questionObject, timeUnits) {
  const updatedQuestionObject = { ...questionObject };
  const questionTypeKey = getQuestionTypeKey(updatedQuestionObject);

  if (has(updatedQuestionObject, 'type') && has(updatedQuestionObject, questionTypeKey)) {
    // Check if questionSettings & timeLimit exists
    if (
      has(updatedQuestionObject[questionTypeKey], 'questionSettings') &&
      has(updatedQuestionObject[questionTypeKey]?.questionSettings, 'timeLimit')
    ) {
      updatedQuestionObject[questionTypeKey].questionSettings.timeLimit = convertTimeDuration(
        updatedQuestionObject[questionTypeKey].questionSettings.timeLimit,
        timeUnits
      );
    }
  }
  return updatedQuestionObject;
}

// // ===========================
// function updateDeepSettings(object, keyToFind, timeUnits) {
//   const updatedObject = object;

//   // forEach(updatedObject, (value, key) => {
//   //   if (key === 'settings') {
//   //     if (value[keyToFind]) {
//   //       convertTimeDuration(value[keyToFind], timeUnits);
//   //     }
//   //   }

//   //   if (key === 'sections' || key === 'subsections') {
//   //     forEach(value, (nestedSettingsObj) => {
//   //       updateDeepSettings(nestedSettingsObj);
//   //     });
//   //   }
//   // });

//   if (updatedObject.sections) {
//     updatedObject.sections = forEach(updatedObject.sections, (sectionInfo, sectionKey) => {
//       if (sectionInfo.settings && sectionInfo.settings[keyToFind]) {
//         // eslint-disable-next-line no-param-reassign
//         sectionInfo.settings[keyToFind] = convertTimeDuration(
//           sectionInfo.settings[keyToFind],
//           timeUnits
//         );

//         // Update sub sections as well
//         if (sectionInfo.subsections) {
//           // eslint-disable-next-line no-param-reassign
//           sectionInfo.subsections = forEach(
//             sectionInfo.subsections,
//             (subSectionInfo, subSectionKey) => {
//               if (subSectionInfo.settings && subSectionInfo.settings[keyToFind]) {
//                 // eslint-disable-next-line no-param-reassign
//                 subSectionInfo.settings[keyToFind] = convertTimeDuration(
//                   subSectionInfo.settings[keyToFind],
//                   timeUnits
//                 );

//                 // Update sub sections as well
//               }
//               updatedObject.sections[subSectionKey] = subSectionInfo;
//             }
//           );
//         }
//         //
//       }
//       updatedObject.sections[sectionKey] = sectionInfo;
//     });
//   }

//   return updatedObject;
// }

// function updateTimersInSettings(settingsObject, timeUnits) {
//   let updatedSettings = settingsObject;

//   // Update on sections and sub-sections
//   updatedSettings = updateDeepSettings(updatedSettings, 'timeLimit', timeUnits);

//   if (settingsObject?.settings?.allowedExtraTime && settingsObject.settings.allowedExtraTime > 0) {
//     updatedSettings = merge({}, settingsObject, {
//       settings: {
//         allowedExtraTime:
//           settingsObject.settings.allowedExtraTimeType === EXTRA_TIME_TYPES.mins
//             ? convertTimeDuration(settingsObject.settings.allowedExtraTime, timeUnits)
//             : settingsObject.settings.allowedExtraTime
//       }
//     });
//   }

//   if (settingsObject?.settings?.timeLimit && settingsObject.settings.timeLimit > 0) {
//     updatedSettings = merge({}, settingsObject, {
//       settings: {
//         timeLimit: convertTimeDuration(settingsObject.settings.timeLimit, timeUnits)
//       }
//     });
//   }
//   return updatedSettings;
// }

export { updateTimersInQuestion };
