/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { misc, colors } from 'styles/utilities';

export const bandWrapper = css`
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: ${misc.navZindex};
  box-shadow: ${misc.boxShadow.modal};
  padding: 15px;
  background-color: ${colors.white};
`;
