/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints } from 'styles/utilities';
// import styledMap from 'styled-map';

import Wrapper from 'components/htmlElements/Fields/Wrapper';
import { VARS } from './FormUtils';

const wrapperBase = css`
  align-items: top;
  ${breakpoints({
    display: ['block', '', 'flex', '', '']
  })}
  ${Wrapper} {
    flex: 1 1 0;
    ${breakpoints({
      marginBottom: [VARS.formSpacing, '', '0px', '', '']
    })}
    &:not(:last-of-type) {
      ${breakpoints({
        marginRight: ['0px', '', VARS.formSpacing, '', '']
      })}
    }
  }
`;

export const StyledWrapper = styled.div`
  ${wrapperBase};
  margin-bottom: ${(props) => (props.singleFieldForm ? '0' : '')};
  ${Wrapper} {
    &:first-of-type {
      flex: ${(props) => (props.singleFieldForm ? '1 0 auto' : '')};
    }
    &:last-of-type {
      flex: ${(props) => (props.singleFieldForm ? '0 1 auto' : '')};
    }
  }
`;
