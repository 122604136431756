import Command from '@ckeditor/ckeditor5-core/src/command';

export default class ResponseCommand extends Command {
  execute() {
    const { editor } = this;
    const { selection } = editor.model.document;

    // @ref: https://stackoverflow.com/a/57203613
    let selectedText = Array.from(selection.getFirstRange().getItems())[0];
    selectedText = selectedText ? selectedText.data : '';

    editor.model.change((writer) => {
      // Create a <response> elment with the "name" attribute (and all the selection attributes)...
      const response = writer.createElement('response', {
        ...Object.fromEntries(selection.getAttributes()),
        name: selectedText
      });

      // ... and insert it into the document.
      editor.model.insertContent(response);

      // Put the selection on the inserted element.
      writer.setSelection(response, 'on');
    });
  }

  refresh() {
    const { model } = this.editor;
    const { selection } = model.document;

    const isAllowed = model.schema.checkChild(selection.focus.parent, 'response');

    this.isEnabled = isAllowed;
  }
}
