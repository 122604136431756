/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import QuestionsContainer from 'hooks/useCreateQuestion';

import PreviewFooter from './pageSections/PreviewFooter';
import QuestionPreviewSection from './pageSections/QuestionPreviewSection';

import './ck-editor-styles.css';

const PreviewQuestions = () => {
  const { questions } = QuestionsContainer.useContainer();

  const [questionNumber, setQuestionNumber] = useState(0);

  return (
    <div className="ck-content ck">
      <QuestionPreviewSection questionNumber={questionNumber} questions={questions} />

      <PreviewFooter
        questions={questions}
        questionNumber={questionNumber}
        setQuestionNumber={setQuestionNumber}
      />
    </div>
  );
};

export default PreviewQuestions;
