/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import H5 from 'components/htmlElements/H5';
import * as styles from './RadioButton.styles';

const RadioButton = ({ id, label, checked, handleSelectChange, disabled, ...props }) => (
  <label htmlFor={id} css={styles.radioStyles}>
    <input
      id={id}
      type="radio"
      name="radio"
      checked={checked}
      onChange={handleSelectChange}
      disabled={disabled}
      {...props}
    />
    <H5 workSans css={styles.label}>
      {label}
    </H5>
    <span className="checkmark" css={styles.checkmark} />
  </label>
);

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  handleSelectChange: PropTypes.func,
  disabled: PropTypes.bool
};

RadioButton.defaultProps = {
  label: '',
  disabled: false,
  handleSelectChange: () => {}
};

export default RadioButton;
