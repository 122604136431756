import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import SectionSettingsProvider from 'components/SectionSettingsForm/SectionSettingsContext';
import SectionSettingsForm from 'components/SectionSettingsForm';

import SubSectionSettingsProvider from 'components/SubSectionSettingsForm/SubSectionSettingsContext';
import SubSectionSettingsForm from 'components/SubSectionSettingsForm';

const SettingsProviders = (props) => (
  <SectionSettingsProvider>
    <SubSectionSettingsProvider>
      <Fragment {...props} />
      <SectionSettingsForm />
      <SubSectionSettingsForm />
    </SubSectionSettingsProvider>
  </SectionSettingsProvider>
);

SettingsProviders.propTypes = {};

export default SettingsProviders;
