import { useState, useEffect } from 'react';
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { api } from 'globals/constants';
import request from 'utils/request';
import { createContainer } from 'unstated-next';

const TESTS_FETCHING_LIMIT = 100;
export const useGetTests = ({ getAllVersions, testGroupId, isDraft, tupleKey = '' }) => {
  const payload = {};

  if (getAllVersions) payload.getAllVersions = getAllVersions;
  if (testGroupId) payload.testGroupId = testGroupId;
  if (isDraft) payload.isDraft = isDraft;

  // payload.getAllVersions = true;

  const {
    data,
    error,
    status,
    isLoading,
    isError,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['getTests', { tupleKey }],
    async ({ pageParam = 0 }) => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.tests.get,
        payload: {
          ...payload,
          orderBy: 'name',
          limit: TESTS_FETCHING_LIMIT,
          offset: pageParam
        }
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        lastPage?.data?.length ? allPages.length * TESTS_FETCHING_LIMIT : undefined
    }
  );

  // Fetch all data upfront
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  // const memoTestsData = useMemo(() => data || {}, [data]);
  const [testsData, updateTestsData] = useState(data);
  useEffect(() => {
    // if (data && data.data !== 'undefined') {
    if (data && data !== 'undefined' && data.pages.length) {
      const updatedData = data;

      // Needed since infinite query returns data in pages. Check react-query docs for more info
      const combinedPagesData = data.pages.map((pageData) => pageData.data).flat();
      updatedData.data = combinedPagesData;

      updateTestsData(updatedData);
    }
  }, [data]);

  return {
    data: testsData,
    error,
    status,
    isLoading,
    isError,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

const GetTestsContainer = createContainer(useGetTests);

export const useSetDefaultVersion = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (testId) => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.test.setCurrentVersion(testId)
      });
      return response;
    },
    {
      onSettled(newData) {
        queryClient.refetchQueries(['getTests', { tupleKey: newData.data.testGroupId }]);
      }
    }
  );

  return {
    setDefaultVersion: mutate,
    isLoading,
    isError,
    isSuccess
  };
};

export default GetTestsContainer;
