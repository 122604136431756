import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import iconSet from './selection.json';

const Icon = (props) => {
  const { color, size, icon, className } = props;
  return (
    <IcomoonReact className={className} iconSet={iconSet} color={color} size={size} icon={icon} />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Icon.defaultProps = {
  className: '',
  color: 'inherit',
  size: '32px'
};

export default Icon;
