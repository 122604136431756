/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/Grid';

import { spacer } from 'styles/utilities';
// import P from 'components/htmlElements/P';
import * as styles from '../page.styles';

const PreviewFooter = ({ questions, questionNumber, setQuestionNumber }) => {
  const handleNextQuestion = () => {
    setQuestionNumber((q) => q + 1);
  };

  const handlePrevQuestion = () => {
    setQuestionNumber((q) => q - 1);
  };

  return (
    <div css={styles.questionFooter}>
      <Container>
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={handlePrevQuestion}
            type="button"
            disabled={questionNumber === 0}
            className={`${questionNumber === 0 ? 'hide' : ''}`}
            css={styles.navBtn}
          >
            Previous
          </button>

          <div className="d-flex">
            {questions.map((question) => (
              <div
                css={[styles.previewPill, spacer.mrR10]}
                className={`${questions[questionNumber]._id === question._id && 'current'}`}
                key={question._id}
              >
                {/* <P
                  workSans
                  small
                  noMargin
                  color={
                    questions[questionNumber]._id === question._id ? colors.white : colors.black
                  }
                >
                  {index + 1}
                </P> */}
              </div>
            ))}
          </div>

          <button
            css={styles.navBtn}
            className={`${questionNumber === questions.length - 1 ? 'hide' : ''}`}
            onClick={handleNextQuestion}
            type="button"
            disabled={questionNumber === questions.length - 1}
          >
            Next
          </button>
        </div>
      </Container>
    </div>
  );
};

PreviewFooter.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionNumber: PropTypes.number.isRequired,
  setQuestionNumber: PropTypes.func.isRequired
};

export default PreviewFooter;
