/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import H3 from 'components/htmlElements/H3';
import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon/Icon';

// Styling
import { spacer } from 'styles/utilities';

const AddSectionBand = ({ buttonText, onHandleClick, title }) => (
  <Fragment>
    <div css={spacer.mrB20} className="d-flex justify-content-between align-items-center">
      <H3 workSans color="blueGrey">
        {title}
      </H3>
      <Button outline icon onClick={onHandleClick}>
        <Icon icon="add" size="20" css={spacer.mrR10} color="#2654b4" />
        {buttonText}
      </Button>
    </div>
  </Fragment>
);

AddSectionBand.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onHandleClick: PropTypes.func.isRequired
};

export default AddSectionBand;
