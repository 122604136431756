/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Components
import H4 from 'components/htmlElements/H4';
import RichTextEditor from 'components/RichTextEditor';
import { Container, Row, Col } from 'components/Grid';
import QuestionSettingsBand from 'components/QuestionTypes/shared-components/QuestionSettingsBand';
import SubmissionBand from 'components/QuestionTypes/shared-components/SubmissionBand';
import MetaData from 'components/QuestionTypes/shared-components/MetaData';
import { useUpdateQuestion } from 'api/questions';
import parse from 'html-react-parser';

// Styling
import { spacer } from 'styles/utilities';
import A from 'components/htmlElements/A';

import * as styles from '../QuestionTypes.styles';

import { getFormValues, formattedForAPI } from './helpers';
import OptionFields from './OptionFields';

const QuestionSchema = Yup.object().shape({
  // questionText: Yup.string().required('You cannot leave question empty'),
  // instructionText: Yup.string()
});

const IncasClozeQuestion = ({
  questionData,
  handleQuestionDelete,
  waterfallSettings,
  initialState
}) => {
  const [initialValues, updateInitialValues] = useState(
    getFormValues({
      questionObject: initialState
    })
  );
  useEffect(() => {
    if (initialState) {
      updateInitialValues(
        getFormValues({
          // questionDefaults: {},
          questionObject: initialState
        })
      );
    }
  }, [initialState]);

  useEffect(() => {
    // Get question info from api and call it here
    // `questionData.type === initialState.type` checks if the question on server is of same type.
    // If it is of same type, then only load data. Otherwise we assume the user is editing the question to a different type
    if (questionData && questionData.type === initialState.type) {
      updateInitialValues((prevState) =>
        getFormValues({
          questionDefaults: { ...prevState, ...waterfallSettings },
          questionObject: questionData
        })
      );
    }
  }, [initialState, initialState.type, questionData, waterfallSettings]);

  const { updateQuestion, isSuccess } = useUpdateQuestion();

  const [clozeBlanks, updateClozeBlanks] = useState([]);

  // cloze options state
  const clozeOptions = useRef([]);

  const setClozeBlanks = (questionText) => {
    // Reset the blanks on edit
    updateClozeBlanks([]);

    parse(questionText, {
      replace: (domNode) => {
        if (
          domNode.attribs &&
          Object.keys(domNode.attribs).includes('data-response-plugin-value')
        ) {
          updateClozeBlanks((prevState) => [
            ...prevState,
            domNode.attribs['data-response-plugin-value']
          ]);
        }
        return domNode;
      }
    });
  };

  const setAnswerField = (values) => {
    const updatedValues = values;
    updatedValues.answerOptions = clozeOptions.current;
    updatedValues.answer = clozeBlanks;

    // Update defaultMarks and partialMarking field as well
    updatedValues.defaultMarks = clozeBlanks.length;
    updatedValues.partialMarking = true;

    return updatedValues;
  };

  const handleSubmit = async (values, actions) => {
    actions.setValues(setAnswerField(values));

    actions.setSubmitting(true);

    const questionDataToSend = formattedForAPI(values);
    await updateQuestion({ questionId: questionData._id, questionData: questionDataToSend });

    actions.setSubmitting(false);

    if (isSuccess) return true;
    return null;
  };

  const clozeOptionHandler = useCallback((index, options) => {
    clozeOptions.current[index] = options;
  }, []);

  useEffect(() => {
    if (initialValues?.answerOptions.length > 0) {
      clozeOptions.current = initialValues.answerOptions;
    }
  }, [initialValues.answerOptions]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={QuestionSchema}
    >
      {({ values, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Container>
            <Row>
              <Col>
                <QuestionSettingsBand
                  values={values}
                  setFieldValue={setFieldValue}
                  hideDefaultMarks
                  hidePartialMarking
                />

                <div css={[styles.divider]} />

                <Field
                  name="questionText"
                  as={RichTextEditor}
                  value={values.questionText}
                  onChange={(data) => {
                    setFieldValue('questionText', data);
                    setClozeBlanks(data);
                  }}
                  showClozeButton
                />

                <H4 workSans css={spacer.mrT20}>
                  Total Marks for this question: {clozeBlanks.length}
                </H4>

                {(values.answerOptions?.length > 0 || clozeBlanks?.length > 0) && (
                  <div css={spacer.mrT40}>
                    {(values.answerOptions?.length ? values.answerOptions : clozeBlanks)?.map(
                      (value, index) => (
                        <div css={spacer.mrT20} key={value}>
                          <H4 workSans>Cloze {index + 1} options:</H4>
                          <OptionFields
                            values={values.answerOptions?.length ? value : [value]}
                            setClozeOptions={(options) => clozeOptionHandler(index, options)}
                          />
                        </div>
                      )
                    )}
                  </div>
                )}

                <div css={[styles.divider]} />

                <H4 workSans css={spacer.mrB20}>
                  Solution Explanation
                </H4>
                <Field
                  name="solutionExplaination"
                  as={RichTextEditor}
                  value={values.solutionExplaination}
                  onChange={(data) => setFieldValue('solutionExplaination', data)}
                />

                <div css={[styles.divider]} />

                <MetaData values={values} />

                <div css={[styles.sectionBorder, spacer.mrT40]} />
                <div
                  css={[spacer.mrT20, spacer.mrLR15, spacer.mrB40]}
                  className="d-flex justify-content-end"
                >
                  <A
                    as="span"
                    small
                    workSans
                    semiBold
                    color="red"
                    onClick={() => handleQuestionDelete(questionData._id)}
                    className="m-0"
                  >
                    Delete question
                  </A>
                </div>

                <SubmissionBand isSubmitting={isSubmitting} submitForm={submitForm} />
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

IncasClozeQuestion.propTypes = {
  questionData: PropTypes.object,
  handleQuestionDelete: PropTypes.func.isRequired,
  waterfallSettings: PropTypes.object,
  initialState: PropTypes.object.isRequired
};
IncasClozeQuestion.defaultProps = {
  waterfallSettings: {},
  questionData: {}
};

// ClozeQuestion.whyDidYouRender = true;
export default IncasClozeQuestion;
