/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { misc } from 'styles/utilities';

export const productCardCol = css`
  display: grid;
  grid-auto-rows: 1fr;
`;

export const coursesSection = css`
  border-top: ${misc.border.grey};
`;

export const tabsWrapper = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .tab-button {
    border: none;
  }
`;

export const productsGrid = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 20px;
`;
