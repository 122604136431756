/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';

import * as styles from './StatusPill.styles';

const StatusPill = ({ text, color }) => (
  <P className="pill d-inline-block" workSans medium css={styles.pill({ color })}>
    {text}
  </P>
);

StatusPill.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string
};

StatusPill.defaultProps = {
  color: 'navyBlue'
};

export default StatusPill;
