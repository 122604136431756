/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { customAlphabet } from 'nanoid';

import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon';
import { Input } from 'components/htmlElements/Fields';
import { FieldArray, Field } from 'formik';
import { NANO_ID_ALPHANUMERICS } from 'globals/constants';

import { spacer } from 'styles/utilities';

const nanoid = customAlphabet(NANO_ID_ALPHANUMERICS, 10);

const AnswerPartsRepeater = ({ values }) => {
  const NEW_OPTION_TEMPLATE = {
    text: '',
    shortId: nanoid()
  };

  return (
    <div>
      <FieldArray name="answerOptions">
        {({ remove, push }) => (
          <Fragment>
            {values.answerOptions.length > 0 &&
              values.answerOptions.map((answerOption, index) => (
                <div css={spacer.mrB30} className="d-flex align-items-center" key={index}>
                  <div className="w-100" css={spacer.mrR10}>
                    <Field
                      as={Input}
                      name={`answerOptions.${index}.text`}
                      value={answerOption.text}
                    />
                    <Field
                      type="hidden"
                      value={answerOption.shortId}
                      name={`answerOptions.${index}.shortId`}
                    />
                  </div>
                  <div>
                    <Button onClick={() => remove(index)} icon outline title="Delete" type="button">
                      <Icon icon="delete" size={20} />
                    </Button>
                  </div>
                </div>
              ))}
            <div css={spacer.mrT10} className="d-flex justify-content-end">
              <Button onClick={() => push(NEW_OPTION_TEMPLATE)} type="button">
                + Add option
              </Button>
            </div>
          </Fragment>
        )}
      </FieldArray>
    </div>
  );
};

AnswerPartsRepeater.propTypes = {
  values: PropTypes.object.isRequired
};

export default AnswerPartsRepeater;
