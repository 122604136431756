import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { misc } from 'styles/utilities';
import StyledInput from './StyledInput';
// import { StyledInputCSSObject } from './StyledInput';

// const Select = styled.select`
//   ${StyledInputCSSObject}
// `;

const StyledSelect = StyledInput.withComponent('select');

const Select = styled(StyledSelect, {
  shouldForwardProp: (prop) => isPropValid(prop)
})`
  -webkit-appearance: menulist-button;
  padding: ${misc.input.paddingHorizontal};
`;

export default Select;
