/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import P from 'components/htmlElements/P';

// Styling
import { spacer } from 'styles/utilities';

const TestSettingsChip = ({ setting, value }) => (
  <div css={spacer.mrT20}>
    <P small workSans color="blackTransparent60" className="m-0">
      {setting}
    </P>
    <P small workSans semiBold className="m-0">
      {value}
    </P>
  </div>
);

TestSettingsChip.propTypes = {
  setting: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default TestSettingsChip;
