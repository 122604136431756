/** @jsxImportSource @emotion/react */
import React from 'react';
// import PropTypes from 'prop-types';
import SidebarShell from 'components/SidebarShell';
// import SidebarListItem from 'components/SidebarShell/SidebarListItem';
import H4 from 'components/htmlElements/H4';
import A from 'components/htmlElements/A';
import P from 'components/htmlElements/P';
import SidebarSections from 'components/SectionSettingsForm/SidebarSections';

import CreateTestContainer from 'hooks/useCreateTest';
import { useSectionSettingsContext } from 'components/SectionSettingsForm/SectionSettingsContext';

// Styling
// import { spacer, bgColor } from 'styles/utilities';
import * as styles from './SidebarTests.styles';

const SidebarTests = () => {
  const { testSettings } = CreateTestContainer.useContainer();
  const { sections } = testSettings;

  const { addNewSection } = useSectionSettingsContext();

  return (
    <SidebarShell>
      <div className="d-flex flex-column h-100">
        <div className="d-flex justify-content-between align-items-center" css={styles.header}>
          <div>
            <H4 workSans className="m-0">
              Sections
            </H4>
            <P color="blackTransparent60" workSans className="m-0">
              {sections.length} sections
            </P>
          </div>
          <A as="span" color="lighterBlue" semiBold className="m-0" onClick={addNewSection}>
            + Add section
          </A>
        </div>
        <div css={styles.sidebarContentsWrapper}>
          <SidebarSections />
        </div>
      </div>
    </SidebarShell>
  );
};

SidebarTests.propTypes = {};

export default SidebarTests;
