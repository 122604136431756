import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

// const clozeIcon = `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 12.113v1.001h3.188v-1l-.737-.157v-1.42l1.167.014.884 2.563h2.07v-.996l-.674-.17-.742-1.817c.339-.218.606-.497.801-.835.199-.339.298-.702.298-1.089 0-.452-.112-.843-.337-1.172a2.153 2.153 0 00-.957-.766c-.414-.18-.902-.269-1.465-.269H7v1.01l.737.157v4.79L7 12.113zm3.516-2.89H9.45v-1.91h1.045c.339 0 .6.093.781.279.183.182.274.425.274.727 0 .293-.09.518-.269.674-.179.153-.434.23-.766.23z" fill="#000"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1 4a1 1 0 011-1h16a1 1 0 011 1v12a1 1 0 01-1 1H2a1 1 0 01-1-1V4zm1.5 11.5v-11h15v11h-15z" fill="#000"/></svg>`;
import alignMiddle from '@ckeditor/ckeditor5-core/theme/icons/align-middle.svg';

export default class RteNowrapUI extends Plugin {
  init() {
    const { editor } = this;
    const { t } = editor;
    // console.info('RteNowrapUI#init() got called');

    // The "placeholder" dropdown must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add('rtenowrap', (locale) => {
      // const dropdownView = createDropdown(locale);

      // // Populate the list in the dropdown with items.
      // addListToDropdown(dropdownView, getDropdownItemsDefinitions(placeholderNames));

      // dropdownView.buttonView.set({
      //   // The t() function helps localize the editor. All strings enclosed in t() can be
      //   // translated and change when the language of the editor changes.
      //   label: t('Placeholder'),
      //   tooltip: true,
      //   withText: true
      // });

      // const buttonEnabled = editor.config.get('clozeResponseConfig.enabled');

      const view = new ButtonView(locale);
      // if (buttonEnabled) {
      view.set({
        label: t('Wrap contents'),
        icon: alignMiddle,
        tooltip: true
      });
      // }

      // Disable the placeholder button when the command is disabled.
      const command = editor.commands.get('rtenowrap');
      view.bind('isEnabled').to(command);

      // Execute the command when the dropdown item is clicked (executed).
      this.listenTo(view, 'execute', (evt) => {
        editor.execute('rtenowrap', { value: evt.source.commandParam });
        editor.editing.view.focus();
      });

      return view;
    });
  }
}
