import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from 'globals/constants';
import request from 'utils/request';

const useCreateCourseSettings = () => {
  const mutation = useMutation(async (courseSettings) => {
    const response = await request({
      method: 'POST',
      endpoint: api.endpoints.course.create(),
      payload: courseSettings
    });
    return response;
  });

  const { mutateAsync, data, error, status } = mutation;
  return { createCourse: mutateAsync, data, error, status };
};

const useUpdateCourseSettings = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (dataSent) => {
      const { courseId, courseData } = dataSent;
      const response = await request({
        method: 'PATCH',
        endpoint: api.endpoints.course.update(courseId),
        payload: courseData
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryClient.invalidateQueries('course', {
          courseId: variables.courseId
        });
      }
    }
  );

  const { mutateAsync, data, error, status } = mutation;

  return { updateCourse: mutateAsync, data, error, status };
};

const useGetCourse = (courseId) => {
  const { data, status, error } = useQuery(
    [
      'course',
      {
        courseId
      }
    ],
    async () => {
      if (!courseId) return null;
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.course.findOne(courseId)
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  return { data, status, error };
};

export { useUpdateCourseSettings, useCreateCourseSettings, useGetCourse };
