/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import CourseSectionSummaryBand from 'components/CourseSectionSummaryBand';
import LoaderOverlay from 'components/LoaderOverlay';
import CourseSubSectionsSortableComponent from 'components/CourseSubSectionSettingsForm/CourseSubSectionsSortableComponent';

import CourseContainer from 'hooks/useCreateCourse';
import arrayMove from 'array-move';

import SidebarListItem from 'components/SidebarShell/SidebarListItem';
import { COURSE_STRUCTURE } from 'globals/constants';
import useSearchQuery from 'hooks/useSearchQuery';
import { navigate } from 'components/Router';
import { useSectionSettingsContext } from './CourseSectionSettingsContext';

const SortableSidebarItem = ({ section, sortableItemType }) => {
  const { courseSettings } = CourseContainer.useContainer();
  const { setQueryParams, useParseQueryString } = useSearchQuery();
  const queryStringData = useParseQueryString();

  // let subHeadingText = `${section.totalQuestions} Videos`;
  let subHeadingText = `Videos`;
  if (courseSettings.courseStructure === COURSE_STRUCTURE.subsections) {
    subHeadingText = `${section.subsections?.length || 0} subsections`;
  }

  let handleOnClick = false;
  if (courseSettings.courseStructure === COURSE_STRUCTURE.sections) {
    const queryString = setQueryParams(
      {
        sectionId: section._id
      },
      true
    );
    handleOnClick = () => {
      navigate(`?${queryString}`);
    };
  }

  return (
    <SidebarListItem
      heading={section.name}
      subHeading={subHeadingText}
      onClick={handleOnClick}
      isActive={
        courseSettings.courseStructure === COURSE_STRUCTURE.sections &&
        queryStringData.sectionId === section._id
      }
    >
      <CourseSubSectionsSortableComponent
        parentSection={section}
        sortableItemType={sortableItemType}
      />
    </SidebarListItem>
  );
};

SortableSidebarItem.propTypes = {
  section: PropTypes.object.isRequired,
  sortableItemType: PropTypes.oneOf(['band', 'sidebar']).isRequired
};

const SortableItem = SortableElement(({ section, sortableItemType }) => {
  // Updated section modal interactions
  const { editSectionSettings } = useSectionSettingsContext();

  if (sortableItemType === 'sidebar') {
    return <SortableSidebarItem section={section} sortableItemType={sortableItemType} />;
  }

  return (
    <CourseSectionSummaryBand onHandleClick={() => editSectionSettings(section._id)} data={section}>
      <CourseSubSectionsSortableComponent
        parentSection={section}
        sortableItemType={sortableItemType}
      />
    </CourseSectionSummaryBand>
  );
});

const SortableList = SortableContainer(({ sections, sortableItemType }) => (
  <div>
    {sections.map((section, index) => (
      <SortableItem
        key={`item-${section._id}`}
        index={index}
        section={section}
        sortableItemType={sortableItemType}
      />
    ))}
  </div>
));

const CourseSectionsSortableComponents = ({ sortableItemType }) => {
  const { courseSettings, updateSectionsOrder } = CourseContainer.useContainer();

  const [overlay, toggleOverlay] = useState(false);

  const [sectionsList, updateSectionsList] = useState(courseSettings.sections);
  // useEffect will update the state if any sections are updated
  useEffect(() => {
    updateSectionsList(courseSettings.sections);
  }, [courseSettings.sections]);

  const updateSectionOrder = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return false;
    }

    const updatedSectionsList = arrayMove(courseSettings.sections, oldIndex, newIndex);

    // Optimistic sections update
    updateSectionsList(updatedSectionsList);

    toggleOverlay(true);
    const data = await updateSectionsOrder(courseSettings, updatedSectionsList);

    // If API call fails, set the sections to order returned by API
    if (data) updateSectionsList(data.sections);

    toggleOverlay(false);

    return true;
  };

  return (
    <div className="is-relative">
      <SortableList
        useWindowAsScrollContainer
        axis="y"
        lockAxis="y"
        distance={2}
        sections={sectionsList}
        onSortEnd={updateSectionOrder}
        sortableItemType={sortableItemType}
      />
      {overlay && <LoaderOverlay />}
    </div>
  );
};

CourseSectionsSortableComponents.propTypes = {
  sortableItemType: PropTypes.oneOf(['band', 'sidebar']).isRequired
};

export default CourseSectionsSortableComponents;
