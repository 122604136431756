/* eslint-disable import/extensions */
import React from 'react';

import FieldError from './FieldError.js';
import FieldLabel from './FieldLabel.js';
import InlineFields from './InlineFields.js';
import Input from './Input.js';
import Select from './Select.js';
import FieldWrapper from './Wrapper.js';

const Textarea = (props) => <Input as="textarea" {...props} />;

export {
  Input,
  Textarea,
  FieldWrapper,
  FieldWrapper as Wrapper,
  FieldError,
  FieldLabel,
  InlineFields,
  Select
};
