/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

// Components
import { Col } from 'components/Grid';

// Styling
import { misc, colors } from 'styles/utilities';
import Icon from 'components/Icon';

import * as styles from './Modal.styles';

ReactModal.setAppElement('body');

export const useModal = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const [values, setValues] = useState(null);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const toggleModal = () => setIsOpen((s) => !s);

  return {
    isOpen,
    toggle: toggleModal,
    values,
    setValues,
    openModal,
    closeModal
  };
};

const defaultOverlayStyles = {
  zIndex: misc.modalZindex,
  backgroundColor: `${colors.blackTransparent60}`
};

const Modal = ({
  colConfig,
  emotionCss,
  shouldCloseOnEsc,
  toggle,
  iconSize,
  children,
  large,
  ...props
}) => (
  <ReactModal
    css={[emotionCss, styles.modalWrapper]}
    shouldCloseOnEsc={shouldCloseOnEsc || true}
    style={{ overlay: defaultOverlayStyles }}
    onRequestClose={toggle}
    shouldCloseOnOverlayClick
    {...props}
  >
    <Global styles={styles.modalGlobalStyles} />
    <Col {...colConfig} className="modal-container" css={styles.modalContainer({ large })}>
      <div css={[styles.contentsWrapper]} className="modal-contents">
        <button
          type="button"
          className="close modal-close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={toggle}
          css={styles.closeIcon}
        >
          <Icon icon="close" size={iconSize} />
        </button>
        <div className="modal-content-wrapper" css={styles.contents}>
          {children}
        </div>
      </div>
    </Col>
  </ReactModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  emotionCss: PropTypes.object,
  children: PropTypes.node.isRequired,
  shouldCloseOnEsc: PropTypes.bool,
  colConfig: PropTypes.object,
  iconSize: PropTypes.number,
  large: PropTypes.bool
};

Modal.defaultProps = {
  emotionCss: {},
  shouldCloseOnEsc: true,
  colConfig: { md: 10 },
  iconSize: 36,
  large: false
};

export default Modal;
