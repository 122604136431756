/** @jsxImportSource @emotion/react */
import React from 'react';

// Constants
import { COURSE_STRUCTURE } from 'globals/constants';
// import useSearchQuery from 'hooks/useSearchQuery';

import CourseContainer from 'hooks/useCreateCourse';
import { bgColor } from 'styles/utilities';

import LesssonsSectionHeader from './LesssonsSectionHeader';
import LessonForm from './LessonForm';

const RightSection = () => {
  const { courseSettings } = CourseContainer.useContainer();

  // const { useParseQueryString } = useSearchQuery();
  // const queryStringData = useParseQueryString();

  // // Get parent settings
  // const [waterfallSettings, updateWaterfallSettings] = useState();
  // useEffect(() => {
  //   if (queryStringData.sectionId) {
  //     const sectionIdWithFallback = queryStringData.sectionId || courseSettings.sections[0]._id;
  //     const sectionData = fetchSectionData(courseSettings, sectionIdWithFallback);
  //     updateWaterfallSettings(sectionData.markingSettings);

  //     if (queryStringData.subsectionId) {
  //       const subSectionIdWithFallback =
  //         queryStringData.subsectionId || courseSettings.sections[0].subsections[0]._id;
  //       const subsectionData = fetchSubsectionData(sectionData, subSectionIdWithFallback);
  //       updateWaterfallSettings(subsectionData.markingSettings);
  //     }
  //   } else {
  //     updateWaterfallSettings(courseSettings.markingSettings);
  //   }
  // }, [
  //   courseSettings,
  //   fetchSectionData,
  //   fetchSubsectionData,
  //   queryStringData.sectionId,
  //   queryStringData.subsectionId
  // ]);

  return (
    <div
      css={[bgColor.mediumGrey]}
      className={`w-100 ${
        courseSettings.courseStructure !== COURSE_STRUCTURE.course ? '' : 'course'
      }`}
    >
      <LesssonsSectionHeader />
      <LessonForm />
    </div>
  );
};

export default RightSection;
