import { useState, useCallback, useEffect } from 'react';
import { createContainer } from 'unstated-next';

import { useCreateLesson, useDeleteLesson } from 'api/courseLessons';

export const initialSettings = [];

const useCourseLessons = (initialstate = initialSettings) => {
  const [lessons, setLessons] = useState(initialstate);

  useEffect(() => {
    setLessons(initialstate);
  }, [initialstate]);

  const { createLesson } = useCreateLesson();
  const { deleteLesson } = useDeleteLesson();

  const createNewLesson = useCallback(
    async (settings) => {
      const { response } = await createLesson(settings);
      const { data } = response;
      return data;
    },
    [createLesson]
  );

  const deleteLesson_ = useCallback(
    async (questionId) => {
      const { response } = await deleteLesson(questionId);
      const { data } = response;

      if (data) {
        // Optimistic removal of question
        setLessons(lessons.filter((obj) => obj._id !== questionId));
      }
      return true;
    },
    [deleteLesson, lessons]
  );

  return {
    lessons,
    setLessons,
    createNewLesson,
    deleteLesson: deleteLesson_
  };
};

const LessonsContainer = createContainer(useCourseLessons);

export default LessonsContainer;
