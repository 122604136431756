/** @jsxImportSource @emotion/react */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styles/utilities';
import * as styles from './GeoGebra.styles';

const GeoGebra = ({ values }) => {
  useEffect(() => {
    const ggbApp = new window.GGBApplet(
      {
        showMenuBar: false,
        showToolBar: false,
        showAlgebraInput: false,
        showFullscreenButton: true,
        material_id: values?.geogebraApplet || '',
        scaleContainerClass: 'geogebraContainer',
        borderColor: colors.blackTransparent50
      },
      true
    );

    ggbApp.inject('ggb-element');
  }, [values?.geogebraApplet]);

  return (
    <div css={styles.appletWrapper} className="geogebraContainer">
      <div id="ggb-element" />
    </div>
  );
};

GeoGebra.propTypes = {
  values: PropTypes.object.isRequired
};

export default GeoGebra;
