/** @jsxImportSource @emotion/react */
import React from 'react';

import SidebarShell from 'components/SidebarShell';

import H4 from 'components/htmlElements/H4';
import A from 'components/htmlElements/A';
import P from 'components/htmlElements/P';
// import SidebarSections from 'components/SectionSettingsForm/SidebarSections';

import CourseContainer from 'hooks/useCreateCourse';

import { useSectionSettingsContext } from 'components/CourseSectionSettingsForm/CourseSectionSettingsContext';
import CourseSectionsSortableComponents from 'components/CourseSectionSettingsForm/CourseSectionsSortableComponent';

// Styling
// import { spacer, bgColor } from 'styles/utilities';
import * as styles from './SidebarCourses.styles';

const SidebarCourses = () => {
  const { courseSettings } = CourseContainer.useContainer();
  const { sections } = courseSettings;

  const { addNewSection } = useSectionSettingsContext();

  return (
    <SidebarShell>
      <div className="d-flex justify-content-between align-items-center" css={styles.header}>
        <div>
          <H4 workSans className="m-0">
            Sections
          </H4>
          <P color="blackTransparent60" workSans className="m-0">
            {sections.length} sections
          </P>
        </div>
        <A as="span" color="lighterBlue" semiBold className="m-0" onClick={addNewSection}>
          + Add section
        </A>
      </div>
      <CourseSectionsSortableComponents sortableItemType="sidebar" />
    </SidebarShell>
  );
};

SidebarCourses.propTypes = {};

export default SidebarCourses;
