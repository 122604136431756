/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import H4 from 'components/htmlElements/H4';
import P from 'components/htmlElements/P';

// Styling
import { spacer } from 'styles/utilities';
import * as styles from './EmptySectionsBand.styles';

const EmptySectionsBand = ({ questions }) => (
  <div css={[styles.sectionsBand, spacer.padBT60, spacer.mrB20]}>
    <H4 workSans semiBold color="blackTransparent20" className="m-0 text-center">
      No {questions ? 'questions' : 'sections'} yet!
    </H4>
    <P workSans color="blackTransparent20" className="m-0 text-center">
      Click on “Add {questions ? 'Question' : 'Section'}” or &apos;+&apos; button to add a new
      {questions ? ' question' : ' section'} here
    </P>
  </div>
);

EmptySectionsBand.propTypes = {
  questions: PropTypes.bool
};

EmptySectionsBand.defaultProps = {
  questions: false
};

export default EmptySectionsBand;
