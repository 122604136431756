import { useState, useCallback, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { findIndex, omit, find } from 'lodash-es';

import { useCreateCourseSettings, useUpdateCourseSettings } from 'api/course';
import { COURSE_STRUCTURE } from 'globals/constants';

export const initialSettings = {
  name: '',
  courseStructure: COURSE_STRUCTURE.sections,
  sections: []
};

const useCreateCourse = (initialstate = initialSettings) => {
  const [courseSettings, setCourseSettings] = useState(initialstate);

  useEffect(() => {
    setCourseSettings(initialstate);
  }, [initialstate]);

  const { createCourse } = useCreateCourseSettings();
  const { updateCourse } = useUpdateCourseSettings();

  // Create a new course
  const createNewCourse = useCallback(
    async (settings) => {
      const { response } = await createCourse(settings);
      const { data } = response;
      return data;
    },
    [createCourse]
  );

  // Edit a course
  const updateCurrentCourse = useCallback(
    async (courseId, changedSettings) => {
      const dataSent = { courseId, courseData: changedSettings };
      const { response } = await updateCourse(dataSent);
      const { data } = response;
      return data;
    },
    [updateCourse]
  );

  // Add a new section
  const addNewSection = useCallback(
    async (newSectionObject) => {
      const { response } = await updateCourse({
        courseId: courseSettings._id,
        courseData: {
          sections: [...courseSettings.sections, newSectionObject]
        }
      });
      const { data } = response;
      if (data) setCourseSettings({ ...data });
      return data;
    },
    [courseSettings._id, courseSettings.sections, updateCourse]
  );

  // Edit a section
  const updateSection = useCallback(
    async (existingSectionObject) => {
      const settingObjectIndex = findIndex(courseSettings.sections, {
        _id: existingSectionObject._id
      });
      const { sections } = courseSettings;
      sections.splice(settingObjectIndex, 1, existingSectionObject);
      const { response } = await updateCourse({
        courseId: courseSettings._id,
        courseData: {
          sections
        }
      });
      const { data } = response;
      if (data) setCourseSettings({ ...data });
      return data;
    },
    [courseSettings, updateCourse]
  );

  // Delete a section
  const deleteSection = useCallback(
    async (existingSectionObjectID) => {
      const settingObjectIndex = findIndex(courseSettings.sections, {
        _id: existingSectionObjectID
      });
      const { sections } = courseSettings;
      sections.splice(settingObjectIndex, 1);
      const { response } = await updateCourse({
        courseId: courseSettings._id,
        courseData: {
          sections
        }
      });
      const { data } = response;
      if (data) setCourseSettings({ ...data });
      return data;
    },
    [courseSettings, updateCourse]
  );

  // Add a new sub-section
  const addNewSubSection = useCallback(
    async (newSubSectionObject, sectionId) => {
      // Get parent section index
      const settingObjectIndex = findIndex(courseSettings.sections, {
        _id: sectionId
      });
      const { sections } = courseSettings;

      // Get parent section and update the subsection in it
      const parentSection = find(sections, { _id: sectionId });

      // Check if subsections array exists
      if (!parentSection.subsections) {
        parentSection.subsections = [];
      }
      parentSection.subsections = [
        ...(parentSection.subsections && parentSection.subsections),
        newSubSectionObject
      ];

      // Replace the parent section with updated section w/new sub-section
      sections.splice(settingObjectIndex, 1, parentSection);

      // Hit the api
      const { response } = await updateCourse({
        courseId: courseSettings._id,
        courseData: {
          sections
        }
      });
      const { data } = response;
      if (data) setCourseSettings({ ...data });
      return data;
    },
    [courseSettings, updateCourse]
  );

  // Edit a sub-section
  const updateSubSection = useCallback(
    async (newSubSectionObject, sectionId) => {
      // Get parent section index
      const settingObjectIndex = findIndex(courseSettings.sections, {
        _id: sectionId
      });
      const { sections } = courseSettings;

      // Get parent section & current subsection index.
      const parentSection = find(sections, { _id: sectionId });
      const subSettingsObjectIndex = findIndex(parentSection.subsections, {
        _id: newSubSectionObject._id
      });

      // Replace the edited sub-section in parent section
      parentSection.subsections.splice(subSettingsObjectIndex, 1, newSubSectionObject);

      // Replace the parent section with updated section w/new sub-section
      sections.splice(settingObjectIndex, 1, parentSection);

      // Hit the api
      const { response } = await updateCourse({
        courseId: courseSettings._id,
        courseData: {
          sections
        }
      });
      const { data } = response;
      if (data) setCourseSettings({ ...data });
      return data;
    },
    [courseSettings, updateCourse]
  );

  // Delete a sub-section
  const deleteSubSection = useCallback(
    async (subsectionId, sectionId) => {
      // Get parent section index
      const settingObjectIndex = findIndex(courseSettings.sections, {
        _id: sectionId
      });
      const { sections } = courseSettings;

      // Get parent section & current subsection index.
      const parentSection = find(sections, { _id: sectionId });
      const subSettingsObjectIndex = findIndex(parentSection.subsections, {
        _id: subsectionId
      });

      // Remove the subsection from parent section
      parentSection.subsections.splice(subSettingsObjectIndex, 1);

      // Replace the parent section in sections array
      sections.splice(settingObjectIndex, 1, parentSection);

      // Hit the api
      const { response } = await updateCourse({
        courseId: courseSettings._id,
        courseData: {
          sections
        }
      });
      const { data } = response;
      if (data) setCourseSettings({ ...data });
      return data;
    },
    [courseSettings, updateCourse]
  );

  // Update order of sections and sub-sections
  const updateSectionsOrder = useCallback(
    async (courseData, updatedSectionsOrder) => {
      const dataSent = { courseId: courseData._id, courseData: { sections: updatedSectionsOrder } };
      const { response } = await updateCourse(dataSent);

      const { data } = response;
      if (data) setCourseSettings({ ...data });
      return data;
    },
    [updateCourse]
  );

  const normalizeCourseSettings = useCallback((values, isExistingCourse = false) => {
    let data = {
      name: values.name,
      description: values.description,
      courseStructure: values.courseStructure,
      meta: {
        color: values.color
      }
    };

    if (values.courseStructure !== COURSE_STRUCTURE.course) {
      if (isExistingCourse) {
        const updatedData = omit(data, 'sections');
        return updatedData;
      }
      data = { ...data, sections: [] };
    }

    return data;
  }, []);

  const selectLessonData = useCallback((values) => {
    const lesson = {
      link: values.link,
      title: values.title,
      description: values.description,
      lessonDuration: values.duration
    };

    return lesson;
  }, []);

  // Fetch section data
  const fetchSectionData = useCallback((settings, sectionId) => {
    const sectionsArray = settings.sections;
    const sectionItem = sectionsArray.find((item) => item._id === sectionId);
    return sectionItem;
  }, []);

  // Fetch subsection data
  const fetchSubsectionData = useCallback((section, subSectionId) => {
    const subsectionArray = section.subsections;
    const subSectionItem = subsectionArray.find((item) => item._id === subSectionId);
    return subSectionItem;
  }, []);

  return {
    courseSettings,
    setCourseSettings,
    createNewCourse,
    updateCurrentCourse,

    addNewSection,
    updateSection,
    deleteSection,
    addNewSubSection,
    updateSubSection,
    deleteSubSection,
    updateSectionsOrder,

    normalizeCourseSettings,
    selectLessonData,
    fetchSectionData,
    fetchSubsectionData
  };
};

const CourseContainer = createContainer(useCreateCourse);

export default CourseContainer;
