/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/Grid';
import EmptySectionsBand from 'components/EmptySectionsBand';

import LessonsBand from 'components/LessonsBand';
import { navigate } from 'components/Router';

//
import { useSectionSettingsContext } from 'components/CourseSectionSettingsForm/CourseSectionSettingsContext';
import SettingsProviders from 'components/CourseSettingsProviders';

import CourseSectionsSortableComponent from 'components/CourseSectionSettingsForm/CourseSectionsSortableComponent';

import HeaderDetails from 'components/HeaderDetails';
import AddSectionBand from 'components/AddSectionBand';
//

import CourseContainer from 'hooks/useCreateCourse';
import LessonsContainer from 'hooks/useCourseLessons';

import { COURSE_STRUCTURE } from 'globals/constants';

import { bgColor, spacer, misc } from 'styles/utilities';

const CourseStructureAtCourse = ({ courseSettings }) => {
  const { lessons } = LessonsContainer.useContainer();

  const handleNewLessonOnClick = () => {
    navigate(`/course/${courseSettings._id}/lessons`);
  };

  return (
    <Fragment>
      <AddSectionBand
        title={`Lessons (${lessons.length})`}
        buttonText="Add Lesson"
        onHandleClick={handleNewLessonOnClick}
      />

      {lessons && lessons.length > 0 ? (
        <Fragment>
          <div
            css={[
              bgColor.white,
              spacer.padB15,
              spacer.padT20,
              spacer.padLR20,
              `border-radius: ${misc.largeBorderRadius}`,
              `border: ${misc.border.default}`
            ]}
          >
            <LessonsBand handleNewQuestionOnClick={handleNewLessonOnClick} />
          </div>
        </Fragment>
      ) : (
        <EmptySectionsBand questions />
      )}
    </Fragment>
  );
};
CourseStructureAtCourse.propTypes = {
  courseSettings: PropTypes.object.isRequired
};

const CourseStructureNotAtCourse = ({ courseSettings }) => {
  // eslint-disable-next-line no-unused-vars
  const { sections } = courseSettings;
  const { addNewSection } = useSectionSettingsContext();

  return (
    <Fragment>
      <AddSectionBand
        title={`Sections (${sections.length})`}
        buttonText="Add Section"
        onHandleClick={addNewSection}
      />

      {/* Listing Section  */}
      {sections && sections.length > 0 ? (
        <CourseSectionsSortableComponent sortableItemType="band" />
      ) : (
        <EmptySectionsBand />
      )}
    </Fragment>
  );
};
CourseStructureNotAtCourse.propTypes = {
  courseSettings: PropTypes.object.isRequired
};

const CourseOverview = () => {
  const createCourseContainer = CourseContainer.useContainer();
  const { courseSettings } = createCourseContainer;
  const { _id: courseId } = courseSettings;

  return (
    <Fragment>
      <HeaderDetails
        name={courseSettings.name}
        id={courseId}
        primaryCtaLink={`/course/${courseId}/settings`}
        isDraft={courseSettings.isDraft}
        hideVersionCta
        hideCloneCta
      />

      <section css={[spacer.padBT30, bgColor.mediumGrey]}>
        <Container>
          {/* If question structure is of course level display add questions section */}
          {courseSettings.courseStructure === COURSE_STRUCTURE.course ? (
            <CourseStructureAtCourse courseSettings={courseSettings} />
          ) : (
            <CourseStructureNotAtCourse courseSettings={courseSettings} />
          )}
        </Container>
      </section>
    </Fragment>
  );
};

const WithProviders = (props) => (
  <SettingsProviders>
    <CourseOverview {...props} />
  </SettingsProviders>
);

export default WithProviders;
