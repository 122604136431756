import React from 'react';
import PropTypes from 'prop-types';
import { useGetCourse } from 'api/course';
import { Redirect } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';
import PageLoader from 'components/PageLoader';

import CourseContainer from 'hooks/useCreateCourse';
import CreateCourse from './page';

const CourseSettingsContainer = ({ courseId }) => {
  const { data, status, error } = useGetCourse(courseId);

  if (error || (data && data.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (status !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (data) {
    const { data: courseData } = data;

    return (
      <CourseContainer.Provider initialState={courseData}>
        <CreateCourse />
      </CourseContainer.Provider>
    );
  }

  return (
    <CourseContainer.Provider>
      <CreateCourse />
    </CourseContainer.Provider>
  );
};

CourseSettingsContainer.propTypes = {
  courseId: PropTypes.string
};

CourseSettingsContainer.defaultProps = {
  courseId: null
};

export default CourseSettingsContainer;
