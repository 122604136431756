/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import { QUESTION_TYPE } from 'components/QuestionTypes/QuestionTypes';

export const getParsedText = (htmlString) => {
  const result = parse(htmlString || '');
  return result;
};

export const getQuestionType = (currentQuestion) =>
  QUESTION_TYPE.filter((item) => item.type === currentQuestion?.type)[0]?.niceName;

function Latex(props) {
  const node = React.createRef();
  const renderMath = () => {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, node.current]);
  };
  useEffect(() => {
    renderMath();
  });

  return (
    <div ref={node} {...props}>
      {props.children}
    </div>
  );
}

Latex.propTypes = {
  children: PropTypes.node.isRequired
};

export default Latex;
