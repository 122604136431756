/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './MediaThumbnail.styles';

const appendToImageUrl = (url, stringToAppend) => {
  if (url.indexOf('?') === -1) {
    return url.concat(`?${stringToAppend}`);
  }
  return url.concat(`&${stringToAppend}`);
};

const MediaThumbnail = ({ url, alt, width, hoverable, noDpr }) => (
  <div css={styles.thumbnailOuter({ width })} className={hoverable ? 'hoverable' : ''}>
    <div css={styles.thumbnailInner}>
      <img
        src={url}
        alt={alt}
        srcSet={
          !noDpr
            ? `
            ${appendToImageUrl(url, 'dpr=1')} 1x,
            ${appendToImageUrl(url, 'dpr=2')} 2x,
            ${appendToImageUrl(url, 'dpr=3')} 3x
            `
            : ''
        }
      />
    </div>
  </div>
);

MediaThumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  hoverable: PropTypes.bool,
  noDpr: PropTypes.bool
};
MediaThumbnail.defaultProps = {
  width: '200px',
  hoverable: false,
  noDpr: false
};

export default MediaThumbnail;
