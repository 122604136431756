/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const tableHeader = css`
  border-bottom: 1px solid ${colors.mildGrey};
`;

export const tableRow = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1;
  }
  > *:not(:nth-of-type(1)) {
    margin-left: 20px;
    text-align: right;
    ${'' /* flex: 1 0 ${`${50 / 3}%`}; */}
  }
`;
