/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import SectionSummaryBand from 'components/SectionSummaryBand';
import LoaderOverlay from 'components/LoaderOverlay';
import SortableSubSectionSummaryBand from 'components/SubSectionSettingsForm/SortableSubSectionSummaryBand';

import { useSectionSettingsContext } from 'components/SectionSettingsForm/SectionSettingsContext';
import CreateTestContainer from 'hooks/useCreateTest';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({ section }) => {
  // Updated section modal interactions
  const { editSectionSettings } = useSectionSettingsContext();

  return (
    <SectionSummaryBand onHandleClick={() => editSectionSettings(section._id)} data={section}>
      <SortableSubSectionSummaryBand parentSection={section} />
    </SectionSummaryBand>
  );
});

const SortableList = SortableContainer(({ sections }) => (
  <div>
    {sections.map((section, index) => (
      <SortableItem key={`item-${section._id}`} index={index} section={section} />
    ))}
  </div>
));

const SortableSectionSummaryBand = () => {
  const { testSettings, updateSectionsOrder } = CreateTestContainer.useContainer();

  const [overlay, toggleOverlay] = useState(false);

  const [sectionsList, updateSectionsList] = useState(testSettings.sections);
  // useEffect will update the state if any sections are updated
  useEffect(() => {
    updateSectionsList(testSettings.sections);
  }, [testSettings.sections]);

  const updateSectionOrder = async ({ oldIndex, newIndex }) => {
    const updatedSectionsList = arrayMove(testSettings.sections, oldIndex, newIndex);

    // Optimistic sections update
    updateSectionsList(updatedSectionsList);

    toggleOverlay(true);
    const data = await updateSectionsOrder(testSettings, updatedSectionsList);

    // If API call fails, set the sections to order returned by API
    if (data) updateSectionsList(data.sections);

    toggleOverlay(false);
  };

  return (
    <div className="is-relative">
      <SortableList
        useWindowAsScrollContainer
        axis="y"
        lockAxis="y"
        distance={2}
        sections={sectionsList}
        onSortEnd={updateSectionOrder}
      />
      {overlay && <LoaderOverlay />}
    </div>
  );
};

export default SortableSectionSummaryBand;
