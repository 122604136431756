/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import P from 'components/htmlElements/P';

// Styling
import { spacer } from 'styles/utilities';
import * as styles from './Tabs.styles';

const Tabs = ({ tabs }) => {
  const [selected, setIsSelected] = useState(0);

  const handleOnClick = (index) => {
    setIsSelected(index);
  };

  return (
    <div css={[spacer.mrBT10, styles.container]}>
      <div css={[spacer.mrB20]} className="d-flex">
        {tabs.map((tab, index) => (
          <P
            workSans
            semiBold
            small
            key={tab.tab}
            css={styles.tab}
            className={`tab ${index === selected ? 'active' : ''} `}
            onClick={() => handleOnClick(index)}
          >
            {tab.tab}
          </P>
        ))}
      </div>
      <div css={styles.contentContainer}>{tabs[selected].component}</div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Tabs;
