import { useState, useEffect, useCallback } from 'react';
import { createContainer } from 'unstated-next';

import { useCreateUser, useDeleteUser, useUpdateUser } from 'api/user';

export const usersList = [];

const useManageUsers = (initialstate = usersList) => {
  const [users, setUsers] = useState(initialstate);

  useEffect(() => {
    setUsers(initialstate);
  }, [initialstate]);

  const { createUser } = useCreateUser();
  const { deleteUser } = useDeleteUser();
  const { updateUser } = useUpdateUser();

  // add a new user
  const addNewUser = useCallback(
    async (values) => {
      const userData = { ...values, roles: [values.roles] };
      const { response } = await createUser(userData);
      const { data } = response;
      if (data) setUsers([...users, data]);
      return data;
    },
    [createUser, users]
  );

  // delete a user
  const deleteSingleUser = useCallback(
    async (userId) => {
      const { response } = await deleteUser(userId);
      const { data } = response;
      if (data) {
        const updatedUsersList = users.filter((user) => user._id !== userId);
        setUsers([...updatedUsersList]);
      }
      return data;
    },
    [deleteUser, users]
  );

  // update a user
  const updateSingleUser = useCallback(
    async (updatedUserData, userInfo) => {
      const { response } = await updateUser({
        userId: userInfo.userId,
        dataToSend: updatedUserData
      });
      const { data } = response;
      if (data) {
        const index = users.findIndex((user) => user._id === userInfo.userId);
        const newList = [...users];
        newList.splice(index, 1, data);
        setUsers([...newList]);
      }
      return data;
    },
    [users, updateUser]
  );

  return {
    users,
    setUsers,
    addNewUser,
    deleteSingleUser,
    updateSingleUser
  };
};

const ManageUserContainer = createContainer(useManageUsers);

export default ManageUserContainer;
