/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { misc, colors, mixins } from 'styles/utilities';

export const thumbnailOuter = (props) => css`
  position: relative;
  display: block;
  border-radius: ${misc.largeBorderRadius};
  border: ${misc.border.default};
  background-color: ${colors.lightGrey};
  overflow: hidden;
  width: ${props.width};
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  &.hoverable {
    cursor: pointer;
    ${mixins.transition()};
    &:hover {
      background-color: ${colors.blackTransparent10};
    }
  }
`;

export const thumbnailInner = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 100%;
    height: auto;
  }

  .hoverable & {
    ${mixins.transition()};
    &:hover {
      transform: scale(1.05);
    }
  }
`;
