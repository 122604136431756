/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { UnstyledLink } from 'components/Router';

// Components
import H4 from 'components/htmlElements/H4';
import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon';

import CourseContainer from 'hooks/useCreateCourse';

// Constants
import { COURSE_STRUCTURE } from 'globals/constants';

// Styling
import { spacer } from 'styles/utilities';
import * as styles from 'components/SectionSummaryBand/SectionSummaryBand.styles';

const CourseSectionSummaryBand = ({
  onHandleClick,
  data,
  isSubSection,
  parentSectionId,
  hideAddQuestions,
  borderless,
  children
}) => {
  const { courseSettings } = CourseContainer.useContainer();

  const handleEditClick = (event) => {
    event.preventDefault();
    onHandleClick();
  };

  const getLinkToQuestions = () => {
    let link = `lessons`;
    if (isSubSection) {
      link = `${link}?sectionId=${parentSectionId}&subsectionId=${data._id}`;
    } else {
      link = `${link}?sectionId=${data._id}`;
    }
    return link;
  };

  if (!data) return null;

  return (
    <div css={styles.band} className={borderless ? 'borderless' : ''}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <div css={spacer.mrR10} className="drag-icon-wrapper">
            <Icon icon="drag" size="20" color="grey" />
          </div>
          <div>
            <H4 workSans className="m-0">
              {data.name}
            </H4>
            <P workSans className="m-0">
              {data.description}
            </P>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <A href="#" semiBold onClick={handleEditClick}>
            Edit Details
          </A>
          {!hideAddQuestions && (
            <Fragment>
              {((courseSettings.courseStructure === COURSE_STRUCTURE.subsections && isSubSection) ||
                courseSettings.courseStructure === COURSE_STRUCTURE.sections) && (
                <Button
                  as={UnstyledLink}
                  to={getLinkToQuestions()}
                  className="flex-shrink-0"
                  css={spacer.mrL20}
                >
                  Add Questions
                </Button>
              )}
            </Fragment>
          )}
        </div>
      </div>

      {children}
    </div>
  );
};

CourseSectionSummaryBand.propTypes = {
  isSubSection: PropTypes.bool,
  onHandleClick: PropTypes.func,
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  parentSectionId: PropTypes.string,
  hideAddQuestions: PropTypes.bool,
  borderless: PropTypes.bool
};

CourseSectionSummaryBand.defaultProps = {
  isSubSection: false,
  children: null,
  parentSectionId: undefined,
  hideAddQuestions: false,
  borderless: false,
  onHandleClick: null
};

export default CourseSectionSummaryBand;
