/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Button from 'components/htmlElements/Button';
import A from 'components/htmlElements/A';
import {
  Input,
  FieldWrapper,
  FieldError,
  FieldLabel,
  Select
} from 'components/htmlElements/Fields';

import Modal, { useModal } from 'components/Modal';
import ForgotPasswordForm from 'components/ForgotPasswordForm';

import useLogin, { useGetOrganisations } from 'api/auth';
import { spacer } from 'styles/utilities';
import * as styles from './LoginForm.styles';

const Schema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email').required('Required'),
  password: Yup.string().required('Required'),
  orgId: Yup.string().required('Required')
});

const LoginForm = () => {
  const { isOpen: forgotModal, toggle: toggleForgotModal } = useModal();
  const { login: hookLogin } = useLogin();

  const { organisationsList, isSuccess } = useGetOrganisations();
  const orgListOptions =
    isSuccess &&
    organisationsList.data.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    ));

  const handleForgot = (e) => {
    e.preventDefault();
    toggleForgotModal(true);
  };

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    const loginResponse = await hookLogin(values);
    if (loginResponse.error) {
      actions.setErrors({ password: loginResponse.error });
    }

    actions.setSubmitting(false);
  };

  return (
    <Fragment>
      <div css={[spacer.mrT60, styles.loginCard]}>
        <Formik
          initialValues={{
            email: '',
            password: '',
            orgId: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={Schema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FieldWrapper>
                <FieldLabel className="placeholder" htmlFor="orgId" required>
                  Select Organisation
                </FieldLabel>
                <Field name="orgId" id="orgId" type="select" as={Select}>
                  <option value="" disabled defaultValue>
                    Select Organisation
                  </option>
                  {orgListOptions}
                </Field>
                {errors.orgId && touched.orgId && <FieldError>{errors.orgId}</FieldError>}
              </FieldWrapper>

              <FieldWrapper>
                <FieldLabel className="placeholder" htmlFor="login-email" required>
                  Email
                </FieldLabel>
                <Field type="email" name="email" id="login-email" as={Input} />
                {errors.email && touched.email && <FieldError>{errors.email}</FieldError>}
              </FieldWrapper>

              <FieldWrapper>
                <FieldLabel className="placeholder" htmlFor="login-password" required>
                  Password
                </FieldLabel>
                <Field type="password" name="password" id="login-password" as={Input} />
                {errors.password && touched.password && <FieldError>{errors.password}</FieldError>}
              </FieldWrapper>

              <div className="d-flex justify-content-between align-items-center">
                <A href="#" onClick={handleForgot}>
                  Forgot Password?
                </A>
                <Button type="submit" loading={isSubmitting}>
                  Login
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        <Modal isOpen={forgotModal} toggle={toggleForgotModal}>
          <ForgotPasswordForm />
        </Modal>
      </div>
    </Fragment>
  );
};
LoginForm.propTypes = {};

export default LoginForm;
