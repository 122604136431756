import styled from '@emotion/styled';
import styledMap from 'styled-map';
import { VARS } from './FormUtils';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: ${styledMap`
    noMargin: 0px;
    default: ${VARS.formSpacing};
  `};
`;

export default Wrapper;
