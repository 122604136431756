/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const sectionBorder = css`
  border-bottom: 1px solid ${colors.blackTransparent10};
`;

export const divider = css`
  border-bottom: 1px solid ${colors.blackTransparent10};
  margin: 40px 0;
`;
