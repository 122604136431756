/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mixins, colors, misc } from 'styles/utilities';

const getColor = (props) => {
  if (props.isDragAccept) {
    return colors.primaryLinkActive;
  }
  if (props.isDragReject) {
    return colors.red;
  }
  if (props.isDragActive) {
    return colors.primaryLinkActive;
  }
  return colors.blackTransparent20;
};

export const StyledDropzoneArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 300px;
  width: 100%;
  background-color: ${colors.mediumGrey};
  color: #bdbdbd;
  border-width: 2px;
  border-radius: ${misc.mediumBorderRadius};
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  outline: none;
  cursor: pointer;
  ${mixins.transition({ properties: 'border' })}
  &:hover {
    border-color: ${colors.primaryLinkHover};
  }
`;

export const noBorder = css`
  border: none;
`;

export const thumbnailList = css`
  max-height: 500px;
  overflow: auto;
`;
