/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

// components
import Modal from 'components/Modal';
import { Link } from 'components/Router';

import H3 from 'components/htmlElements/H3';
import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';
import niceDateFormat from 'utils/niceDateFormat';
import { useSetDefaultVersion } from 'api/tests';
import PageLoader from 'components/PageLoader';
import LoaderOverlay from 'components/LoaderOverlay';

// styling
import { spacer, txtColor } from 'styles/utilities';
import * as modalStyles from 'components/Modal/Modal.styles';
import { useVersionModalContext } from './VersionModalContext';

import * as styles from './VersionModal.styles';

const VersionModal = () => {
  const { isOpen, closeModal, versionsList, testsLoading, testName } = useVersionModalContext();

  const { setDefaultVersion, isLoading } = useSetDefaultVersion();

  const handleDefaultVersionChange = (event, testId) => {
    event.preventDefault();
    setDefaultVersion(testId);
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      {!testsLoading ? (
        <Fragment>
          <div css={modalStyles.header}>
            <H3 workSans>
              Versions - <span css={txtColor.blueGrey}>{testName}</span>
            </H3>
          </div>
          <div css={[spacer.mrLR20, spacer.mrT20]} className="is-relative">
            <div css={[styles.tableRow, styles.tableHeader]}>
              <P workSans small color="blueGrey">
                Version Name
              </P>
              <P workSans small color="blueGrey">
                Status
              </P>
              <P workSans small color="blueGrey">
                Last modified On
              </P>
              <P workSans small color="blueGrey">
                Is Default
              </P>
            </div>
            {versionsList.map((item) => (
              <div css={[styles.tableRow]} key={item._id}>
                <P workSans small color="blueGrey">
                  {item.name} - <Link to={`/test/${item._id}`}>View</Link>
                </P>
                <P workSans small color="blueGrey">
                  {item.isDraft ? 'Draft' : 'Published'}
                </P>
                <P workSans small color="blueGrey">
                  {niceDateFormat(item.updatedAt)}
                </P>
                {item.isCurrentVersion ? (
                  <P workSans small color="green">
                    Default Version
                  </P>
                ) : (
                  <P workSans small color="lighterBlue">
                    <A href="#" onClick={(e) => handleDefaultVersionChange(e, item._id)}>
                      Set as default
                    </A>
                  </P>
                )}
              </div>
            ))}

            {isLoading ? <LoaderOverlay /> : ''}
          </div>
          <div className="d-flex justify-content-end" css={[spacer.mrLR20, spacer.mrT40]}>
            <A
              semiBold
              color="lighterBlue"
              onClick={closeModal}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              Close
            </A>
          </div>
        </Fragment>
      ) : (
        <PageLoader isFetching isRelative height={400} />
      )}
    </Modal>
  );
};

export default VersionModal;
