/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import { colors } from 'styles/utilities';
import StyledInput from './StyledInput';

// Styling
const inputWrapper = css`
  position: relative;
  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }
`;

const Input = ({ iconPrefix, ...props }) => {
  if (iconPrefix)
    return (
      <div css={inputWrapper}>
        <Icon
          icon={iconPrefix}
          size="1em"
          color={colors.blackTransparent60}
          className="input-icon"
        />
        <StyledInput iconPrefix {...props} />
      </div>
    );
  return <StyledInput {...props} />;
};

Input.propTypes = {
  noMargin: PropTypes.bool,
  iconPrefix: PropTypes.node
};

Input.defaultProps = {
  noMargin: false,
  iconPrefix: ''
};

export default Input;
