import styled from '@emotion/styled';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['d-vp']),
  mixins.respondTo(misc.viewport['e-vp']),
  mixins.respondTo(misc.viewport['f-vp'])
]);

const H1 = styled.h1`
  font-family: ${typography.fonts.workSans.fontFamily};
  font-weight: ${typography.fonts.workSans.fontWeight.semiBold};
  line-height: 125%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['28px', '32px', '36px', '40px', typography.baseSizes.beta]
  })};
`;

export default H1;
