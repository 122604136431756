/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const radioStyles = css`
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    border: 2px solid ${colors.blackTransparent50};
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;

    &:checked ~ .checkmark {
      background-color: ${colors.white};
      border: 2px solid ${colors.primaryLinkActive};

      &:after {
        display: block;
      }
    }

    &:disabled ~ .checkmark {
      background-color: ${colors.white};
      border: 2px solid ${colors.blackTransparent60};

      &:after {
        display: block;
        background: ${colors.blackTransparent60};
      }
    }
  }
`;

export const checkmark = css`
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: ${colors.white};
  border-radius: 50%;
  border: 2px solid ${colors.blackTransparent60};
  transform: translateY(-50%);

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${colors.primaryLinkActive};
  }
`;

export const label = css`
  padding-left: 25px;
`;
