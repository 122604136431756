/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

const sidebarWidth = '300px';
const sidebarNavWidth = '82px';

export const wrapper = ({ top }) => css`
  width: ${sidebarWidth};
  border-right: 1px solid ${colors.blackTransparent10};
  background-color: ${colors.white};
  flex-shrink: 0;
  top: ${top}px;
  position: fixed;
  left: ${sidebarNavWidth};
  height: calc(100vh - ${`${top}px`});
  z-index: 1;
`;

export const listItem = (props) => css`
  width: 100%;
  border-bottom: 1px solid ${colors.blackTransparent10};
  background-color: ${props.isActive ? colors.secondaryLinkActive : colors.white};
  .list-wrapper-inner & {
    border-bottom: none;
  }
  padding: 15px 0 0 15px;
`;

export const listItemContent = css`
  /* border-bottom: 1px solid ${colors.blackTransparent10}; */
  padding: 0 0 15px 0;
  width: 100%;
`;

export const fauxSidebar = css`
  width: ${sidebarWidth};
  flex-shrink: 0;
`;
