/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, typography } from 'styles/utilities';

export const crumbContainer = css`
  font-family: ${typography.fonts.workSans.fontFamily};
  font-size: ${typography.baseSizes.theta};
  color: ${colors.blackTransparent60};
`;
