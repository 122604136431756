const typography = {
  fonts: {
    workSans: {
      fontFamily: `'Work Sans', sans-serif`,
      fontWeight: {
        regular: 400,
        medium: 500,
        semiBold: 600
      }
    },
    gtWalsheim: {
      fontFamily: 'GT Walsheim',
      fontWeight: {
        light: 300,
        medium: 500,
        bold: 700
      }
    },
    cooperBT: {
      fontFamily: 'Cooper BT',
      fontWeight: {
        light: 300
      }
    }
  },
  baseSizes: {
    alpha: '56px',
    beta: '48px', // H1
    gamma: '32px', // H2
    delta: '24px', // H3
    epsilon: '20px', // H4
    zeta: '18px',
    eta: '16px', // H5, H6, body, P
    theta: '14px', // P small
    iota: '12px'
  }
};

export default typography;
