/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// import PropTypes from 'prop-types';

import { useForgottenPassword, useGetOrganisations } from 'api/auth';

import H2 from 'components/htmlElements/H2';
import Button from 'components/htmlElements/Button';
import {
  Input,
  FieldWrapper,
  FieldError,
  FieldLabel,
  Select
} from 'components/htmlElements/Fields';

import * as modalStyles from 'components/Modal/Modal.styles';
import { spacer } from 'styles/utilities';

const Schema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email').required('Required'),
  orgId: Yup.string().required('Required')
});

const ForgotPasswordForm = () => {
  const [formSubmitted, updateFormSubmission] = useState({ success: false, message: '' });

  const { forgotPasswordMutation } = useForgottenPassword();
  const { organisationsList, isSuccess } = useGetOrganisations();

  const orgListOptions =
    isSuccess &&
    organisationsList.data.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    ));

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    const response = await forgotPasswordMutation(values);
    if (response.error) {
      actions.setErrors({ email: response.error.message });
    } else {
      updateFormSubmission({
        success: true,
        message: 'Please check your email for instructions'
      });
    }

    actions.setSubmitting(false);
  };

  return (
    <Fragment>
      {!formSubmitted.success ? (
        <Fragment>
          <div css={modalStyles.header}>
            <H2>Forgotten Password</H2>
          </div>
          <div css={[spacer.mrLR20, spacer.mrT20]}>
            <Formik
              onSubmit={handleSubmit}
              validationSchema={Schema}
              initialValues={{
                email: '',
                orgId: ''
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <FieldWrapper>
                    <FieldLabel className="placeholder" htmlFor="orgId" required>
                      Select Organisation
                    </FieldLabel>
                    <Field name="orgId" id="orgId" type="select" as={Select}>
                      <option value="" disabled defaultValue>
                        Select Organisation
                      </option>
                      {orgListOptions}
                    </Field>
                    {errors.orgId && touched.orgId && <FieldError>{errors.orgId}</FieldError>}
                  </FieldWrapper>

                  <FieldWrapper>
                    <FieldLabel className="placeholder" htmlFor="forgot-email" required>
                      Email
                    </FieldLabel>
                    <Field type="email" name="email" id="forgot-email" as={Input} />
                    {errors.email && touched.email && <FieldError>{errors.email}</FieldError>}
                  </FieldWrapper>

                  <FieldWrapper className="text-right">
                    <Button type="submit" loading={isSubmitting}>
                      Reset Password
                    </Button>
                  </FieldWrapper>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      ) : (
        <div css={[spacer.padLR20, spacer.padT20]}>
          <H2>{formSubmitted.message}</H2>
        </div>
      )}
    </Fragment>
  );
};

ForgotPasswordForm.propTypes = {};

export default ForgotPasswordForm;
