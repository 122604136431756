import React from 'react';
import PropTypes from 'prop-types';

import { ROUTE_PATHS, COURSE_STRUCTURE } from 'globals/constants';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';

import CourseContainer from 'hooks/useCreateCourse';
import LessonsContainer from 'hooks/useCourseLessons';
import useSearchQuery from 'hooks/useSearchQuery';

import { useGetCourse } from 'api/course';
import { useGetLessons } from 'api/courseLessons';

import CreateLessons from './page';

export const useGetCurrentCourseLevel = (courseSettings) => {
  const { getSearchQuery } = useSearchQuery();

  const getSubSectionId = () => {
    const idInURL = getSearchQuery('subsectionId');
    if (idInURL) return idInURL;

    // If it doesn't exist in URL, return the first subsection ID
    return courseSettings.sections[0].subsections[0]._id;
  };

  const getSectionId = () => {
    const idInURL = getSearchQuery('sectionId');
    if (idInURL) return idInURL;

    // If it doesn't exist in URL, return the first section ID
    return courseSettings.sections[0]._id;
  };

  if (courseSettings.courseStructure === COURSE_STRUCTURE.subsections) {
    return {
      levelId: getSubSectionId(),
      level: COURSE_STRUCTURE.subsections
    };
  }
  if (courseSettings.courseStructure === COURSE_STRUCTURE.sections) {
    return {
      levelId: getSectionId(),
      level: COURSE_STRUCTURE.sections
    };
  }
  return {
    levelId: courseSettings._id,
    level: COURSE_STRUCTURE.test
  };
};

const CreateLessonContainer = () => {
  const { courseSettings } = CourseContainer.useContainer();

  // Get info on the test structure to fetch questions accordingly
  const { levelId, level } = useGetCurrentCourseLevel(courseSettings);

  const {
    data: lessons,
    error: lessonsError,
    status: lessonsStatus
  } = useGetLessons(levelId, level);

  if (lessonsError || (lessons && lessons.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (lessonsStatus !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (lessons) {
    return (
      <LessonsContainer.Provider initialState={lessons.data}>
        <CreateLessons />
      </LessonsContainer.Provider>
    );
  }

  return null;
};

const WithTestSettingsProvider = ({ courseId }) => {
  const { data: courseData, error: courseError, status: courseStatus } = useGetCourse(courseId);

  if (courseError || (courseData && courseData.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (courseStatus !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (courseData) {
    return (
      <CourseContainer.Provider initialState={courseData.data}>
        <CreateLessonContainer />
      </CourseContainer.Provider>
    );
  }

  return null;
};

WithTestSettingsProvider.propTypes = {
  courseId: PropTypes.string.isRequired
};

export default WithTestSettingsProvider;
