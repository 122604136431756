/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { customAlphabet } from 'nanoid';

// components
import A from 'components/htmlElements/A';
import P from 'components/htmlElements/P';
import Button from 'components/htmlElements/Button';
import Checkbox from 'components/Checkbox';
import { NANO_ID_ALPHANUMERICS } from 'globals/constants';

import Modal from 'components/Modal';
import MediaUploader from 'components/MediaUploader';
import MediaThumbnail from 'components/MediaThumbnail';

import { FieldArray, Field } from 'formik';

// styling
import { spacer } from 'styles/utilities';

const nanoid = customAlphabet(NANO_ID_ALPHANUMERICS, 10);

const ImageOnlyOptionRepeater = ({ setFieldValue, values }) => {
  const NEW_OPTION_TEMPLATE = {
    url: '',
    shortId: nanoid(),
    isCorrect: false // This is only for internal app reference to set `answer` key
  };

  // Have to do a custom modal state management since there are multiple modals used on the site
  const [currentOpenModal, updateCurrentModalOpen] = useState({});

  const handleButtonClick = (event, index) => {
    console.info(currentOpenModal);
    updateCurrentModalOpen({ number: index });
  };

  const handleToggle = () => {
    updateCurrentModalOpen({ number: 'uno' }); // Put any random value in `number` since the modals open only on index from answerOptions.map
  };

  const handleImageRemoval = (index, replace) => {
    replace(index, {
      url: '',
      shortId: values.answerOptions[index].shortId,
      isCorrect: values.answerOptions[index].isCorrect
    });
  };

  return (
    <div>
      <FieldArray name="answerOptions">
        {({ remove, push, replace }) => (
          <Fragment>
            {values.answerOptions.length > 0 &&
              values.answerOptions.map((answerOption, index) => (
                <div css={spacer.mrB30} key={index}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    css={spacer.mrB10}
                  >
                    <div className="d-flex align-items-center">
                      <P workSans small semiBold>
                        Option {index + 1}
                      </P>
                      <div css={spacer.mrL30}>
                        <Field
                          as={Checkbox}
                          name={`answerOptions.${index}.isCorrect`}
                          checked={answerOption.isCorrect}
                          label="Correct answer"
                        />
                      </div>
                    </div>
                    <A semiBold color="red" as="span" onClick={() => remove(index)}>
                      Delete option
                    </A>
                  </div>

                  {answerOption.url ? (
                    <Fragment>
                      <MediaThumbnail
                        url={answerOption.url.concat('?auto=format&w=100')}
                        width="100px"
                      />
                      <A
                        onClick={() => handleImageRemoval(index, replace)}
                        as="span"
                        className="remove-image"
                      >
                        Remove Image
                      </A>
                    </Fragment>
                  ) : (
                    <Button onClick={(event) => handleButtonClick(event, index)} type="button">
                      Select Image
                    </Button>
                  )}

                  <Field name={`answerOptions.${index}.url`} type="hidden" />

                  <Modal isOpen={currentOpenModal?.number === index} toggle={handleToggle} large>
                    <MediaUploader
                      handleMediaClick={(url) => {
                        setFieldValue(`answerOptions.${index}.url`, url);
                        handleToggle();
                      }}
                    />
                  </Modal>

                  <Field
                    type="hidden"
                    value={answerOption.shortId}
                    name={`answerOptions.${index}.shortId`}
                  />
                </div>
              ))}
            <div css={spacer.mrT10} className="d-flex justify-content-end">
              <Button onClick={() => push(NEW_OPTION_TEMPLATE)} type="button">
                + Add option
              </Button>
            </div>
          </Fragment>
        )}
      </FieldArray>
    </div>
  );
};

ImageOnlyOptionRepeater.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default ImageOnlyOptionRepeater;
