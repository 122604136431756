import React, { useRef } from 'react';
import Input from 'components/htmlElements/Fields/Input';
import PropTypes from 'prop-types';
import { spacer } from 'styles/utilities';
import { cloneDeep } from 'lodash-es';
import P from 'components/htmlElements/P';

const OptionFields = ({ values, setClozeOptions }) => {
  const options = useRef(cloneDeep(values));

  const inputChangeHandler = (inputIndex, inputValue) => {
    options.current[inputIndex] = inputValue;

    setClozeOptions(options.current.filter((item) => item !== ''));
  };

  return (
    <div className="d-flex justify-items-center align-items-end">
      <div className="w-100">
        <P>Correct answer:</P>
        <Input type="text" value={values[0] ?? ''} disabled={values[0] ?? false} />
      </div>

      <Input
        type="text"
        css={spacer.mrLR20}
        defaultValue={values[1] ?? ''}
        onChange={(e) => inputChangeHandler(1, e.target.value)}
      />
      <Input
        type="text"
        defaultValue={values[2] ?? ''}
        onChange={(e) => inputChangeHandler(2, e.target.value)}
      />
    </div>
  );
};

OptionFields.propTypes = {
  values: PropTypes.array.isRequired,
  setClozeOptions: PropTypes.func.isRequired
};

export default OptionFields;
