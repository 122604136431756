/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

// Constants
import { TEST_STRUCTURE } from 'globals/constants';
import useSearchQuery from 'hooks/useSearchQuery';

import CreateTestContainer from 'hooks/useCreateTest';
import { bgColor } from 'styles/utilities';

import QuestionSectionHeader from './QuestionSectionHeader';
import QuestionTypeSettings from './QuestionTypeSettings';

const RightSection = () => {
  const { testSettings, fetchSectionData, fetchSubsectionData } =
    CreateTestContainer.useContainer();

  const { useParseQueryString } = useSearchQuery();
  const queryStringData = useParseQueryString();

  // Get parent settings
  const [waterfallSettings, updateWaterfallSettings] = useState();
  useEffect(() => {
    if (queryStringData.sectionId) {
      const sectionIdWithFallback = queryStringData.sectionId || testSettings.sections[0]._id;
      const sectionData = fetchSectionData(testSettings, sectionIdWithFallback);
      updateWaterfallSettings(sectionData.markingSettings);

      if (queryStringData.subsectionId) {
        const subSectionIdWithFallback =
          queryStringData.subsectionId || testSettings.sections[0].subsections[0]._id;
        const subsectionData = fetchSubsectionData(sectionData, subSectionIdWithFallback);
        updateWaterfallSettings(subsectionData.markingSettings);
      }
    } else {
      updateWaterfallSettings(testSettings.markingSettings);
    }
  }, [
    fetchSectionData,
    fetchSubsectionData,
    queryStringData.sectionId,
    queryStringData.subsectionId,
    testSettings
  ]);

  return (
    <div
      css={[bgColor.mediumGrey]}
      className={`w-100 ${testSettings.questionStructure !== TEST_STRUCTURE.test ? '' : 'test'}`}
    >
      <QuestionSectionHeader waterfallSettings={waterfallSettings} />
      <QuestionTypeSettings waterfallSettings={waterfallSettings} />
    </div>
  );
};

export default RightSection;
