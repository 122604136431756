/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc } from 'styles/utilities';

const sectionBorder = `1px solid ${colors.blackTransparent10}`;

export const sectionDivider = css`
  border-bottom: ${sectionBorder};
`;

export const settings = css`
  border-bottom: ${sectionBorder};
  &.no-border {
    border: none;
  }
  &.border-top {
    border-top: ${sectionBorder};
  }
  &.last-child {
    border-bottom: none;
  }
`;

export const label = css`
  display: none;
`;

export const saveSettings = css`
  background-color: ${colors.white};
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: ${misc.navZindex};
  box-shadow: ${misc.boxShadow.modal};
`;
