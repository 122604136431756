import { css } from '@emotion/react';
import styled from '@emotion/styled';
import facepaint from 'facepaint';
import isPropValid from '@emotion/is-prop-valid';

import { typography, colors, misc, mixins } from 'styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['d-vp']),
  mixins.respondTo(misc.viewport['f-vp'])
]);

const normalPtypesizes = ['12px', '14px', typography.baseSizes.eta];

export const styledP = css`
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
`;

export const BaseP = styled.p`
  ${styledP}
  font-family: ${(props) => {
    if (props.workSans) {
      return typography.fonts.workSans.fontFamily;
    }
    if (props.gtWalsheim) {
      return typography.fonts.gtWalsheim.fontFamily;
    }
    return typography.fonts.gtWalsheim.fontFamily;
  }};
  font-weight: ${(props) => {
    if (props.workSans) {
      if (props.semiBold) {
        return typography.fonts.workSans.fontWeight.semiBold;
      }
      if (props.medium) {
        return typography.fonts.workSans.fontWeight.medium;
      }
      return typography.fonts.workSans.fontWeight.regular;
    }
    if (props.gtWalsheim) {
      if (props.bold) {
        return typography.fonts.gtWalsheim.fontWeight.bold;
      }
      if (props.medium) {
        return typography.fonts.gtWalsheim.fontWeight.medium;
      }
      return typography.fonts.gtWalsheim.fontWeight.light;
    }
    return typography.fonts.gtWalsheim.fontWeight.light;
  }};
  line-height: ${(props) => (props.workSans ? '140%' : '150%')};
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  strong {
    font-weight: ${(props) =>
      props.workSans
        ? typography.fonts.workSans.fontWeight.semiBold
        : typography.fonts.gtWalsheim.fontWeight.bold};
  }
`;

const P = styled(BaseP, {
  shouldForwardProp: (prop) => isPropValid(prop)
})((props) =>
  breakPoints({
    fontSize: props.small ? [typography.baseSizes.theta, '', ''] : normalPtypesizes
  })
);

export default P;
