import React, { useContext, createContext, useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'components/Modal';

const UserModalContext = createContext();

export const useUserModalContext = () => useContext(UserModalContext);

const UserModalProvider = ({ children }) => {
  const { isOpen, openModal, closeModal } = useModal(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState();

  const openToAddUser = useCallback(() => {
    setIsEdit(false);
    openModal();
  }, [openModal]);

  const openToEditUser = useCallback(() => {
    setIsEdit(true);
    openModal();
  }, [openModal]);

  useEffect(() => {
    if (!isEdit) setUserData(null);
  }, [isEdit]);

  const value = useMemo(
    () => ({
      openModal,
      closeModal,
      isOpen,
      isEdit,
      setIsEdit,
      openToEditUser,
      openToAddUser,
      userData,
      setUserData
    }),
    [
      openModal,
      closeModal,
      isOpen,
      isEdit,
      setIsEdit,
      openToEditUser,
      openToAddUser,
      userData,
      setUserData
    ]
  );

  return <UserModalContext.Provider value={value}>{children}</UserModalContext.Provider>;
};

UserModalProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default UserModalProvider;
