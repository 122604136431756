import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ style, className, children, ...props }) => (
  <div className={`${className || ''} container`} style={style} {...props}>
    {children}
  </div>
);
Container.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
Container.defaultProps = {
  style: {},
  className: ''
};

// =============
const getRowClasses = ({ align, justify }) => {
  const classString = '';

  // Align
  const alignString = align ? ` align-items-${align}` : '';

  // Justify
  const justifyString = justify ? ` justify-content-${justify}` : '';

  return classString.concat(alignString, justifyString);
};
// =============
const Row = ({ style, className, children, align, justify, ...props }) => (
  <div
    className={`${className || ''} ${getRowClasses({ align, justify })} row`}
    style={style}
    {...props}
  >
    {children}
  </div>
);
Row.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(['normal', 'start', 'center', 'end', 'stretch']),
  justify: PropTypes.oneOf(['start', 'center', 'end', 'between', 'around', 'initial', 'inherit'])
};
Row.defaultProps = {
  style: {},
  className: '',
  align: 'normal',
  justify: 'start'
};

// =============
const getColClasses = ({ xs, sm, md, lg, xl, offset }) => {
  const classString = '';

  // Column classes
  const xsString = xs ? ` col-xs-${xs}` : '';
  const smString = sm ? ` col-sm-${sm}` : '';
  const mdString = md ? ` col-md-${md}` : '';
  const lgString = lg ? ` col-lg-${lg}` : '';
  const xlString = xl ? ` col-xl-${xl}` : '';

  // Offsets
  const offsetString =
    offset &&
    Object.keys(offset).map((breakpoint) => ` offset-${breakpoint}-${offset[breakpoint]}`);

  return classString.concat(
    xsString,
    smString,
    mdString,
    lgString,
    xlString,
    offsetString ? offsetString.join(' ') : ''
  );
};
// =============
const Col = ({ style, className, children, xs, sm, md, lg, xl, offset, pull, push, ...props }) => {
  const styles = {
    boxSizing: 'border-box',
    minHeight: '1px',
    position: 'relative',
    width: '100%',
    ...style
  };

  return (
    <div
      className={`${className || ''} ${getColClasses({
        xs,
        sm,
        md,
        lg,
        xl,
        offset
      })} col-12 col-class`}
      {...props}
      style={styles}
    >
      {children}
    </div>
  );
};
Col.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  offset: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number
  }),
  push: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number
  }),
  pull: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number
  })
};
Col.defaultProps = {
  style: {},
  className: '',
  xs: null,
  sm: null,
  md: null,
  lg: null,
  xl: null,
  offset: {},
  push: {},
  pull: {}
};

export { Container, Row, Col };
