import React from 'react';
import PropTypes from 'prop-types';
import CreateTestContainer from 'hooks/useCreateTest';
import { useGetTestSettings } from 'api/test';
import { Redirect } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';
import PageLoader from 'components/PageLoader';

import TestSettings from './page';

const TestSettingsContainer = ({ testId }) => {
  const { data, status, error } = useGetTestSettings(testId);

  if (error || (data && data.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (status !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (data) {
    const { data: testData } = data;

    // Returns date of existing test
    return (
      <CreateTestContainer.Provider initialState={testData}>
        <TestSettings />
      </CreateTestContainer.Provider>
    );
  }

  // For creating a new test
  return (
    <CreateTestContainer.Provider>
      <TestSettings />
    </CreateTestContainer.Provider>
  );
};

TestSettingsContainer.propTypes = {
  testId: PropTypes.string
};

TestSettingsContainer.defaultProps = {
  testId: null
};

export default TestSettingsContainer;
