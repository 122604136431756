import facepaint from 'facepaint';
import misc from 'styles/tokens/misc';
import { respondTo } from 'styles/helpers/mixins';

const breakPoints = facepaint([
  respondTo(misc.viewport['c-vp']), // BS equivalent = sm
  respondTo(misc.viewport['d-vp']), // BS equivalent = md
  respondTo(misc.viewport['e-vp']), // BS equivalent = lg
  respondTo(misc.viewport['f-vp']) // BS equivalent = xl
]);

export default breakPoints;
