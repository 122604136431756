/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

// components
import P from 'components/htmlElements/P';
import Icon from 'components/Icon/Icon';

// styling
import { spacer, colors } from 'styles/utilities';
import * as styles from './InlineToast.styles';

const InlineToast = ({ type, message }) => {
  let color;
  if (type === 'error') {
    color = colors.red;
  } else if (type === 'notice') {
    color = colors.orange;
  } else {
    color = colors.green;
  }
  return (
    <div css={styles.inlineErrorContainer}>
      <ToastIcon colour={color} />
      <P workSans css={[styles.errorText, spacer.mrLR0, spacer.mrBT0]} className={`${type}`}>
        {message}
      </P>
    </div>
  );
};

InlineToast.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

const ToastIcon = ({ colour }) => <Icon icon="info" color={colour} size="18" />;

ToastIcon.propTypes = {
  colour: PropTypes.string.isRequired
};

export default InlineToast;
