import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { UserInfoContainer, useValidateToken, useLogout } from 'api/auth';
import * as LogRocket from 'logrocket';

export default (props) => {
  const { validateToken } = useValidateToken();
  const { token, _id, name, email } = UserInfoContainer.useContainer();
  const logout = useLogout();

  const [didLogout, updateDidLogout] = useState(false);
  const logoutAction = useCallback(
    (data) => {
      if (data && data.status === 401) {
        logout();
        updateDidLogout(true);
        // window.location.reload();
      }
    },
    [logout]
  );

  useEffect(() => {
    async function validate() {
      if (token) {
        const { data } = await validateToken();

        if (_id && name && email) {
          // Set Logrocket info
          LogRocket.identify(_id, {
            name: `${name}`,
            email
          });
        }

        if (!didLogout) {
          logoutAction(data);
        }
      }
    }

    // Call validate() after a certain interval to allow React to set user info state in local storage
    setTimeout(() => {
      validate();
    }, 100);
  }, [validateToken, token, _id, name, email, didLogout, logoutAction]);

  return <Fragment {...props} />;
};
