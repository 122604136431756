/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Modal, { useModal } from 'components/Modal';
import MediaUploader from 'components/MediaUploader';

// CKEDITOR5
// import { CKEditor } from '@ckeditor/ckeditor5-react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from './CKEditor5';
// CKEDITOR5

// @ref: https://medium.com/@roopamg777/render-mathematical-equations-latex-using-mathjax-and-react-hooks-e69e36523fff
// function Latex(props) {
//   let node = React.createRef();
//   useEffect(() => {
//     renderMath();
//   });
//   const renderMath = () => {
//     window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, node.current]);
//   };
//   return <div ref={node}>{props.children}</div>;
// }
// <Latex>
// <div dangerouslySetInnerHTML={{ __html: text }}></div>
// </Latex>

const RichTextEditor = ({ value, onChange, name, showClozeButton }) => {
  const [currentEditor, setCurrentEditor] = useState();

  const { isOpen: mediaUploaderModalOpen, toggle: toggleMediaUploaderModal } = useModal();

  // const [valueInState, updateValueInState] = useState('');
  // useEffect(() => {
  //   updateValueInState(value);
  // }, [value]);

  // CKEDITOR5
  const handleInputChange = (event, editor) => {
    const data = editor.getData();
    // updateValueInState(data);
    onChange(data);
  };
  // CKEDITOR5

  // Insert media into editor
  const handleInsertMedia = (url) => {
    currentEditor.execute('insertCustomImage', url);

    // Close the modal
    toggleMediaUploaderModal();
  };

  return (
    <Fragment>
      {/* CKEDITOR 5 */}
      <CKEditor
        editor={ClassicEditor}
        name={name}
        data={value}
        onChange={handleInputChange}
        config={{
          initialData: value,
          imageFromGallery: {
            openGallery: (editor) => {
              setCurrentEditor(editor);
              toggleMediaUploaderModal();
            }
          },
          clozeResponseConfig: {
            enabled: showClozeButton
          }
        }}
        onInit={(editor) => {
          setCurrentEditor(editor);

          // editor.setData(value);
          // editor.execute(
          //   'insertCustomImage',
          //   'https://toppng.com/uploads/preview/file-upload-image-icon-115632290507ftgixivqp.png'
          // );
        }}
        onError={(phase, willEditorRestart) => {
          console.info(phase, willEditorRestart);
        }}
      />
      {/* CKEDITOR 5 */}
      <Modal isOpen={mediaUploaderModalOpen} toggle={toggleMediaUploaderModal} large>
        <MediaUploader handleMediaClick={handleInsertMedia} />
      </Modal>
    </Fragment>
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showClozeButton: PropTypes.bool
};

RichTextEditor.defaultProps = {
  showClozeButton: false
};

export default RichTextEditor;
