/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import hexToRgba from 'hex-to-rgba';

import { misc, colors } from 'styles/utilities';

export const profileIcon = css`
  border-radius: ${misc.smallBorderRadius};
  overflow: hidden;
  border: 2px solid ${hexToRgba(colors.black, '0.2')};
  background-color: #73aef3;
  ${'' /* margin-right: 5px; */}
`;

export const profileText = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const text = css`
  margin: 0;
  padding: 0;
  font-size: 2rem;
`;

export const image = css`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
