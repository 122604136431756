/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';
// import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import A from 'components/htmlElements/A';
import P from 'components/htmlElements/P';
import H2 from 'components/htmlElements/H2';
import Button from 'components/htmlElements/Button';
import {
  Input,
  FieldError,
  FieldLabel,
  FieldWrapper,
  Textarea
} from 'components/htmlElements/Fields';

import CourseContainer from 'hooks/useCreateCourse';

import * as modalStyles from 'components/Modal/Modal.styles';
import { spacer } from 'styles/utilities';

import { useSectionSettingsContext } from './CourseSectionSettingsContext';

const Schema = Yup.object().shape({
  name: Yup.string().required('Required')
});

const CourseSettingsForm = () => {
  const { closeModal, sectionSettings, isNewSection, hideDeleteOption } =
    useSectionSettingsContext();

  // console.info(sectionSettings);

  const { addNewSection, updateSection, deleteSection } = CourseContainer.useContainer();

  // Form submission
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    if (isNewSection) {
      await addNewSection({
        name: values.name,
        description: values.description
      });
    } else {
      await updateSection({
        _id: values._id,
        name: values.name,
        description: values.description
      });
    }
    actions.setSubmitting(false);
    closeModal();
  };

  // Handle Deletion of section
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);
  const handleDelete = async (event, sectionID) => {
    toggleDeleteConfirmation(true);
    await deleteSection(sectionID);
    toggleDeleteConfirmation(false);
    closeModal();
  };

  return (
    <Fragment>
      <div css={modalStyles.header}>
        <H2>{isNewSection ? 'Add' : 'Edit'} Section</H2>
      </div>
      <div css={[spacer.mrLR20, spacer.mrT20]}>
        <Formik
          initialValues={{
            _id: isNewSection ? false : sectionSettings?._id,
            name: isNewSection ? '' : sectionSettings?.name,
            description: isNewSection ? '' : sectionSettings?.description
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={Schema}
        >
          {({ isSubmitting, values }) => (
            <Form>
              {values._id && <Field type="hidden" name="_id" />}

              <FieldWrapper>
                <FieldLabel className="placeholder" htmlFor="name" required>
                  Name
                </FieldLabel>
                <Field as={Input} name="name" type="text" />
                <ErrorMessage component={FieldError} name="name" />
              </FieldWrapper>

              <FieldWrapper>
                <FieldLabel className="placeholder" htmlFor="description" required>
                  Description
                </FieldLabel>
                <Field as={Textarea} name="description" type="text" />
                <ErrorMessage component={FieldError} name="description" />
              </FieldWrapper>

              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {!isNewSection && !showDeleteConfirmation && !hideDeleteOption && (
                    <A
                      as="span"
                      onClick={() => toggleDeleteConfirmation(true)}
                      css={spacer.mrR20}
                      color="red"
                    >
                      Delete
                    </A>
                  )}
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <A as="span" onClick={closeModal} css={spacer.mrR20}>
                    Cancel
                  </A>
                  <Button type="submit" loading={isSubmitting}>
                    {isNewSection ? 'Add' : 'Edit'} Section
                  </Button>
                </div>
              </div>

              {showDeleteConfirmation ? (
                <div css={spacer.mrT20}>
                  <P>Are you sure? This action is irreversible</P>
                  <div className="d-flex">
                    <A as="span" onClick={(e) => handleDelete(e, values._id)} css={spacer.mrR10}>
                      Yes
                    </A>
                    <A as="span" onClick={() => toggleDeleteConfirmation(false)}>
                      No
                    </A>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

const CourseSettingsModal = (props) => {
  const { isOpen, closeModal } = useSectionSettingsContext();

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <CourseSettingsForm {...props} />
    </Modal>
  );
};

export default CourseSettingsModal;
