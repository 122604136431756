import { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { api, TEST_STRUCTURE } from 'globals/constants';
import request from 'utils/request';

const useGetLessons = (levelId, level) => {
  const payload = {};

  if (levelId) {
    if (level === TEST_STRUCTURE.subsections) {
      payload.atLevelSubsectionId = levelId;
    } else if (level === TEST_STRUCTURE.sections) {
      payload.atLevelSectionId = levelId;
    } else {
      payload.atLevelCourseId = levelId;
    }
  }

  const { data, status, error } = useQuery(
    [
      'lessons',
      {
        levelId
      }
    ],
    async () => {
      if (!levelId) return null;
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.course.lessons.get,
        payload
      });
      return response;
    }
    // {
    //   enabled,
    //   cacheTime: 1000
    // }
  );

  return { data, status, error };
};

const useGetLesson = (lessonId) => {
  const { data, status, error } = useQuery(['getLesson', { lessonId }], async () => {
    if (!lessonId) return null;
    const { response } = await request({
      method: 'GET',
      endpoint: api.endpoints.course.lessons.findOne(lessonId)
    });
    return response;
  });

  const [lessonData, updateLessonData] = useState(data);
  useEffect(() => {
    if (data) {
      updateLessonData(data);
    }
  }, [data]);

  const memoLessonData = useMemo(() => lessonData || {}, [lessonData]);

  return { data: memoLessonData, status, error };
};

const useCreateLesson = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (lessonData) => {
      const response = await request({
        method: 'POST',
        endpoint: api.endpoints.course.lessons.create,
        payload: lessonData
      });
      return response;
    },
    {
      onSettled(data, err, variables) {
        queryClient.removeQueries('lessons');
        queryClient.removeQueries(['course', { courseId: variables.courseId }]);
      }
    }
  );

  const { mutateAsync, data, error, status } = mutation;
  return { createLesson: mutateAsync, data, error, status };
};

const useUpdateLesson = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ lessonId, lessonData }) => {
      const response = await request({
        method: 'PATCH',
        endpoint: api.endpoints.course.lessons.update(lessonId),
        payload: lessonData
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        // queryClient.removeQueries('questions');

        queryClient.invalidateQueries([
          'getLesson',
          {
            lessonId: variables.lessonId
          }
        ]);
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { updateLesson: mutateAsync, data, error, isSuccess };
};

const useReorderQuestion = () => {
  const mutation = useMutation(async ({ lessonId, updatedQuestionsOrder }) => {
    const response = await request({
      method: 'POST',
      endpoint: api.endpoints.course.lessons.reorder(lessonId),
      payload: {
        data: updatedQuestionsOrder
      }
    });
    return response;
  });

  const { mutateAsync, data, error, isSuccess, isLoading } = mutation;

  return { reorderQuestion: mutateAsync, data, error, isSuccess, isLoading };
};

const useDeleteLesson = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (lessonId) => {
      const response = await request({
        method: 'DELETE',
        endpoint: api.endpoints.course.lessons.delete(lessonId)
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryClient.removeQueries('lessons');

        queryClient.invalidateQueries([
          'getLesson',
          {
            lessonId: variables.lessonId
          }
        ]);
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { deleteLesson: mutateAsync, data, error, isSuccess };
};

export {
  useGetLessons,
  useGetLesson,
  useCreateLesson,
  useUpdateLesson,
  useDeleteLesson,
  useReorderQuestion
};
