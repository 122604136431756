/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { misc } from 'styles/utilities';

export const pageHeight = css`
  min-height: 100vh;
`;

export const dropdownContent = (props) => css`
  z-index: ${misc.dropdownZindex};
  width: ${props.width || '300px'};
  padding: 0;
  &.dropdown__content {
    padding: 0;
  }
  .dropdown-segment {
    border: none;
    &:not(:last-child) {
      border: none;
    }
    padding: 5px 10px;
  }
`;
