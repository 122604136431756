// import styled from '@emotion/styled';
import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['d-vp']),
  mixins.respondTo(misc.viewport['e-vp']),
  mixins.respondTo(misc.viewport['f-vp'])
]);

const H2 = styled.h2`
  font-family: ${styledMap`
    workSans: ${typography.fonts.workSans.fontFamily};
    gtWalsheim: ${typography.fonts.gtWalsheim.fontFamily};
    default: ${typography.fonts.cooperBT.fontFamily};
  `};
  font-weight: ${(props) => {
    if (props.gtWalsheim) {
      if (props.bold) {
        return typography.fonts.gtWalsheim.fontWeight.bold;
      }
      if (props.medium) {
        return typography.fonts.gtWalsheim.fontWeight.medium;
      }
      return typography.fonts.gtWalsheim.fontWeight.light;
    }

    if (props.workSans) {
      if (props.semiBold) {
        return typography.fonts.workSans.fontWeight.semiBold;
      }
      return typography.fonts.gtWalsheim.fontWeight.regular;
    }
    return typography.fonts.cooperBT.fontWeight.light;
  }};
  line-height: 122%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: -0.38px;
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['24px', '', '26px', '28px', typography.baseSizes.gamma]
  })};
`;

export default H2;
