/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

export const inputsWrapper = css`
  width: 50%;
  max-width: 300px;
`;

export const appletWrapper = css`
  max-width: 1110px;
  max-height: 666px;
`;
