import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { api } from 'globals/constants';
import request from 'utils/request';

const useCreateUser = () => {
  const mutation = useMutation(async (userDetails) => {
    const response = await request({
      method: 'POST',
      endpoint: api.endpoints.users.create,
      payload: userDetails
    });

    return response;
  });

  const { mutateAsync, data, error, status } = mutation;
  return { createUser: mutateAsync, data, error, status };
};

const useGetAllUsers = () => {
  const { data, error, status, isLoading, isError, isSuccess } = useQuery(
    ['getUsers'],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.users.getAll
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const memoUsersData = useMemo(() => data || {}, [data]);

  return {
    data: memoUsersData,
    error,
    status,
    isLoading,
    isError,
    isSuccess
  };
};

const useDeleteUser = () => {
  const mutation = useMutation(async (userId) => {
    const response = await request({
      method: 'DELETE',
      endpoint: api.endpoints.users.delete(userId)
    });
    return response;
  });

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { deleteUser: mutateAsync, data, error, isSuccess };
};

const useUpdateUser = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (userDetails) => {
      const { userId, dataToSend } = userDetails;
      const response = await request({
        method: 'PATCH',
        endpoint: api.endpoints.users.update(userId),
        payload: dataToSend
      });
      return response;
    },
    {
      onSettled() {
        queryClient.refetchQueries(['getUsers']);
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { updateUser: mutateAsync, data, error, isSuccess };
};

export { useGetAllUsers, useCreateUser, useDeleteUser, useUpdateUser };
