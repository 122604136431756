/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// components
// import A from 'components/htmlElements/A';
import { UnstyledLink } from 'components/Router';

// styling
import * as styles from './Breadcrumb.styles';

const Breadcrumb = ({ routes }) => (
  <div css={styles.crumbContainer}>
    {routes.map((route, index) => {
      const key = route.name + index.toString();
      return (
        <Fragment key={key}>
          {route.link ? (
            <UnstyledLink to={route.link}>{route.name}</UnstyledLink>
          ) : (
            <span>{route.name}</span>
          )}
          {index === routes.length - 1 ? null : ' > '}
        </Fragment>
      );
    })}
  </div>
);

Breadcrumb.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Breadcrumb;
