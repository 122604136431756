import React from 'react';

// Solution ref: https://github.com/reach/router/issues/242
const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  // React.useEffect(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), [
  //   location.pathname
  // ]);
  return children;
};

export default ScrollToTop;
