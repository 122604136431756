/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import SettingsProviders from 'components/SettingsProviders';

// Hooks
import CreateTestContainer from 'hooks/useCreateTest';

// Constants
import { TEST_STRUCTURE } from 'globals/constants';

// Styling
import SidebarTests from 'components/SidebarTests';
import * as styles from './page.styles';

import RightSection from './pageSections/RightSection';

const CreateQuestion = () => {
  const createTestContainer = CreateTestContainer.useContainer();
  const { testSettings } = createTestContainer;

  return (
    <Fragment>
      <div className="d-flex" css={styles.pageHeight}>
        {testSettings.questionStructure !== TEST_STRUCTURE.test && <SidebarTests />}
        <RightSection />
      </div>
    </Fragment>
  );
};

const WithProviders = (props) => (
  <SettingsProviders>
    <CreateQuestion {...props} />
  </SettingsProviders>
);

export default WithProviders;
