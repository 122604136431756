/* eslint-disable import/prefer-default-export */
import { keyframes, css } from '@emotion/react';
import hexToRgba from 'hex-to-rgba';

import { colors } from 'styles/utilities';

const loaderSize = '1em';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

export const loaderOverlay = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.blackTransparent20};
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-bottom: ${loaderSize} solid ${hexToRgba(colors.white, '0.4')};
    border-left: ${loaderSize} solid ${hexToRgba(colors.white, '0.4')};
    border-right: ${loaderSize} solid ${hexToRgba(colors.white, '0.4')};
    border-top: ${loaderSize} solid ${colors.navyBlue};
    border-radius: 100%;
    top: 50%;
    left: 50%;
    margin-top: -${loaderSize};
    margin-left: -${loaderSize};
    animation: ${rotate} 0.75s infinite linear;
  }
`;
