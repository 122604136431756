import React, { Fragment } from 'react';
import SectionSettingsProvider from 'components/CourseSectionSettingsForm/CourseSectionSettingsContext';
import SectionSettingsForm from 'components/CourseSectionSettingsForm';

import SubSectionSettingsProvider from 'components/CourseSubSectionSettingsForm/CourseSubSectionSettingsContext';
import SubSectionSettingsForm from 'components/CourseSubSectionSettingsForm';

const SettingsProviders = (props) => (
  <SectionSettingsProvider>
    <SubSectionSettingsProvider>
      <Fragment {...props} />
      <SectionSettingsForm />
      <SubSectionSettingsForm />
    </SubSectionSettingsProvider>
  </SectionSettingsProvider>
);

export default SettingsProviders;
