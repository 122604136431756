/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import H2 from 'components/htmlElements/H2';
import Button from 'components/htmlElements/Button';
import { Row, Col } from 'components/Grid';

import { spacer } from 'styles/utilities';
import { Input } from 'components/htmlElements/Fields';
// import StyledSelect from 'components/htmlElements/Fields/StyledSelect';
import ProductBand from 'components/ProductBand/ProductBand';
import H4 from 'components/htmlElements/H4';
import * as styles from './page.styles';

const tabs = [
  {
    label: 'All Products',
    key: 'all'
  },
  {
    label: 'Tests',
    key: 'tests'
  },
  {
    label: 'Courses',
    key: 'courses'
  }
];

const HomePage = ({ tests, courses }) => {
  const [activeTab, setActiveTab] = useState('all');
  // const [activeFilter, setActiveFilter] = useState('all');
  const [query, setQuery] = useState('');
  const timerRef = useRef(null);
  const allProducts = useMemo(
    () => [...tests, ...courses.map((course) => ({ ...course, isCourse: true }))],
    [courses, tests]
  );

  const productsToRender = useMemo(
    () =>
      (activeTab === 'all'
        ? allProducts
        : activeTab === 'tests'
        ? tests.map((test) => ({ ...test, isCourse: false }))
        : courses.map((course) => ({ ...course, isCourse: true }))
      )
        // .filter(
        //   (product) =>
        //     activeFilter === 'all' ||
        //     (activeFilter === 'draft' && product.isDraft) ||
        //     (activeFilter === 'published' && !product.isDraft)
        // )
        .filter((product) => product?.name.toLowerCase().includes(query.toLowerCase())),
    [activeTab, allProducts, courses, query, tests]
  );

  const inputSearchHandler = (e) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setQuery(e.target.value);
    }, 500);
  };

  return (
    <div css={[spacer.padT30, spacer.padLR40]}>
      <Row className="justify-content-between align-items-start" css={spacer.mrB30}>
        <Col css={spacer.mrB10} className="col-12 col-md-3">
          <H2 as="h1" workSans semiBold className="m-0">
            Dashboard
          </H2>
        </Col>

        <Col css={spacer.mrB10} className="col-12 col-md-5">
          <Input
            type="text"
            iconPrefix="search"
            placeholder="Search for tests or courses"
            onChange={inputSearchHandler}
          />
        </Col>
      </Row>

      <Row className="justify-content-between align-items-center" css={spacer.mrB20}>
        <Col className="col-12 col-md-6" css={[styles.tabsWrapper]}>
          {tabs.map((tab) => (
            <Button
              key={tab.key}
              medium
              outline
              onClick={() => setActiveTab(tab.key)}
              className={`tab-button ${activeTab === tab.key ? 'active' : ''}`}
            >
              {tab.label}
            </Button>
          ))}
        </Col>

        {/* <Col className="col-12 col-md-3" css={spacer.mrB20}>
          <StyledSelect
            label="Select"
            placeholder="All Products"
            options={[
              { value: 'all', option: 'All Products' },
              { value: 'draft', option: 'Draft' },
              { value: 'published', option: 'Published' }
            ]}
            setValue={setActiveFilter}
            activeState={activeFilter}
          />
        </Col> */}
      </Row>

      <div css={spacer.padB60}>
        {productsToRender?.length > 0 ? (
          <div css={styles.productsGrid}>
            {productsToRender.map((product) => (
              <div key={product._id} className="w-100 h-100">
                <ProductBand
                  product={product}
                  productLink={product.isCourse ? `course/${product._id}` : `test/${product._id}`}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center" css={spacer.padBT40}>
            <H4 workSans medium color="blackTransparent50">
              No products found, try changing the filters or refreshing the page.
            </H4>
          </div>
        )}
      </div>
    </div>
  );
};

HomePage.propTypes = {
  tests: PropTypes.array,
  courses: PropTypes.array
};

HomePage.defaultProps = {
  tests: [],
  courses: []
};

export default HomePage;
