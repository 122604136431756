import Command from '@ckeditor/ckeditor5-core/src/command';
import getSelectedContent from '@ckeditor/ckeditor5-engine/src/model/utils/getselectedcontent';

// @ref: https://stackoverflow.com/a/68649991/2623613
// This helps to get html from selection
function getSelectedHtml(ed) {
  const {
    model,
    data,
    model: {
      document: { selection }
    }
  } = ed;
  const range = selection.getFirstRange();
  if (range.end.isEqual(range.start)) {
    return '';
  }
  const selected = getSelectedContent(model, selection);
  return data.stringify(selected);
}

export default class RteNowrapCommand extends Command {
  execute() {
    const { editor } = this;
    const { selection } = editor.model.document;

    // @ref: https://stackoverflow.com/a/57203613
    // let selectedText = Array.from(selection.getFirstRange().getItems())[0];
    // selectedText = selectedText ? selectedText.data : '';

    const selectedHtml = getSelectedHtml(editor);

    editor.model.change((writer) => {
      // Create a <response> elment with the "name" attribute (and all the selection attributes)...
      const rtenowrap = writer.createElement('rtenowrap', {
        ...Object.fromEntries(selection.getAttributes()),
        name: selectedHtml
      });

      // ... and insert it into the document.
      editor.model.insertContent(rtenowrap);

      // Put the selection on the inserted element.
      writer.setSelection(rtenowrap, 'on');
    });
  }

  refresh() {
    const { model } = this.editor;
    const { selection } = model.document;

    const isAllowed = model.schema.checkChild(selection.focus.parent, 'rtenowrap');

    this.isEnabled = isAllowed;
  }
}
