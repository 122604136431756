import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import './nprogress.css';

NProgress.configure({ minimum: 0.4 });

const Loader = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);
  return <div />;
};

export default Loader;
