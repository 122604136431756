/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc, typography } from 'styles/utilities';

export const toggleContainer = css`
  border-radius: ${misc.smallBorderRadius};
  background-color: ${colors.white};
  display: flex;
  cursor: pointer;
  border: 2px solid ${colors.mediumGrey};
  position: relative;
  padding: 2px 2px;
  outline: none;
`;

export const backgroundAnimation = css`
  &.background {
    z-index: 1;
    position: absolute;
    background: ${colors.grey};
    height: calc(100% - 4px);
    width: calc(50% - 2px);
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    border-radius: ${misc.smallerBorderRadius};
  }
  &.open {
    transform: translateX(0%);
    background: ${colors.green};
  }
`;

export const stateContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${misc.smallerBorderRadius};
  transition: ${misc.transitions};
  flex: 1;
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  .state {
    font-size: ${typography.baseSizes.iota};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 2;
    &.open {
      color: ${colors.white};
    }
  }
`;
