/** @jsxImportSource @emotion/react */
import A from 'components/htmlElements/A';
import H4 from 'components/htmlElements/H4';
import H5 from 'components/htmlElements/H5';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { spacer } from 'styles/utilities';
import { FieldArray } from 'formik';
import * as styles from './Matrices.styles';

const SelectPatternMatrix = ({ values, setFieldValue }) => {
  const selectHandler = (item, push, remove) => {
    if (values.selectedItems.includes(item)) {
      const itemIndex = values.selectedItems.indexOf(item);
      remove(itemIndex);
    } else {
      push(item);
    }
  };

  return (
    <FieldArray name="selectedItems">
      {({ push, remove }) => (
        <Fragment>
          <div css={styles.matricesContainer}>
            {/* SelectedPattern Pattern Matrix */}
            <div className="text-center" css={spacer.padBT30}>
              <H4 workSans css={spacer.mrB20}>
                Step 1 : Select Pattern
              </H4>
              <div css={[styles.matrixWrapper, spacer.mrB20]}>
                {[...Array(100)].map((_, i) => (
                  <styles.Dots
                    css={[spacer.mrBT5, spacer.mrLR5]}
                    key={`item-${i.toString()}`}
                    className={values.selectedItems.includes(i) ? 'selected' : ''}
                    onClick={() => selectHandler(i, push, remove)}
                  />
                ))}
              </div>

              <A
                as={H5}
                color="lighterBlue"
                semiBold
                onClick={() => setFieldValue('selectedItems', [])}
              >
                Clear Pattern
              </A>
            </div>
          </div>
        </Fragment>
      )}
    </FieldArray>
  );
};

const ShowPatternMatrix = ({ values, setFieldValue }) => {
  const showPatternHandler = (item, push, remove, removeAnswer) => {
    if (values.shownItems.includes(item)) {
      const itemIndex = values.shownItems.indexOf(item);
      remove(itemIndex);

      if (values.answer.includes(item)) {
        const itemIndexAnswerArray = values.answer.indexOf(item);
        removeAnswer(itemIndexAnswerArray);
      }
    } else {
      push(item);
    }
  };

  return (
    <FieldArray name="shownItems">
      {({ push, remove }) => (
        <Fragment>
          <div className="text-center m-auto" css={spacer.padB30}>
            <H4 workSans css={spacer.mrB20} className="w-75 m-auto">
              Step 2.A : Select dots to show
            </H4>

            <div css={[styles.matrixWrapper, spacer.mrB20]}>
              <FieldArray name="answer">
                {({ remove: removeAnswer }) => (
                  <Fragment>
                    {[...Array(100)].map((_, i) => (
                      <styles.Dots
                        css={[spacer.mrBT5, spacer.mrLR5]}
                        key={`item-${i.toString()}`}
                        className={values.shownItems.includes(i) ? 'show' : ''}
                        onClick={() => showPatternHandler(i, push, remove, removeAnswer)}
                      />
                    ))}
                  </Fragment>
                )}
              </FieldArray>
            </div>

            <A as={H5} color="lighterBlue" semiBold onClick={() => setFieldValue('shownItems', [])}>
              Clear Pattern
            </A>
          </div>
        </Fragment>
      )}
    </FieldArray>
  );
};

const AnswerMatrix = ({ values, setFieldValue }) => {
  const setAnswerHandler = (item, push, remove) => {
    if (values.shownItems.includes(item)) {
      if (values.answer.includes(item)) {
        const itemIndex = values.answer.indexOf(item);
        remove(itemIndex);
      } else {
        push(item);
      }
    }
  };

  const showAnswer = (item) => {
    if (values.shownItems.includes(item) && values.answer.includes(item)) return 'selected';
    if (values.shownItems.includes(item)) {
      return 'showSelected';
    }
    return null;
  };

  return (
    <FieldArray name="answer">
      {({ push, remove }) => (
        <Fragment>
          <div className="text-center m-auto" css={spacer.padB30}>
            <H4 workSans css={spacer.mrB20} className="w-75 m-auto">
              Step 2.B : Select correct answer
            </H4>

            <div css={[styles.matrixWrapper, spacer.mrB20]} className="mx-auto">
              {[...Array(100)].map((_, i) => (
                <styles.Dots
                  css={[spacer.mrBT5, spacer.mrLR5]}
                  key={`item-${i.toString()}`}
                  light
                  className={showAnswer(i)}
                  onClick={() => setAnswerHandler(i, push, remove)}
                />
              ))}
            </div>

            <A as={H5} color="lighterBlue" semiBold onClick={() => setFieldValue('answer', [])}>
              Clear Pattern
            </A>
          </div>
        </Fragment>
      )}
    </FieldArray>
  );
};

const Matrices = ({ values, setFieldValue }) => (
  <div>
    <div css={styles.matricesContainer}>
      <SelectPatternMatrix values={values} setFieldValue={setFieldValue} />

      <div css={[styles.wrapper, spacer.padT20, spacer.padLR40]}>
        <ShowPatternMatrix values={values} setFieldValue={setFieldValue} />
        <AnswerMatrix values={values} setFieldValue={setFieldValue} />
      </div>
    </div>

    <div css={[spacer.mrT30, styles.infoWrapper]}>
      <div className="info">
        <styles.Dots className="showSelected" css={spacer.mrR10} />
        <H4 workSans>Unselected</H4>
      </div>

      <div className="info">
        <styles.Dots className="selected" css={spacer.mrR10} />

        <H4 workSans>Primary Pattern</H4>
      </div>

      <div className="info">
        <styles.Dots className="show" css={spacer.mrR10} />

        <H4 workSans>Dots to Display</H4>
      </div>
    </div>
  </div>
);

export default Matrices;

Matrices.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

SelectPatternMatrix.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

ShowPatternMatrix.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

AnswerMatrix.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};
