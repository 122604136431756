/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import P from 'components/htmlElements/P';
import { spacer } from 'styles/utilities';
import H5 from 'components/htmlElements/H5';
import { Link } from 'components/Router';
import Button from 'components/htmlElements/Button';
import * as styles from './ProductBand.styles';

const ProductBand = ({ product, productLink }) => {
  const { name, noOfSections, noOfSubsections, meta } = product;

  return (
    <Link to={productLink} className="d-block h-100 w-100">
      <div
        css={[spacer.padLR20, spacer.padBT20, styles.bandContainer({ customColor: meta?.color })]}
        className="h-100 w-100 d-flex flex-column justify-content-between"
      >
        <div>
          <div>
            <H5 workSans semiBold>
              {name}
            </H5>
            <P small workSans medium color="blackTransparent60">
              {noOfSections} sections, {noOfSubsections} subsections
            </P>
          </div>
        </div>

        <Button secondary medium className="w-100 CTA">
          View Details
        </Button>
      </div>
    </Link>
  );
};

ProductBand.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    noOfSections: PropTypes.number.isRequired,
    noOfSubsections: PropTypes.number.isRequired,
    isDraft: PropTypes.bool.isRequired,
    meta: PropTypes.object
  }),
  productLink: PropTypes.string.isRequired
};

ProductBand.defaultProps = {
  product: {
    meta: {}
  }
};

export default ProductBand;
