/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { findKey } from 'lodash-es';

import AddSectionBand from 'components/AddSectionBand';
import SectionSummaryBand from 'components/SectionSummaryBand';
import LoaderOverlay from 'components/LoaderOverlay';
import CreateTestContainer from 'hooks/useCreateTest';
import { useSubSectionSettingsContext } from 'components/SubSectionSettingsForm/SubSectionSettingsContext';

// Constants
import { TEST_STRUCTURE } from 'globals/constants';
import { spacer } from 'styles/utilities';

const SortableItem = SortableElement(({ subSection, sectionId }) => {
  // Need to create context for subsection editing
  const { editSubSectionSettings } = useSubSectionSettingsContext();

  return (
    <SectionSummaryBand
      data={subSection}
      isSubSection
      parentSectionId={sectionId}
      onHandleClick={() => editSubSectionSettings(sectionId, subSection._id)}
    />
  );
});

const SortableList = SortableContainer(({ subSections, sectionId }) => (
  <div>
    {subSections.map((subSection, index) => (
      <SortableItem
        key={`item-${subSection._id}`}
        index={index}
        subSection={subSection}
        sectionId={sectionId}
      />
    ))}
  </div>
));

const SortableSubSectionSummaryBand = ({ parentSection }) => {
  const { testSettings, updateSectionsOrder } = CreateTestContainer.useContainer();
  const { addNewSubSection } = useSubSectionSettingsContext();

  const [overlay, toggleOverlay] = useState(false);

  const [subsectionsList, updateSubsectionsList] = useState(parentSection.subsections);
  // useEffect will update the state if any sections are updated
  useEffect(() => {
    updateSubsectionsList(
      testSettings.sections[findKey(testSettings.sections, ['_id', parentSection._id])].subsections
    );
  }, [parentSection._id, testSettings.sections]);

  const updateSectionOrder = async ({ oldIndex, newIndex }) => {
    const updatedSubsectionsList = arrayMove(subsectionsList, oldIndex, newIndex);

    // Optimistic sections update
    updateSubsectionsList(updatedSubsectionsList);

    // Get the parent Section index and update it
    const sectionsList = testSettings.sections;
    const currentSectionIndex = findKey(testSettings.sections, ['_id', parentSection._id]);
    // Replace the sub-sections at the parent section index
    sectionsList[currentSectionIndex].subsections = updatedSubsectionsList;

    toggleOverlay(true);
    const data = await updateSectionsOrder(testSettings, sectionsList);

    // If API call fails, set the sections to order returned by API
    if (data)
      updateSubsectionsList(
        data.sections[findKey(testSettings.sections, ['_id', parentSection._id])].subsections
      );

    toggleOverlay(false);
  };

  // If test is not at `subSection` or subsection array doesn't exist return null
  if (testSettings.questionStructure !== TEST_STRUCTURE.subsections) return null;

  return (
    <div css={spacer.mrT60}>
      <AddSectionBand
        title={`Sub Sections (${subsectionsList?.length || 0})`}
        buttonText="Add Sub Section"
        onHandleClick={() => addNewSubSection(parentSection._id)}
      />
      {parentSection.subsections && subsectionsList !== undefined ? (
        <div className="is-relative">
          <SortableList
            useWindowAsScrollContainer
            axis="y"
            lockAxis="y"
            distance={2}
            subSections={subsectionsList}
            onSortEnd={updateSectionOrder}
            sectionId={parentSection._id}
          />
          {overlay && <LoaderOverlay />}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

SortableSubSectionSummaryBand.propTypes = {
  parentSection: PropTypes.object.isRequired
};

export default SortableSubSectionSummaryBand;
