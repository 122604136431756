import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';
import { UserInfoContainer } from 'api/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = UserInfoContainer.useContainer();

  return (
    <Fragment>
      {user.token && !user.errors ? (
        <Component {...rest} />
      ) : (
        <Redirect to={ROUTE_PATHS.login} noThrow />
      )}
    </Fragment>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};

export default PrivateRoute;
