/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import * as styles from './AnagramSorter.styles';

const SortableItem = SortableElement(({ item, fluidCell }) => (
  <div css={styles.dragBox({ fluidCell })} className="noselect">
    <span>{item}</span>
  </div>
));

const SortableList = SortableContainer(({ wordArray, fluidCell }) => (
  <div className="d-flex">
    {wordArray.map((item, index) => (
      <SortableItem key={`wordArray-${index}`} item={item} index={index} fluidCell={fluidCell} />
    ))}
  </div>
));

const AnagramSorter = ({ wordArray, callbackFunction, fluidCell }) => {
  const [mixedWord, updateMixedWord] = useState(wordArray);
  useEffect(() => {
    updateMixedWord(wordArray);
  }, [wordArray]);

  const updateWordOrder = ({ oldIndex, newIndex }) => {
    const updatedWordOrder = arrayMove(wordArray, oldIndex, newIndex);

    updateMixedWord(updatedWordOrder);

    if (typeof callbackFunction === 'function') {
      callbackFunction(updatedWordOrder);
    }
  };

  return (
    <Fragment>
      {mixedWord && mixedWord.length ? (
        <SortableList
          useWindowAsScrollContainer
          axis="x"
          lockAxis="x"
          distance={2}
          wordArray={mixedWord}
          onSortEnd={updateWordOrder}
          fluidCell={fluidCell}
        />
      ) : (
        ''
      )}
    </Fragment>
  );
};

AnagramSorter.propTypes = {
  wordArray: PropTypes.array,
  callbackFunction: PropTypes.func.isRequired,
  fluidCell: PropTypes.bool
};
AnagramSorter.defaultProps = {
  wordArray: [],
  fluidCell: false
};

export default AnagramSorter;
