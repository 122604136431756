const colors = {
  white: '#ffffff',
  black: '#000000',
  blackTransparent10: 'rgba(0, 0, 0, 0.1)',
  blackTransparent20: 'rgba(0, 0, 0, 0.2)',
  blackTransparent50: 'rgba(0, 0, 0, 0.5)',
  blackTransparent60: 'rgba(0, 0, 0, 0.6)',
  blackTransparent84: 'rgba(0, 0, 0, 0.84)',

  lightGrey: '#F7F7F7',
  loginBackground: 'rgba(239, 236, 230, 0.8)',
  mediumGrey: '#f4f4f4',
  grey: '#E0E0E0',
  blueGrey: 'rgba(0, 0, 0, 0.45)',
  mildGrey: '#eaeaea',
  borderGrey: '#979797',

  navyBlue: '#2654b4',
  lightBlue: '#daebff',
  lighterBlue: '#3391ff',
  backgroundBlue: 'rgba(51, 145, 255, 0.1)',
  cream: '#f5f0ea',

  red: '#c00018',
  orange: '#f17034',
  green: '#19a14c',

  primaryLinkHover: '#5e86da',
  primaryLinkActive: '#2654B4',
  secondaryLinkHover: 'rgba(218, 235, 255, 0.5)',
  secondaryLinkActive: '#daebff',
  checkBoxHover: '#73aef3',
  tabPressed: '#0076ff'
};

export const colorAlias = {
  primaryAnchorDefaultColor: colors.lighterBlue
};

export default { ...colors, ...colorAlias };
