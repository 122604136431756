/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { misc, colors } from 'styles/utilities';

export const suffix = css`
  border-top-right-radius: ${misc.smallBorderRadius};
  border-bottom-right-radius: ${misc.smallBorderRadius};
`;

export const input = css`
  border: none;
  border-radius: none;
`;

export const wrapper = css`
  border: 2px solid ${colors.mediumGrey};
  border-radius: ${misc.smallBorderRadius};
  background-color: ${colors.white};
  &:hover {
    border-color: ${colors.primaryLinkHover};
  }
  &:focus {
    border-color: ${colors.primaryLinkActive};
  }
`;

export const wrapperBorder = css`
  border-radius: ${misc.smallBorderRadius};
  border: 2px solid ${colors.mediumGrey};
  &:hover {
    border: 2px solid ${colors.primaryLinkHover};
  }
`;
