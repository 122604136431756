import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { sentryDSN } from 'globals/constants';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()]
  });
  LogRocket.init('test-tools/test-tools-dashboard');
  setupLogRocketReact(LogRocket);
}
