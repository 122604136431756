import {
  Link as Link_,
  Router,
  Location,
  navigate,
  useNavigate,
  useLocation,
  Redirect,
  useParams
} from '@reach/router';
import StyledNavLink from 'components/htmlElements/StyledNavLink';

const Link = StyledNavLink.withComponent(Link_);
const UnstyledLink = Link_;

export {
  Link,
  Router,
  Location,
  navigate,
  useNavigate,
  useLocation,
  Redirect,
  UnstyledLink,
  useParams
};
