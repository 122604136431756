/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import SettingsProviders from 'components/CourseSettingsProviders';

import CourseContainer from 'hooks/useCreateCourse';

import { COURSE_STRUCTURE } from 'globals/constants';

import SidebarCourses from 'components/SidebarCourses';
import RightSection from './pageSections/RightSection';

import * as styles from './page.styles';

const CreateLessons = () => {
  const { courseSettings } = CourseContainer.useContainer();

  return (
    <Fragment>
      <div className="d-flex" css={styles.pageHeight}>
        {courseSettings.courseStructure !== COURSE_STRUCTURE.course && <SidebarCourses />}
        <RightSection />
      </div>
    </Fragment>
  );
};

const WithProviders = (props) => (
  <SettingsProviders>
    <CreateLessons {...props} />
  </SettingsProviders>
);

export default WithProviders;
