/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc, typography } from 'styles/utilities';

export const inputStyles = css`
  &.input-element {
    display: none;
  }
  &.input-element:checked + .card-container {
    border: solid 1px ${colors.primaryLinkHover};
    .selected {
      color: ${colors.lighterBlue};
    }
  }
`;

export const container = css`
  background-color: ${colors.white};
  border-radius: ${misc.largeBorderRadius};
  border: ${misc.border.grey};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  outline: none;
`;

export const testTile = css`
  background-color: ${colors.backgroundBlue};
  border-radius: ${misc.mediumBorderRadius};
  position: relative;
  .text {
    margin: 0;
    font-size: ${typography.baseSizes.iota};
    &.absolute {
      position: absolute;
      top: 25px;
    }
  }
  .locked-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.subsection-tile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hide {
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const cardBottomSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const selected = css`
  border: solid 1px ${colors.primaryLinkHover};
`;
