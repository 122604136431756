/** @jsxImportSource @emotion/react */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { findKey } from 'lodash-es';

import AddSectionBand from 'components/AddSectionBand';
import CourseSectionSummaryBand from 'components/CourseSectionSummaryBand';
import LoaderOverlay from 'components/LoaderOverlay';
import CourseContainer from 'hooks/useCreateCourse';
import { useSubSectionSettingsContext } from 'components/CourseSubSectionSettingsForm/CourseSubSectionSettingsContext';

// Constants
import { COURSE_STRUCTURE } from 'globals/constants';
import { spacer } from 'styles/utilities';

import SidebarListItem from 'components/SidebarShell/SidebarListItem';
import useSearchQuery from 'hooks/useSearchQuery';
import { navigate } from 'components/Router';

const SortableSidebarItem = ({ subSection, sectionId }) => {
  const { setQueryParams, useParseQueryString } = useSearchQuery();
  const queryStringData = useParseQueryString();

  const handleOnClick = () => {
    const queryString = setQueryParams(
      {
        sectionId,
        subsectionId: subSection._id
      },
      true
    );
    navigate(`?${queryString}`);
  };

  return (
    <SidebarListItem
      heading={subSection.name}
      subHeading="Videos"
      onClick={handleOnClick}
      isActive={queryStringData.subsectionId === subSection._id}
    />
  );
};

SortableSidebarItem.propTypes = {
  subSection: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired
};

const SortableItem = SortableElement(({ subSection, sectionId, sortableItemType }) => {
  // Need to create context for subsection editing
  const { editSubSectionSettings } = useSubSectionSettingsContext();

  if (sortableItemType === 'sidebar') {
    return <SortableSidebarItem sectionId={sectionId} subSection={subSection} />;
  }

  return (
    <CourseSectionSummaryBand
      data={subSection}
      isSubSection
      parentSectionId={sectionId}
      onHandleClick={() => editSubSectionSettings(sectionId, subSection._id)}
    />
  );
});

const SortableList = SortableContainer(({ subSections, sectionId, sortableItemType }) => (
  <div>
    {subSections.map((subSection, index) => (
      <SortableItem
        key={`item-${subSection._id}`}
        index={index}
        subSection={subSection}
        sectionId={sectionId}
        sortableItemType={sortableItemType}
      />
    ))}
  </div>
));

const CourseSortableSubSectionSummaryBand = ({ parentSection, sortableItemType }) => {
  const { courseSettings, updateSectionsOrder } = CourseContainer.useContainer();
  const { addNewSubSection } = useSubSectionSettingsContext();

  const [overlay, toggleOverlay] = useState(false);

  const [subsectionsList, updateSubsectionsList] = useState(parentSection.subsections);
  // useEffect will update the state if any sections are updated
  useEffect(() => {
    updateSubsectionsList(
      courseSettings.sections[findKey(courseSettings.sections, ['_id', parentSection._id])]
        .subsections
    );
  }, [courseSettings.sections, parentSection._id]);

  const updateSectionOrder = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return false;
    }

    const updatedSubsectionsList = arrayMove(subsectionsList, oldIndex, newIndex);

    // Optimistic sections update
    updateSubsectionsList(updatedSubsectionsList);

    // Get the parent Section index and update it
    const sectionsList = courseSettings.sections;
    const currentSectionIndex = findKey(courseSettings.sections, ['_id', parentSection._id]);
    // Replace the sub-sections at the parent section index
    sectionsList[currentSectionIndex].subsections = updatedSubsectionsList;

    toggleOverlay(true);
    const data = await updateSectionsOrder(courseSettings, sectionsList);

    // If API call fails, set the sections to order returned by API
    if (data)
      updateSubsectionsList(
        data.sections[findKey(courseSettings.sections, ['_id', parentSection._id])].subsections
      );

    toggleOverlay(false);

    return true;
  };

  // If test is not at `subSection` or subsection array doesn't exist return null
  if (courseSettings.courseStructure !== COURSE_STRUCTURE.subsections) return null;

  return (
    <Fragment>
      {sortableItemType === 'band' && (
        <div css={spacer.mrT60}>
          <AddSectionBand
            title={`Sub Sections (${subsectionsList?.length || 0})`}
            buttonText="Add Sub Section"
            onHandleClick={() => addNewSubSection(parentSection._id)}
          />
        </div>
      )}
      {parentSection.subsections && subsectionsList !== undefined ? (
        <div className="is-relative">
          <SortableList
            useWindowAsScrollContainer
            axis="y"
            lockAxis="y"
            distance={2}
            subSections={subsectionsList}
            onSortEnd={updateSectionOrder}
            sectionId={parentSection._id}
            sortableItemType={sortableItemType}
          />
          {overlay && <LoaderOverlay />}
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

CourseSortableSubSectionSummaryBand.propTypes = {
  parentSection: PropTypes.object.isRequired,
  sortableItemType: PropTypes.oneOf(['band', 'sidebar']).isRequired
};

export default CourseSortableSubSectionSummaryBand;
