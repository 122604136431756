/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import H5 from 'components/htmlElements/H5';

import * as styles from './ProfileIcon.styles';

const ProfileIcon = ({ type, firstName, lastName, size, image }) => (
  <div
    css={[styles.profileIcon, styles.profileText]}
    style={{ width: size, height: size, backgroundColor: type === 'image' ? '#ffffff' : '' }}
  >
    {type === 'text' ? (
      <H5
        as="span"
        color="white"
        css={styles.text}
        style={{ fontSize: `${parseInt(size) - parseInt(size) * 0.5}px` }}
      >
        {firstName && firstName.length ? firstName.charAt(0) : ''}
        {lastName && lastName.length ? lastName.charAt(0) : ''}
      </H5>
    ) : (
      <div css={styles.image} style={{ backgroundImage: `url(${image})` }} />
    )}
  </div>
);

ProfileIcon.propTypes = {
  type: PropTypes.oneOf(['text', 'image']).isRequired,
  firstName(props, propName, componentName) {
    if (
      props.type === 'text' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`${propName} is required when "type" is true in ${componentName}.`);
    }
    return null;
  },
  lastName(props, propName, componentName) {
    if (
      props.type === 'text' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`${propName} is required when "type" is true in ${componentName}.`);
    }
    return null;
  },
  size: PropTypes.string,
  image(props, propName, componentName) {
    if (
      props.type === 'image' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`${propName} is required when "type" is true in ${componentName}.`);
    }
    return null;
  }
};

ProfileIcon.defaultProps = {
  firstName: '',
  lastName: '',
  size: '38px',
  image: ''
};

export default ProfileIcon;
