import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import RteNowrapEditing from './RteNowrapEditing';
import RteNowrapUI from './RteNowrapUI';

export default class RteNowrapPlugin extends Plugin {
  static get requires() {
    return [RteNowrapEditing, RteNowrapUI];
  }
}
