import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import VersionModalProvider from 'components/VersionModal/VersionModalContext';
import VersionModal from 'components/VersionModal';

import { useGetTestSettings } from 'api/test';
import { useGetQuestions } from 'api/questions';

import CreateTestContainer from 'hooks/useCreateTest';
import QuestionsContainer from 'hooks/useCreateQuestion';

import { ROUTE_PATHS, TEST_STRUCTURE } from 'globals/constants';
import TestOverview from './page';

const TestOverviewContainer = ({ testId }) => {
  const { data: testData, status: testStatus, error: testError } = useGetTestSettings(testId);
  const {
    data: questionData,
    status: questionStatus,
    error: questionError
  } = useGetQuestions(testId, TEST_STRUCTURE.test);

  if (
    testError ||
    (testData && testData.error) ||
    questionError ||
    (questionData && questionData.error)
  ) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (testStatus !== 'success' || (questionStatus !== 'idle' && questionStatus !== 'success')) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (testData && testData.data) {
    return (
      <Fragment>
        <CreateTestContainer.Provider initialState={testData.data}>
          <QuestionsContainer.Provider initialState={questionData && questionData.data}>
            <VersionModalProvider
              testGroupId={testData.data.testGroupId}
              testName={testData.data.name}
            >
              <TestOverview />
              <VersionModal />
            </VersionModalProvider>
          </QuestionsContainer.Provider>
        </CreateTestContainer.Provider>
      </Fragment>
    );
  }

  return null;
};

TestOverviewContainer.propTypes = {
  testId: PropTypes.string.isRequired
};

export default TestOverviewContainer;
