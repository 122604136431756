/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UserInfoContainer, useLogout } from 'api/auth';
import { ROUTE_PATHS } from 'globals/constants';

// Components
import H4 from 'components/htmlElements/H4';
import { Container, Row, Col } from 'components/Grid';
import { navigate } from 'components/Router';
import LoginForm from 'components/LoginForm';

// Styling
import { spacer, bgColor } from 'styles/utilities';
import * as styles from './page.styles';

const Login = (props) => {
  const user = UserInfoContainer.useContainer();
  const logout = useLogout();

  // If no errors after login or if navigated to login page,
  // go to home page
  useEffect(() => {
    if (user.token && !user.error) {
      navigate(ROUTE_PATHS.home);
    }
  }, [user.error, user.token]);

  // Logout [Used for token error or features like parallel login, etc.]
  const [didLogout, updateDidLogout] = useState(false);
  useEffect(() => {
    const logoutAction = () => {
      if (props.location.state && props.location.state.logoutUser) {
        logout();
        updateDidLogout(true);
        // window.location.reload();
      }
    };
    if (!didLogout) {
      logoutAction();
    }
  }, [didLogout, logout, props.location.state]);

  return (
    <div css={[bgColor.loginBackground, styles.page]} className="d-flex align-items-center">
      <Container css={[spacer.mrBT60]}>
        <Row className="d-flex justify-content-center align-items-center">
          <Col className="col-lg-6">
            <H4 className="text-center" workSans>
              Tests Hub
            </H4>
            <LoginForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.object
};

Login.defaultProps = {
  location: {}
};

export default Login;
