/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { merge, omit } from 'lodash-es';

// Components
import TestSettingsChip from 'components/TestSettingsChip/TestSettingsChip';
import H5 from 'components/htmlElements/H5';
import A from 'components/htmlElements/A';
import { Container, Row, Col } from 'utils/bootstrap-grid';
import { Link } from 'components/Router';
import settingsHelpers from 'utils/settingsHelpers';
import CreateTestContainer from 'hooks/useCreateTest';

// Styling
import { spacer, bgColor } from 'styles/utilities';
import { TEST_STRUCTURE } from 'globals/constants';
import settingsSummary from './SettingsSummaryBand.styles';

const { getTestSettingsFormFields } = settingsHelpers;

const SettingsSummaryBand = () => {
  const { testSettings } = CreateTestContainer.useContainer();
  let initialSettingsList = getTestSettingsFormFields({ testSettings });
  initialSettingsList = omit(initialSettingsList, '_id');

  const [settingsList, updateSettingsList] = useState(initialSettingsList);

  let questionStructure = '';
  // let resultsDisplayedAt = '';

  if (testSettings.questionStructure === TEST_STRUCTURE.test) {
    questionStructure = 'No Sections';
  } else if (testSettings.questionStructure === TEST_STRUCTURE.sections) {
    questionStructure = 'Single Sections';
  } else if (testSettings.questionStructure === TEST_STRUCTURE.subsections)
    questionStructure = 'Sections and Subsections';

  // if (testSettings.resultsDisplayedAt === TEST_STRUCTURE.test) {
  //   resultsDisplayedAt = 'On Test';
  // } else if (testSettings.resultsDisplayedAt === TEST_STRUCTURE.sections) {
  //   resultsDisplayedAt = 'On Section';
  // } else if (testSettings.resultsDisplayedAt === TEST_STRUCTURE.subsections) {
  //   resultsDisplayedAt = 'On Subsection';
  // }

  useEffect(() => {
    const updatedSettings = {
      sectionStructure: {
        text: 'Section Structure',
        value: true,
        positiveStatus: questionStructure
      }
    };
    updateSettingsList((prevState) => merge({}, prevState, updatedSettings));
  }, [questionStructure]);

  const getTestSettingsChipValue = (itemObj) => {
    if (itemObj.value) {
      return itemObj.positiveStatus;
    }
    return itemObj.negativeStatus;
  };

  return (
    <div css={[settingsSummary, spacer.padBT30, spacer.padLR15, spacer.mrB40, bgColor.white]}>
      <Container>
        <Row>
          <Col className="d-flex align-items-center">
            <H5 semiBold workSans>
              Settings Summary
            </H5>
            <div css={spacer.mrL20}>
              <A as={Link} to={`/test/${testSettings._id}/settings`} color="lighterBlue" semiBold>
                Edit settings
              </A>
            </div>
          </Col>
          {Object.keys(settingsList).map((item) => (
            <Col className="col-lg-3" key={settingsList[item].text}>
              <TestSettingsChip
                setting={settingsList[item].text}
                value={getTestSettingsChipValue(settingsList[item])}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default SettingsSummaryBand;
