/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// components
import H4 from 'components/htmlElements/H4';
import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon';
import { Input, InlineFields, FieldLabel } from 'components/htmlElements/Fields';

import { FieldArray, Field } from 'formik';

// styling
import { spacer } from 'styles/utilities';

const MetaData = ({ values }) => {
  const NEW_OPTION_TEMPLATE = {
    key: '',
    value: ''
  };

  return (
    <div>
      <H4 workSans css={spacer.mrB20}>
        Extra Fields
      </H4>
      <FieldArray name="meta">
        {({ remove, push }) => (
          <Fragment>
            {Object.keys(values.meta).length > 0 &&
              Object.keys(values.meta).map((attribute, index) => (
                <div css={spacer.mrB30} className="d-flex align-items-center" key={attribute}>
                  <div className="w-100" css={spacer.mrR10}>
                    <InlineFields>
                      <FieldLabel className="w-100">
                        <FieldLabel htmlFor={`meta.${index}.key`}>Attribute</FieldLabel>
                        <Field
                          as={Input}
                          type="input"
                          name={`meta.${index}.key`}
                          id={`meta.${index}.key`}
                        />
                      </FieldLabel>

                      <FieldLabel className="w-100">
                        <FieldLabel htmlFor={`meta.${index}.value`}>Value</FieldLabel>
                        <Field
                          as={Input}
                          type="input"
                          name={`meta.${index}.value`}
                          id={`meta.${index}.value`}
                        />
                      </FieldLabel>
                    </InlineFields>
                  </div>

                  <div>
                    <Button
                      onClick={() => remove(attribute)}
                      icon
                      outline
                      title="Delete"
                      type="button"
                    >
                      <Icon icon="delete" size={20} />
                    </Button>
                  </div>
                </div>
              ))}
            <div css={spacer.mrT10} className="d-flex justify-content-end">
              <Button onClick={() => push(NEW_OPTION_TEMPLATE)} type="button">
                + Add attribute
              </Button>
            </div>
          </Fragment>
        )}
      </FieldArray>
    </div>
  );
};

MetaData.propTypes = {
  values: PropTypes.object.isRequired
};

export default MetaData;
