/** @jsxImportSource @emotion/react */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { customAlphabet } from 'nanoid';

import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon';
import { Input, Select } from 'components/htmlElements/Fields';
import { FieldArray, Field, useField, useFormikContext } from 'formik';
import { NANO_ID_ALPHANUMERICS } from 'globals/constants';

import { spacer } from 'styles/utilities';

const nanoid = customAlphabet(NANO_ID_ALPHANUMERICS, 10);

const AnswerPartsTextField = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const [field] = useField(props);
  const [currentFieldType, updateCurrentFieldType] = useState(
    values.answerParts[props.repeaterIndex].type
  );
  useEffect(() => {
    updateCurrentFieldType((prevState) => {
      if (prevState !== values.answerParts[props.repeaterIndex].type) {
        setFieldValue(props.name, '');
      }

      return values.answerParts[props.repeaterIndex].type;
    });
  }, [props.name, props.repeaterIndex, setFieldValue, values.answerParts]);

  return (
    <Fragment>
      {currentFieldType === 'text' && <Input {...props} {...field} />}
      {currentFieldType === 'number' && (
        <Input
          {...props}
          {...field}
          type="number"
          // value={Number(answerPart.text)}
        />
      )}
      {currentFieldType === 'operator' && (
        <Select {...props} {...field}>
          <option value="" label="Select operator" disabled hidden />
          <option value="+" label="+" />
          <option value="-" label="-" />
          <option value="x" label="x" />
          <option value="÷" label="÷" />
        </Select>
      )}
    </Fragment>
  );
};

AnswerPartsTextField.propTypes = {
  repeaterIndex: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

const AnswerPartsRepeater = ({ values }) => {
  const NEW_OPTION_TEMPLATE = {
    text: '',
    type: 'text',
    shortId: nanoid()
  };

  return (
    <div>
      <FieldArray name="answerParts">
        {({ remove, push }) => (
          <Fragment>
            {values.answerParts.length > 0 &&
              values.answerParts.map((answerPart, index) => (
                <div
                  css={spacer.mrB30}
                  className="d-flex align-items-center"
                  key={`${index.toString()}-item`}
                >
                  <div className="flex-shrink-0" css={spacer.mrR10}>
                    <Field as={Select} name={`answerParts.${index}.type`} value={answerPart.type}>
                      <option value="text" label="Normal Text" />
                      <option value="number" label="Number" />
                      <option value="operator" label="Operator" />
                    </Field>
                  </div>
                  <div className="w-100" css={spacer.mrR10}>
                    <AnswerPartsTextField
                      name={`answerParts.${index}.text`}
                      repeaterIndex={index}
                    />
                    {/* {answerPart.type === 'text' && (
                      <Field
                        as={Input}
                        name={`answerParts.${index}.text`}
                        value={answerPart.text}
                      />
                    )}
                    {answerPart.type === 'number' && (
                      <Field
                        as={Input}
                        name={`answerParts.${index}.text`}
                        value={Number(answerPart.text)}
                        type="number"
                      />
                    )}
                    {answerPart.type === 'operator' && (
                      <Field as={Select} name={`answerParts.${index}.text`} value={answerPart.text}>
                        <option value="" label="Select operator" disabled hidden />
                        <option value="+" label="+" />
                        <option value="-" label="-" />
                        <option value="x" label="x" />
                        <option value="÷" label="÷" />
                      </Field>
                    )} */}
                    <Field
                      type="hidden"
                      value={answerPart.shortId}
                      name={`answerParts.${index}.shortId`}
                    />
                  </div>
                  <div>
                    <Button onClick={() => remove(index)} icon outline title="Delete" type="button">
                      <Icon icon="delete" size={20} />
                    </Button>
                  </div>
                </div>
              ))}
            <div css={spacer.mrT10} className="d-flex justify-content-end">
              <Button onClick={() => push(NEW_OPTION_TEMPLATE)} type="button">
                + Add option
              </Button>
            </div>
          </Fragment>
        )}
      </FieldArray>
    </div>
  );
};

AnswerPartsRepeater.propTypes = {
  values: PropTypes.object.isRequired
};

export default AnswerPartsRepeater;
