import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { api } from 'globals/constants';
import request from 'utils/request';
import { createContainer } from 'unstated-next';

const useGetCourses = () => {
  const { data, error, status } = useQuery(
    'getCourses',
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.courses.get
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const memoCoursesData = useMemo(() => data || {}, [data]);

  return {
    data: memoCoursesData,
    error,
    status
  };
};

const GetCoursesContainer = createContainer(useGetCourses);

export default GetCoursesContainer;
