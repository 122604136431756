/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, typography, misc, spacer } from 'styles/utilities';

export const loginCard = css`
  border: solid 2px ${colors.blackTransparent10};
  border-radius: ${misc.largeBorderRadius};
  background-color: ${colors.white};
  ${spacer.padBT20};
  ${spacer.padLR20};
  .edit-settings {
    font-size: ${typography.baseSizes.theta};
    font-family: ${typography.fonts.workSans.fontFamily};
    font-weight: ${typography.fonts.workSans.fontWeight.semiBold};
    color: ${colors.lighterBlue};
    outline: none;
    cursor: pointer;
  }
`;
