import React from 'react';
import { Link } from 'components/Router';

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) =>
      // the object returned here is passed to the
      // anchor element's props
      ({
        isactive: isCurrent ? 'true' : 'false'
      })
    }
  />
);

export default NavLink;
