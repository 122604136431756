import { useLocation } from 'components/Router';
import { useState, useEffect, useCallback } from 'react';
import * as queryString from 'querystringify';

const useSearchQuery = () => {
  const location = useLocation();

  // const queryParams = useMemo(() => {
  //   const params = new URLSearchParams(location.search);
  //   return params;
  // }, [location.search]);

  const getSearchQuery = useCallback(
    (param) => {
      const params = new URLSearchParams(location.search);
      const result = params.get(param);
      return result;
    },
    [location.search]
  );

  const getPathName = useCallback(() => {
    const { pathname } = location;
    return pathname;
  }, [location]);

  // Set query string
  const setQueryParams = (queryParameters, pushCleanString) => {
    const queryStringData = pushCleanString ? {} : queryString.parse(window.location.search);
    if (Object.keys(queryParameters).length) {
      Object.keys(queryParameters).forEach((key) => {
        queryStringData[key] = queryParameters[key];
      });
    }
    return queryString.stringify(queryStringData);
  };

  // Get query string parsed as object
  const useParseQueryString = () => {
    // Need to call `useLocation` again because it needs to
    // be set as a dependency for useEffect to propogate URL updates
    const location_ = useLocation();
    const [queryStringData, updateQueryString] = useState(queryString.parse(location_.search));

    // Get query string
    useEffect(() => {
      updateQueryString(queryString.parse(location_.search));
    }, [location_.search]);

    return queryStringData;
  };

  return {
    getSearchQuery,
    getPathName,
    setQueryParams,
    useParseQueryString
  };
};

export default useSearchQuery;
