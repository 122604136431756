/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import * as hexToRgba from 'hex-to-rgba';
import { colors, misc, typography } from 'styles/utilities';

export const pill = (props) => css`
  padding: 4px 8px;
  margin: 0;
  color: ${colors[props.color] || colors.navyBlue};
  background-color: ${hexToRgba(colors[props.color] || colors.navyBlue, 0.08)};
  border-radius: ${misc.largeBorderRadius};
  line-height: 1;

  &.pill {
    font-size: ${typography.baseSizes.iota};
  }
`;
