/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors } from 'styles/utilities';

export const isRelative = css`
  position: relative;
`;

export const userTable = css`
  overflow-x: auto;
  table {
    overflow-x: auto;
    border: 1px solid ${colors.grey};
  }
  th,
  td {
    border-bottom: 1px solid ${colors.grey};
    padding: 8px;
    padding-left: 16px;
  }
`;
