import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import {
  toWidget,
  viewToModelPositionOutsideModelElement
} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

import RteNowrapCommand from './RteNowrapCommand';
import './rteNowrap-ui.css';

export default class RteNowrapEditing extends Plugin {
  static get requires() {
    // ADDED
    return [Widget];
  }

  init() {
    // console.info('RteNowrapEditing#init() got called');

    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add('rtenowrap', new RteNowrapCommand(this.editor));

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) =>
        viewElement.hasClass('response')
      )
    );

    // this.editor.config.define('clozeResponseConfig', {
    //   enabled: true
    // });
  }

  _defineSchema() {
    // ADDED
    const { schema } = this.editor.model;

    schema.register('rtenowrap', {
      // Allow wherever text is allowed:
      allowWhere: '$text',

      // The placeholder will act as an inline node:
      isInline: true,

      // The inline widget is self-contained so it cannot be split by the caret and can be selected:
      isObject: true,

      // The inline widget can have the same attributes as text (for example linkHref, bold).
      allowAttributesOf: '$text',

      // The placeholder can have many types, like date, name, surname, etc:
      allowAttributes: ['name']
    });
  }

  _defineConverters() {
    // Helper method for both downcast converters.
    function createResponseView(modelItem, viewWriter) {
      const name = modelItem.getAttribute('name');

      const responseView = viewWriter.createContainerElement(
        'span',
        {
          class: 'no-wrap-content',
          contentEditable: true
        },
        {
          isAllowedInsideAttributeElement: true
        }
      );

      // Insert the placeholder name (as a html).
      // const innerText = viewWriter.createText(`[${name}]`);
      const rawElem = viewWriter.createRawElement(
        'span',
        { contentEditable: true },
        (domElement) => {
          // eslint-disable-next-line no-param-reassign
          domElement.innerHTML = name;
        }
      );

      viewWriter.insert(viewWriter.createPositionAt(responseView, 0), rawElem);

      return responseView;
    }

    const { conversion } = this.editor;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['response']
      },
      model: (viewElement, { writer: modelWriter }) => {
        // Extract the "name" from "{name}".
        const name = viewElement.getChild(0).data.slice(1, -1);

        return modelWriter.createElement('response', { name });
      }
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'rtenowrap',
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = createResponseView(modelItem, viewWriter);

        // Enable widget handling on a placeholder element inside the editing view.
        return toWidget(widgetElement, viewWriter);
      }
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'rtenowrap',
      view: (modelItem, { writer: viewWriter }) => createResponseView(modelItem, viewWriter)
    });
  }
}
