import qs from 'qs';
import store from 'store';

const request = async ({ endpoint, method, headers, payload, body, customOptions = {} }) => {
  let url = `${endpoint}`;

  const userInfo = {
    token: store.get('tctUser') && store.get('tctUser').token
  };

  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      mode: 'no-cors',
      ...headers
    }
  };

  if (customOptions.removeContentType) {
    delete options.headers['Content-Type'];
  }

  if (userInfo.token) {
    options.headers.Authorization = `Bearer ${userInfo.token}`;
  }

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    options.body = body || JSON.stringify(payload);
  }

  if (method === 'GET' || method === 'PUT') {
    if (payload) {
      url += `?${qs.stringify(payload)}`;
    }
  }

  const response = await fetch(url, options);

  const responseData = await response.text();

  return {
    response: responseData.length ? JSON.parse(responseData) : responseData,
    status: response.status
  };
};

export default request;
