/* eslint-disable import/prefer-default-export */
import { keyframes, css } from '@emotion/react';
import { colors, breakpoints, misc, mixins } from 'styles/utilities';

const modalFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const modalGlobalStyles = css`
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Content--after-open {
    .modal-container {
      animation: 0.3s ease ${modalFadeIn} forwards;
    }
  }
`;

export const modalContainer = (props) => css`
  max-width: ${props.large ? '970px' : '620px'};
`;

export const modalWrapper = css`
  background-color: ${colors.blackTransparent54};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${misc.modalZindex};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${'' /* pointer-events: none; */}
  padding: 10px;
  > * {
    flex: 1 1 auto;
    pointer-events: all;
  }
`;

export const contentsWrapper = css`
  background-color: ${colors.white};
  border-radius: ${misc.largeBorderRadius};
  box-shadow: ${misc.boxShadow.modal};
`;

export const contents = css`
  ${breakpoints({
    paddingBottom: ['10px', '', '20px', '', '']
  })}
`;

export const header = css`
  border-bottom: 1px solid ${colors.mildGrey};
  ${breakpoints({
    padding: ['10px', '', '20px', '', '']
  })}
`;

export const closeIcon = css`
  position: absolute;
  top: 10px;
  cursor: pointer;
  z-index: 1;
  box-shadow: none;
  border: 0;
  background: transparent;
  line-height: 1;

  ${mixins.transition()};
  ${breakpoints({
    right: ['10px', '', '20px', '', '']
  })}
`;
