/* eslint-disable jsx-a11y/media-has-caption */
/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// components
import Button from 'components/htmlElements/Button';
import A from 'components/htmlElements/A';
import Icon from 'components/Icon';

import Modal, { useModal } from 'components/Modal';
import MediaUploader from 'components/MediaUploader';

import { Field, FieldArray } from 'formik';

// styling
import { spacer, colors } from 'styles/utilities';
import Checkbox from 'components/Checkbox/Checkbox';

const InsertQuestionAudio = ({ values, setFieldValue }) => {
  const { isOpen, openModal, toggle } = useModal();

  const handleButtonClick = () => {
    openModal();
  };

  const handleInsertMedia = (url, push) => {
    push({ url, autoplay: values.autoplay ?? false });
    toggle();
  };

  const autoPlayChangeHandler = ({ push, remove, index, url, autoplay }) => {
    remove(index);

    push({ url, autoplay: !autoplay });
  };

  return (
    <FieldArray name="questionAudio">
      {({ push, remove }) => (
        <Fragment>
          {values?.questionAudio?.length > 0 &&
            values?.questionAudio.map(
              (audio, index) =>
                audio?.url && (
                  <div className="d-flex align-items-center" css={spacer.mrB20} key={audio?.url}>
                    <audio
                      src={audio?.url}
                      autoPlay={audio?.autoplay}
                      controls
                      css={spacer.mrR20}
                    />

                    <Field
                      as={Checkbox}
                      name="autoplay"
                      label="autoplay"
                      type="checkbox"
                      checked={audio?.autoplay}
                      onChange={() => {
                        setFieldValue('autoplay', !audio?.autoplay);
                        autoPlayChangeHandler({
                          push,
                          remove,
                          index,
                          url: audio?.url,
                          autoplay: audio?.autoplay
                        });
                      }}
                    />

                    <A onClick={() => remove(index)} as="span" css={spacer.mrL20}>
                      <Icon icon="close" color={colors.navyBlue} />
                    </A>
                  </div>
                )
            )}

          <Button onClick={handleButtonClick} type="button">
            Select Audio
          </Button>

          <Modal isOpen={isOpen} toggle={toggle} large>
            <MediaUploader
              handleMediaClick={(url) => {
                handleInsertMedia(url, push);
              }}
            />
          </Modal>
        </Fragment>
      )}
    </FieldArray>
  );
};

InsertQuestionAudio.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default InsertQuestionAudio;
