/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components/htmlElements/Fields';
import P from 'components/htmlElements/P';

import { spacer } from 'styles/utilities';
import * as styles from './InputSuffix.styles';

const InputSuffix = ({ ...props }) => (
  <div className="d-flex" css={styles.wrapper}>
    <Input {...props} small css={styles.input} />
    <div
      className="d-flex justify-content-center align-items-center"
      css={[styles.suffix, spacer.padLR10]}
    >
      <P workSans small>
        {props.suffix}
      </P>
    </div>
  </div>
);

InputSuffix.propTypes = {
  suffix: PropTypes.string.isRequired
};

export default InputSuffix;
