/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';

import { spacer } from 'styles/utilities';
import Latex, { getParsedText } from '../helpers/helpers';
import * as styles from '../page.styles';

const PreviewTextBasedQuestion = ({ questions, questionNumber }) => {
  const currentQuestion = questions[questionNumber];
  const options = currentQuestion?.text?.answerOptions || [];
  const answers = currentQuestion?.text?.answer || [];
  const correctAnswers = options.filter((o1) => answers.some((o2) => o1.shortId === o2));

  return (
    <Fragment>
      {/* Question text */}
      <div css={styles.clearfix} className="clearfix">
        <Latex css={styles.questionText}>
          {getParsedText(currentQuestion?.text?.questionText)}
        </Latex>
      </div>

      <br />

      {/* Options */}
      <div css={[spacer.padBT20, styles.clearfix]} className="clearfix">
        {currentQuestion?.type === 'text' && (
          <div className="d-flex">
            {currentQuestion?.text?.answerOptions?.map((option) => (
              <Latex
                key={option.shortId}
                css={[styles.optionStyles, spacer.mrBT5, spacer.mrLR5]}
                className={`${
                  correctAnswers?.some((e) => e.shortId === option.shortId) ? 'correct' : ''
                }`}
              >
                {getParsedText(option.text)}
              </Latex>
            ))}
          </div>
        )}
      </div>

      <br />

      {/* Correct answers */}
      {correctAnswers && correctAnswers.length > 0 && (
        <div css={[styles.correctAnswers, styles.clearfix]} className="clearfix">
          <P className="correct-title">The correct answer is </P>
          {correctAnswers?.map((answer, index) => (
            <Latex key={answer.shortId} css={styles.inlineDiv}>
              {getParsedText(answer.text)}
              {index < correctAnswers.length - 1 && <span>, </span>}
            </Latex>
          ))}
        </div>
      )}

      <br />

      {/* Solution Explanation */}
      <div css={[styles.solutionExplanation, spacer.padB30, styles.clearfix]} className="clearfix">
        {currentQuestion?.text?.solutionExplaination && (
          <Latex>{getParsedText(currentQuestion?.text?.solutionExplaination)}</Latex>
        )}
      </div>
    </Fragment>
  );
};

PreviewTextBasedQuestion.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionNumber: PropTypes.number.isRequired
};

export default PreviewTextBasedQuestion;
