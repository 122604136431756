/** @jsxImportSource @emotion/react */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import H4 from 'components/htmlElements/H4';
import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon';
import MediaThumbnail from 'components/MediaThumbnail';
import Tabs from 'components/Tabs';
import { useDropzone } from 'react-dropzone';
import { Waypoint } from 'react-waypoint';

import { useCreateMedia, useGetAllMedia } from 'api/media';

import * as modalStyles from 'components/Modal/Modal.styles';
import { spacer, colors } from 'styles/utilities';
import * as styles from './MediaUploader.styles';

const MediaDropZone = () => {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*, audio/mpeg',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const { addMedia, isLoading } = useCreateMedia();

  const uploadMediaPromise = async (file) => {
    await addMedia(file);
  };

  const handleUpload = async () => {
    console.info(files);
    await Promise.all(files.map((file) => uploadMediaPromise(file))).then((result) => {
      console.info(result);
    });

    if (!isLoading) {
      setFiles([]);
    }
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (files.length) {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      }
    },
    [files]
  );

  const thumbs = files.map((file) => (
    <div css={[spacer.mrLR5, spacer.mrBT5]} key={file.name}>
      <MediaThumbnail url={file.preview} alt={file.name} width="100px" noDpr />
    </div>
  ));

  return (
    <Fragment>
      <styles.StyledDropzoneArea
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject
        })}
      >
        <input {...getInputProps()} />
        <div className="text-center">
          <Icon icon="cloud_upload" size="150" color={colors.grey} />
          <P workSans semiBold>
            Drop the file from your computer here <br />
            <P as="span" workSans color="primaryLinkHover">
              You can also browse your file
            </P>
          </P>
        </div>
      </styles.StyledDropzoneArea>

      <div className="d-flex align-items-center flex-wrap">{thumbs}</div>

      <div className="text-right" css={spacer.mrT20}>
        <Button onClick={handleUpload} loading={isLoading}>
          Upload
        </Button>
      </div>
    </Fragment>
  );
};

const MediaThumbnailsList = ({ minHeight, mediaData, handleImageClick, waypointOnEnter }) => (
  <div style={{ minHeight: `${minHeight}px` }} css={styles.thumbnailList}>
    <div className="d-flex flex-wrap">
      {mediaData.map((media) => (
        <A
          as="span"
          css={[spacer.mrLR5, spacer.mrBT5]}
          key={media.url}
          onClick={() => handleImageClick(media.url)}
        >
          <MediaThumbnail
            url={media.url.concat('?auto=format&w=100')}
            alt={media.originalName}
            width="100px"
            hoverable
          />
        </A>
      ))}
    </div>
    <Waypoint debug onEnter={waypointOnEnter} bottomOffset="-20%" />
  </div>
);

MediaThumbnailsList.propTypes = {
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaData: PropTypes.array.isRequired,
  handleImageClick: PropTypes.func,
  waypointOnEnter: PropTypes.func
};
MediaThumbnailsList.defaultProps = {
  minHeight: 'auto',
  handleImageClick: () => {},
  waypointOnEnter: () => {}
};

const MediaUploader = ({ handleMediaClick }) => {
  const { data: mediaData, isError, isLoading, fetchNextPage, hasNextPage } = useGetAllMedia();

  const [height, setHeight] = useState();
  const elementHeight = useRef(null);

  useEffect(() => {
    if (elementHeight.current) {
      setHeight(elementHeight.current.clientHeight);
    }
  }, [height]);

  const handleImageClick = (url) => {
    if (typeof handleMediaClick === 'function') {
      handleMediaClick(url);
    }
  };

  const handlePaginatedMediaData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const mediaDropzone = (
    <div ref={elementHeight}>
      <MediaDropZone />
    </div>
  );

  return (
    <Fragment>
      <div css={[modalStyles.header, styles.noBorder]}>
        <H4>Upload Media</H4>
        <div css={[spacer.mrLR20, spacer.mrT20]}>
          <Tabs
            tabs={[
              {
                tab: 'Upload',
                component: mediaDropzone
              },
              {
                tab: 'Saved',
                component: !isError && !isLoading && mediaData && (
                  <MediaThumbnailsList
                    minHeight={height}
                    mediaData={mediaData.data}
                    handleImageClick={handleImageClick}
                    waypointOnEnter={handlePaginatedMediaData}
                  />
                )
              }
            ]}
          />
        </div>
      </div>
    </Fragment>
  );
};

MediaUploader.propTypes = {
  handleMediaClick: PropTypes.func
};

MediaUploader.defaultProps = {
  handleMediaClick: null
};

export default MediaUploader;
