/* eslint-disable max-classes-per-file */
// @ref: https://ckeditor.com/docs/ckeditor5/latest/framework/guides/tutorials/using-react-in-a-widget.html#insertproductpreviewcommandjs
// @ref: https://ckeditor.com/docs/ckeditor5/latest/features/image.html#image-styles
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

import Command from '@ckeditor/ckeditor5-core/src/command';

class InsertCustomImage extends Command {
  execute(imageUrl) {
    this.editor.model.change((writer) => {
      const imageElement = writer.createElement('image', {
        src: imageUrl
      });

      // Insert the image in the current selection location.
      this.editor.model.insertContent(imageElement, this.editor.model.document.selection);
    });
  }
}

export default class InsertImage extends Plugin {
  init() {
    const { editor } = this;

    editor.commands.add('insertCustomImage', new InsertCustomImage(editor));

    editor.ui.componentFactory.add('insertImage', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Insert image',
        icon: imageIcon,
        tooltip: true
      });

      // Callback executed once the image is clicked.
      view.on('execute', () => {
        // This is a call back function called where the plugin is used
        editor.config.get('imageFromGallery').openGallery(editor);

        // const customImageUrl = editor.config.get('customImageURL');
        // const imageUrl = prompt('Insert custom image');

        // editor.model.change((writer) => {
        //   const imageElement = writer.createElement('image', {
        //     src: imageUrl
        //   });

        //   // Insert the image in the current selection location.
        //   editor.model.insertContent(imageElement, editor.model.document.selection);
        // });
      });

      return view;
    });
  }
}
