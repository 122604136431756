// import { fontFace } from 'polished';
import { css } from '@emotion/react';
import typography from 'styles/tokens/typography';

const fonts = css`
  @font-face {
    font-family: ${typography.fonts.cooperBT.fontFamily};
    font-weight: ${typography.fonts.cooperBT.fontWeight.light};
    font-style: normal;
    src: url(${require('fonts/cooper-bt-light.woff').default}) format('woff'),
      url(${require('fonts/cooper-bt-light.woff2').default}) format('woff2');
  }
  @font-face {
    font-family: ${typography.fonts.gtWalsheim.fontFamily};
    font-weight: ${typography.fonts.gtWalsheim.fontWeight.light};
    font-style: normal;
    src: url(${require('fonts/gt-walsheim-pro-light.woff').default}) format('woff'),
      url(${require('fonts/gt-walsheim-pro-light.woff2').default}) format('woff2');
  }
  @font-face {
    font-family: ${typography.fonts.gtWalsheim.fontFamily};
    font-weight: ${typography.fonts.gtWalsheim.fontWeight.medium};
    font-style: normal;
    src: url(${require('fonts/gt-walsheim-pro-medium.woff').default}) format('woff'),
      url(${require('fonts/gt-walsheim-pro-medium.woff2').default}) format('woff2');
  }
  @font-face {
    font-family: ${typography.fonts.gtWalsheim.fontFamily};
    font-weight: ${typography.fonts.gtWalsheim.fontWeight.bold};
    font-style: normal;
    src: url(${require('fonts/gt-walsheim-pro-bold.woff').default}) format('woff'),
      url(${require('fonts/gt-walsheim-pro-bold.woff2').default}) format('woff2');
  }
`;

export default fonts;
