/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import P from 'components/htmlElements/P';
import Icon from 'components/Icon';

import { spacer } from 'styles/utilities';
import * as styles from './SidebarShell.styles';

const SidebarListItem = ({ heading, subHeading, onClick, children, isActive }) => {
  const clickableProps = {
    onClick,
    type: 'button',
    role: 'button',
    tabIndex: '0',
    onKeyPress: () => {}
  };

  return (
    <div css={styles.listItem({ isActive })} {...(onClick && { ...clickableProps })}>
      <div className="list-wrapper-inner">
        <div className="d-flex align-items-start ">
          <Icon icon="drag" size="20" color="grey" />
          <div css={[styles.listItemContent, spacer.mrL5]}>
            <P workSans color={onClick ? 'lighterBlue' : ''} className="m-0">
              {heading}
            </P>
            <P workSans small color="blackTransparent60" className="m-0">
              {subHeading}
            </P>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

SidebarListItem.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  children: PropTypes.node,
  isActive: PropTypes.bool
};

SidebarListItem.defaultProps = {
  onClick: false,
  children: null,
  isActive: false
};

export default SidebarListItem;
