/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/Grid';
import H3 from 'components/htmlElements/H3';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import Icon from 'components/Icon';

import { Link, navigate } from 'components/Router';
import StatusPill from 'components/StatusPill';
import { useCloneTestSettings } from 'api/test';
import PageLoader from 'components/PageLoader';

// Styling
import { spacer, bgColor } from 'styles/utilities';

const HeaderDetails = ({
  name,
  id,
  primaryCtaLink,
  isDraft,
  hideVersionCta,
  hideCloneCta,
  openVersionModal
}) => {
  const [overlay, toggleOverlay] = useState(false);
  const { cloneTest } = useCloneTestSettings();

  const handleTestCloning = async () => {
    toggleOverlay(true);
    const data = await cloneTest({ testId: id });
    if (data?.response?.data) {
      navigate(`/test/${data.response.data._id}`);
      toggleOverlay(false);
    }
  };

  return (
    <div css={[bgColor.white, spacer.padBT60]}>
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <H3 workSans css={spacer.mrR10}>
              {name}
            </H3>
            <StatusPill
              text={!isDraft ? 'Published' : 'Draft'}
              color={!isDraft ? 'green' : 'navyBlue'}
            />
          </div>
          <div className="d-flex align-items-center">
            {!hideCloneCta && (
              <A as="span" color="lighterBlue" semiBold onClick={handleTestCloning}>
                Clone test
              </A>
            )}

            {!hideVersionCta && (
              <A
                as="span"
                css={spacer.mrL20}
                color="lighterBlue"
                semiBold
                onClick={openVersionModal}
              >
                View Versions
              </A>
            )}
            <div css={spacer.mrL20}>
              <Button as={Link} icon primary to={primaryCtaLink}>
                <Icon icon="settings" size="20" css={spacer.mrR10} />
                Settings
              </Button>
            </div>
          </div>
        </div>
      </Container>
      {overlay && <PageLoader isFetching isRelative atPageHeight />}
    </div>
  );
};

HeaderDetails.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  primaryCtaLink: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  hideVersionCta: PropTypes.bool,
  hideCloneCta: PropTypes.bool,
  openVersionModal: PropTypes.func
};
HeaderDetails.defaultProps = {
  hideVersionCta: false,
  hideCloneCta: false,
  openVersionModal: () => {}
};

export default HeaderDetails;
