import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import P from 'components/htmlElements/P';
import { colors, misc, mixins, typography } from 'styles/utilities';

export const StyledInputCSSObject = {
  margin: 0,
  border: `2px solid ${colors.mediumGrey}`,
  boxShadow: `none`,
  borderRadius: `${misc.smallBorderRadius}`,
  backgroundColor: `${colors.white}`,
  fontFamily: `${typography.fonts.workSans.fontFamily}`,
  fontSize: `${typography.baseSizes.theta}`,
  width: `100%`,
  WebkitAppearance: 'none',
  outline: 'none',
  lineHeight: 1,
  fontWeight: 'inherit',
  color: `${colors.blackTransparent84}`,
  '&:hover': {
    borderColor: `${colors.primaryLinkHover}`
  },
  '&:focus': {
    borderColor: `${colors.primaryLinkActive}`
  },
  '&::placeholder': {
    fontSize: `${typography.baseSizes.theta}`
  }
};

const inheritPStyles = P.withComponent('input');
const StyledInput = styled(inheritPStyles, {
  shouldForwardProp: (prop) => isPropValid(prop)
})`
  ${StyledInputCSSObject};
  padding: ${(props) =>
    props.iconPrefix
      ? `${misc.input.paddingVertical} ${parseInt(misc.input.paddingHorizontal) + 24}px`
      : `${misc.input.paddingVertical} ${misc.input.paddingHorizontal}`};
  ${mixins.linkActiveStyles} {
    outline: none;
  }
  &[type='checkbox'] {
    display: none;
  }
`;

export default StyledInput;
