import React, { useContext, createContext, useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'components/Modal';
import CourseContainer from 'hooks/useCreateCourse';
import { find } from 'lodash-es';

const SubSectionSettingsContext = createContext();

export const useSubSectionSettingsContext = () => useContext(SubSectionSettingsContext);

const SubSectionSettingsProvider = ({ children }) => {
  const { isOpen, openModal, closeModal } = useModal(false);

  const { courseSettings } = CourseContainer.useContainer();
  const { sections } = courseSettings;

  // Set if a new sub section is being added or existing one edited
  const [isNewSubSection, toggleNewSubSection] = useState(true);

  const [parentSection, updateParentSection] = useState();
  const [subSectionSettings, updateSubSectionSettings] = useState();
  const [subSectionId, updateSubSectionId] = useState();
  useEffect(() => {
    if (sections && sections.length && subSectionId) {
      const subSection = find(parentSection.subsections, { _id: subSectionId });
      updateSubSectionSettings(subSection);
    }

    // If new sub section then clear subSectionSettings
    if (isNewSubSection) {
      updateSubSectionSettings();
    }
  }, [isNewSubSection, parentSection, sections, subSectionId]);

  const [hideDeleteOption, toggleHideDeleteOption] = useState(false);

  const editSubSectionSettings = useCallback(
    (sectionId, passedSubSectionId) => {
      toggleNewSubSection(false);
      updateSubSectionId(passedSubSectionId);
      updateParentSection(find(sections, { _id: sectionId }));
      openModal();
    },
    [openModal, sections]
  );

  const addNewSubSection = useCallback(
    (sectionId) => {
      toggleNewSubSection(true);
      updateParentSection(find(sections, { _id: sectionId }));
      openModal();
    },
    [openModal, sections]
  );

  const value = useMemo(
    () => ({
      closeModal,
      isOpen,
      subSectionSettings,
      courseSettings,
      isNewSubSection,
      editSubSectionSettings,
      addNewSubSection,
      parentSection,
      hideDeleteOption,
      toggleHideDeleteOption
    }),
    [
      closeModal,
      isOpen,
      subSectionSettings,
      courseSettings,
      isNewSubSection,
      editSubSectionSettings,
      addNewSubSection,
      parentSection,
      hideDeleteOption
    ]
  );

  return (
    <SubSectionSettingsContext.Provider value={value}>
      {children}
    </SubSectionSettingsContext.Provider>
  );
};

SubSectionSettingsProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default SubSectionSettingsProvider;
