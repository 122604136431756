/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, typography } from 'styles/utilities';

export const inlineErrorContainer = css`
  display: flex;
  align-items: center;
`;

export const errorText = css`
  &.error,
  &.notice,
  &.success {
    margin: 0;
    padding-left: 6px;
    font-size: ${typography.baseSizes.iota};
  }
  &.error {
    color: ${colors.red};
  }
  &.notice {
    color: ${colors.orange};
  }
  &.success {
    color: ${colors.green};
  }
`;
