import misc from 'styles/tokens/misc';
import hexToRgba from 'hex-to-rgba';

export const respondTo = (viewport) => `
  @media screen and (min-width: ${viewport})
`;

export const respondToMax = (viewport) => `
  @media screen and (max-width: ${parseInt(viewport) - 1}px)
`;

export const respondToMinMax = (viewport) => `
  @media screen and (min-width: ${viewport}px) and (max-width: ${parseInt(viewport) - 1}px)
`;

export const improveAntiAlias = () => `box-shadow: 0 0 1px rgba(0, 0, 0, 0);`;

export const fontSmooth = () => `
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
`;

export const fontHacks = () => `
  ${improveAntiAlias()}
  ${fontSmooth()}
`;

export const transition = ({
  duration = misc.transitions.duration,
  properties = misc.transitions.properties,
  easing = misc.transitions.easing,
  delay = misc.transitions.delay
} = {}) => `transition: ${duration} ${properties} ${easing} ${delay};`;

export const linkActiveStyles = () => `&:hover, &:focus, &:active`;

export const horizontalScrollingFlex = ({
  justifyContent = 'flex-start',
  minWidth = '45%'
} = {}) => `
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: ${justifyContent};
  > * {
    min-width: ${minWidth};
  }
`;

export const toRgba = (hexColor, opacity) => hexToRgba(hexColor, opacity);
