/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styling
import { spacer } from 'styles/utilities';
import P from 'components/htmlElements/P';
import * as styles from './ToggleSwitch.styles';

const ToggleSwitch = ({ state, onClick }) => {
  const [active, setActive] = useState(state);
  const changeToggleState = () => {
    setActive((s) => !s);
  };

  return (
    <div
      css={styles.toggleContainer}
      onClick={() => {
        changeToggleState();
        onClick();
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex="0"
    >
      <div css={[styles.stateContainer, spacer.padLR10]}>
        <P workSans medium className={`state ${active ? 'open' : ''}`}>
          Yes
        </P>
      </div>

      <div css={styles.backgroundAnimation} className={`background ${active ? 'open' : ''}`} />

      <div css={[styles.stateContainer, spacer.padLR10]}>
        <P workSans medium className="state no">
          No
        </P>
      </div>
    </div>
  );
};

ToggleSwitch.propTypes = {
  state: PropTypes.bool,
  onClick: PropTypes.func
};

ToggleSwitch.defaultProps = {
  state: false,
  onClick: () => {}
};

export default ToggleSwitch;
