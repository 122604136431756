import React from 'react';

import { useGetAllUsers } from 'api/user';

import ManageUserContainer from 'hooks/useManageUsers';

import { ROUTE_PATHS } from 'globals/constants';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';

import UserModalProvider from 'components/AddUserModal/AddUserModelContext';
import AddUserModal from 'components/AddUserModal/AddUserModal';
import UserManagement from './page';

const UserManagementContainer = () => {
  const { data: userData, status, error } = useGetAllUsers();

  if (error || (userData && userData.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (status !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  // return <UserManagement users={userData && userData.data} />;

  if (userData) {
    return (
      <ManageUserContainer.Provider initialState={userData.data}>
        <UserModalProvider>
          <UserManagement />
          <AddUserModal />
        </UserModalProvider>
      </ManageUserContainer.Provider>
    );
  }

  return (
    <ManageUserContainer.Provider initialState={[]}>
      <UserModalProvider>
        <UserManagement />
        <AddUserModal />
      </UserModalProvider>
    </ManageUserContainer.Provider>
  );
};

UserManagement.propTypes = {};

export default UserManagementContainer;
