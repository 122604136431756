/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const listItem = css`
  margin-right: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  &:not(:last-of-type) {
    border-right: 1px solid ${colors.blackTransparent10};
  }
`;

export const miniInput = css`
  display: flex;
  align-items: center;
  > *:first-of-type {
    margin-right: 10px;
  }
  input {
    width: 50px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
  }
`;
