import isNotUndefined from 'utils/isNotUndefined';

const getInitialState = (type) => ({
  type,
  [type]: {
    instructionsText: '',
    questionText: '',
    wordList: [], // Syntax: [{fullWord: "mountain", "partialWord": "mo"}]
    answer: [], // Syntax: [{ "text": "a", "shortId": "c8sd<UNIQUE ID>" }]
    solutionExplaination: '',
    questionSettings: {
      markingEnabled: true,
      defaultMarks: 1,
      negativeMarkingEnabled: false,
      defaultNegativeMarks: 0,
      partialMarking: true,
      timerAtQuestion: true,
      timeLimit: 0,
      pauseTimer: false
    },
    meta: []
  },
  testId: '',
  usage: 'final' // [final, sample, practice, note]

  // // Either one of this is to be set. `atLevelSubsectionId` takes precedence if multiple values present
  // // atLevelSubsectionId > atLevelSectionId > atLevelTestId
  // atLevelTestId: '',
  // atLevelSectionId: '',
  // atLevelSubsectionId: ''
});

const initialState = getInitialState();

const getFormValues = ({ questionDefaults = {}, questionObject }) => {
  const mergedData = {
    ...questionDefaults
  };

  if (questionObject.type) {
    mergedData.type = questionObject.type;
  }

  mergedData.instructionsText = questionObject[questionObject.type]?.instructionsText || '';

  mergedData.questionText = questionObject[questionObject.type]?.questionText || '';

  if (questionObject[questionObject.type]?.wordList) {
    mergedData.wordList = questionObject[questionObject.type]?.wordList;
  }

  if (questionObject[questionObject.type]?.answer) {
    mergedData.answer = questionObject[questionObject.type]?.answer;
  }

  mergedData.solutionExplaination = questionObject[questionObject.type]?.solutionExplaination || '';

  if (isNotUndefined(questionObject[questionObject.type]?.questionSettings?.markingEnabled)) {
    mergedData.markingEnabled =
      questionObject[questionObject.type]?.questionSettings?.markingEnabled;
  }

  if (isNotUndefined(questionObject[questionObject.type]?.questionSettings?.defaultMarks)) {
    mergedData.defaultMarks = questionObject[questionObject.type]?.questionSettings?.defaultMarks;
  }

  if (isNotUndefined(questionObject[questionObject.type]?.questionSettings?.defaultNegativeMarks)) {
    mergedData.defaultNegativeMarks =
      questionObject[questionObject.type]?.questionSettings?.defaultNegativeMarks;
  }

  if (
    isNotUndefined(questionObject[questionObject.type]?.questionSettings?.negativeMarkingEnabled)
  ) {
    mergedData.negativeMarkingEnabled =
      questionObject[questionObject.type]?.questionSettings?.negativeMarkingEnabled;
  }

  if (isNotUndefined(questionObject[questionObject.type]?.questionSettings?.partialMarking)) {
    mergedData.partialMarking =
      questionObject[questionObject.type]?.questionSettings?.partialMarking;
  }

  if (isNotUndefined(questionObject[questionObject.type]?.questionSettings?.timerAtQuestion)) {
    mergedData.timerAtQuestion =
      questionObject[questionObject.type]?.questionSettings?.timerAtQuestion;
  }

  if (isNotUndefined(questionObject[questionObject.type]?.questionSettings?.timeLimit)) {
    mergedData.timeLimit = questionObject[questionObject.type]?.questionSettings?.timeLimit;
  }

  if (isNotUndefined(questionObject[questionObject.type]?.questionSettings?.pauseTimer)) {
    mergedData.pauseTimer = questionObject[questionObject.type]?.questionSettings?.pauseTimer;
  }

  if (questionObject[questionObject.type]?.meta) {
    mergedData.meta = questionObject[questionObject.type]?.meta;
  }

  if (questionObject?.usage) {
    mergedData.usage = questionObject?.usage;
  }

  if (questionObject.testId) {
    mergedData.testId = questionObject.testId;
  }

  if (questionObject.atLevelTestId) {
    mergedData.atLevelTestId = questionObject.atLevelTestId;
  }

  if (questionObject.atLevelSectionId) {
    mergedData.atLevelSectionId = questionObject.atLevelSectionId;
  }

  if (questionObject.atLevelSubsectionId) {
    mergedData.atLevelSubsectionId = questionObject.atLevelSubsectionId;
  }

  return mergedData;
};

const formattedForAPI = (values) => {
  const questionObject = {};

  if (values.type) {
    questionObject.type = values.type;
  }

  // Set IDs needed for the question
  if (values.testId) {
    questionObject.testId = values.testId;
  }
  if (values.atLevelTestId) {
    questionObject.atLevelTestId = values.atLevelTestId;
  }
  if (values.atLevelSectionId) {
    questionObject.atLevelSectionId = values.atLevelSectionId;
  }
  if (values.atLevelSubsectionId) {
    questionObject.atLevelSubsectionId = values.atLevelSubsectionId;
  }

  if (values.usage) {
    questionObject.usage = values.usage;
  }

  // Set question data
  questionObject[values.type] = {
    ...(isNotUndefined(values.instructionsText) && { instructionsText: values.instructionsText }),
    ...(isNotUndefined(values.questionText) && { questionText: values.questionText }),
    ...(isNotUndefined(values.wordList) && { wordList: values.wordList }),
    ...(isNotUndefined(values.answer) && { answer: values.answer }),
    ...(isNotUndefined(values.solutionExplaination) && {
      solutionExplaination: values.solutionExplaination
    }),

    // Set settings
    questionSettings: {
      ...(isNotUndefined(values.markingEnabled) && { markingEnabled: values.markingEnabled }),
      ...(isNotUndefined(values.defaultMarks) && { defaultMarks: values.defaultMarks }),
      ...(isNotUndefined(values.negativeMarkingEnabled) && {
        negativeMarkingEnabled: values.negativeMarkingEnabled
      }),
      ...(isNotUndefined(values.defaultNegativeMarks) && {
        defaultNegativeMarks: values.defaultNegativeMarks
      }),
      ...(isNotUndefined(values.partialMarking) && { partialMarking: values.partialMarking }),
      ...(isNotUndefined(values.timerAtQuestion) && { timerAtQuestion: values.timerAtQuestion }),
      ...(isNotUndefined(values.timeLimit) && { timeLimit: values.timeLimit }),
      ...(isNotUndefined(values.pauseTimer) && { pauseTimer: values.pauseTimer })
    },

    meta: [...(isNotUndefined(values.meta) && values.meta)]
  };

  return questionObject;
};

export { initialState, formattedForAPI, getFormValues, getInitialState };
