/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import SettingsSummaryBand from 'components/SettingsSummaryBand';
import { Container } from 'components/Grid';
import EmptySectionsBand from 'components/EmptySectionsBand';
import { navigate, UnstyledLink } from 'components/Router';

import AddSectionBand from 'components/AddSectionBand';
import QuestionBand from 'components/QuestionBand';
import A from 'components/htmlElements/A';

// Constants
import { TEST_STRUCTURE } from 'globals/constants';

// hooks
import CreateTestContainer from 'hooks/useCreateTest';
import QuestionsContainer from 'hooks/useCreateQuestion';

import { useSectionSettingsContext } from 'components/SectionSettingsForm/SectionSettingsContext';
import SettingsProviders from 'components/SettingsProviders';
import { useVersionModalContext } from 'components/VersionModal/VersionModalContext';

// Page sections

// Styling
import { spacer, bgColor } from 'styles/utilities';

import SortableSectionSummaryBand from 'components/SectionSettingsForm/SortableSectionSummaryBand';

import HeaderDetails from 'components/HeaderDetails';
import * as styles from './page.styles';

const TestStructureAtTest = ({ testSettings }) => {
  const { questions } = QuestionsContainer.useContainer();

  const handleNewQuestionOnClick = () => {
    navigate(`/test/${testSettings._id}/questions`);
  };

  const handlePreviewQuestion = () => `/test/${testSettings._id}/preview`;

  return (
    <Fragment>
      <AddSectionBand
        title={`Questions (${questions.length})`}
        buttonText="Add Question"
        onHandleClick={handleNewQuestionOnClick}
      />

      {questions && questions.length > 0 ? (
        <div>
          <div className="d-flex justify-content-end">
            <A as={UnstyledLink} to={handlePreviewQuestion()} semiBold>
              Preview questions
            </A>
          </div>

          <div
            css={[
              bgColor.white,
              spacer.padB15,
              spacer.padT20,
              spacer.padLR20,
              styles.questionContainer
            ]}
          >
            <QuestionBand handleNewQuestionOnClick={handleNewQuestionOnClick} />
          </div>
        </div>
      ) : (
        <EmptySectionsBand questions />
      )}
    </Fragment>
  );
};
TestStructureAtTest.propTypes = {
  testSettings: PropTypes.object.isRequired
};

const TestStructureNotAtTest = ({ testSettings }) => {
  const { sections } = testSettings;
  const { addNewSection } = useSectionSettingsContext();

  return (
    <Fragment>
      <AddSectionBand
        title={`Sections (${sections.length})`}
        buttonText="Add Section"
        onHandleClick={addNewSection}
      />

      {/* Listing Section  */}
      <Fragment>
        {sections && sections.length > 0 ? <SortableSectionSummaryBand /> : <EmptySectionsBand />}
      </Fragment>
    </Fragment>
  );
};
TestStructureNotAtTest.propTypes = {
  testSettings: PropTypes.object.isRequired
};

const TestOverview = () => {
  const createTestContainer = CreateTestContainer.useContainer();
  const { testSettings } = createTestContainer;
  const { _id: testId } = testSettings;

  const { openModal: openVersionModal } = useVersionModalContext();

  return (
    <Fragment>
      {/* Header Section */}
      <HeaderDetails
        name={testSettings.name}
        id={testId}
        primaryCtaLink={`/test/${testId}/settings`}
        isDraft={testSettings.isDraft}
        openVersionModal={openVersionModal}
      />

      <section css={[spacer.padBT30, bgColor.mediumGrey]}>
        <Container>
          <SettingsSummaryBand />

          {/* If question structure is of test level display add questions section */}
          {testSettings.questionStructure === TEST_STRUCTURE.test ? (
            <TestStructureAtTest testSettings={testSettings} />
          ) : (
            <TestStructureNotAtTest testSettings={testSettings} />
          )}
        </Container>
      </section>
    </Fragment>
  );
};

const WithProviders = (props) => (
  <SettingsProviders>
    <TestOverview {...props} />
  </SettingsProviders>
);

export default WithProviders;
