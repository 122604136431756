import React, { useContext, createContext, useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'components/Modal';
import CourseContainer from 'hooks/useCreateCourse';
import { find } from 'lodash-es';

const SectionSettingsContext = createContext();

export const useSectionSettingsContext = () => useContext(SectionSettingsContext);

const SectionSettingsProvider = ({ children }) => {
  const { isOpen, openModal, closeModal } = useModal(false);

  const { courseSettings } = CourseContainer.useContainer();
  const { sections } = courseSettings;

  // Set if a new section is being added or existing one edited
  const [isNewSection, toggleNewSection] = useState(true);

  const [sectionSettings, updateSectionSettings] = useState();
  const [sectionId, updateSectionId] = useState();
  useEffect(() => {
    if (sections && sections.length && sectionId) {
      updateSectionSettings(find(sections, { _id: sectionId }));
    }

    // If new section then clear sectionSettings
    if (isNewSection) {
      updateSectionSettings();
    }
  }, [isNewSection, sectionId, sections]);

  const [hideDeleteOption, toggleHideDeleteOption] = useState(false);

  const editSectionSettings = useCallback(
    (passedSectionId) => {
      toggleNewSection(false);
      updateSectionId(passedSectionId);
      openModal();
    },
    [openModal]
  );

  const addNewSection = useCallback(() => {
    toggleNewSection(true);
    openModal();
  }, [openModal]);

  const value = useMemo(
    () => ({
      closeModal,
      isOpen,
      sectionSettings,
      courseSettings,
      isNewSection,
      editSectionSettings,
      addNewSection,
      hideDeleteOption,
      toggleHideDeleteOption
    }),
    [
      addNewSection,
      closeModal,
      courseSettings,
      editSectionSettings,
      hideDeleteOption,
      isNewSection,
      isOpen,
      sectionSettings
    ]
  );

  return (
    <SectionSettingsContext.Provider value={value}>{children}</SectionSettingsContext.Provider>
  );
};

SectionSettingsProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default SectionSettingsProvider;
