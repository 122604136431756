/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Container, Row, Col } from 'components/Grid';
import RichTextEditor from 'components/RichTextEditor';
import H4 from 'components/htmlElements/H4';
import { colors, spacer } from 'styles/utilities';
import A from 'components/htmlElements/A';
import { useUpdateQuestion } from 'api/questions';
import Input from 'components/htmlElements/Fields/Input';
import QuestionSettingsBand from '../shared-components/QuestionSettingsBand';
import { getFormValues, formattedForAPI } from './helpers';

import * as styles from '../QuestionTypes.styles';
import * as geogebraStyles from './GeoGebra.styles';
import MetaData from '../shared-components/MetaData';
import SubmissionBand from '../shared-components/SubmissionBand';
import GeoGebra from './GeoGebra';

const QuestionSchema = Yup.object().shape({
  // questionText: Yup.string().required('You cannot leave question empty'),
  // instructionText: Yup.string()
});

const GeoGebraQuestion = ({
  questionData,
  handleQuestionDelete,
  waterfallSettings,
  initialState
}) => {
  const [initialValues, updateInitialValues] = useState(
    getFormValues({
      questionObject: initialState
    })
  );
  useEffect(() => {
    if (initialState) {
      updateInitialValues(
        getFormValues({
          // questionDefaults: {},
          questionObject: initialState
        })
      );
    }
  }, [initialState]);

  useEffect(() => {
    // Get question info from api and call it here
    // `questionData.type === initialState.type` checks if the question on server is of same type.
    // If it is of same type, then only load data. Otherwise we assume the user is editing the question to a different type
    if (questionData && questionData.type === initialState.type) {
      updateInitialValues((prevState) =>
        getFormValues({
          questionDefaults: { ...prevState, ...waterfallSettings },
          questionObject: questionData
        })
      );
    }
  }, [initialState, initialState.type, questionData, waterfallSettings]);

  const { updateQuestion, isSuccess } = useUpdateQuestion();
  const setAnswerField = (values) => {
    const updatedValues = values;
    updatedValues.answer = [values.answerValue];

    return updatedValues;
  };

  const handleSubmit = async (values, actions) => {
    actions.setValues(setAnswerField(values));

    actions.setSubmitting(true);

    const questionDataToSend = formattedForAPI(values);

    await updateQuestion({ questionId: questionData._id, questionData: questionDataToSend });

    actions.setSubmitting(false);

    if (isSuccess) return true;
    return null;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues, answerValue: initialValues?.answer[0] }}
      onSubmit={handleSubmit}
      validationSchema={QuestionSchema}
    >
      {({ values, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Container>
            <Row>
              <Col>
                <QuestionSettingsBand values={values} setFieldValue={setFieldValue} />

                <div css={[styles.divider]} />

                <Field
                  name="questionText"
                  as={RichTextEditor}
                  value={values.questionText}
                  onChange={(data) => setFieldValue('questionText', data)}
                />

                <div css={[styles.divider]} />

                <GeoGebra values={values} key={values?.geogebraApplet || 'applet'} />

                <div className="d-flex align-items-center" css={spacer.mrT40}>
                  <div
                    className="d-flex align-items-center"
                    css={[spacer.mrR40, geogebraStyles.inputsWrapper]}
                  >
                    <H4 bold gtWalsheim className="d-inline text-nowrap" css={spacer.mrR10}>
                      Applet ID :
                    </H4>
                    <Field
                      name="geogebraApplet"
                      as={Input}
                      css={{ border: `1px solid ${colors.blackTransparent50}` }}
                    />
                  </div>

                  <div className="d-flex align-items-center" css={geogebraStyles.inputsWrapper}>
                    <H4 bold gtWalsheim className="d-inline text-nowrap" css={spacer.mrR10}>
                      Answer :
                    </H4>
                    <Field
                      name="answerValue"
                      as={Input}
                      css={{ border: `1px solid ${colors.blackTransparent50}` }}
                    />
                  </div>
                </div>

                <div css={[styles.divider]} />

                <H4 workSans css={spacer.mrB20}>
                  Solution Explanation
                </H4>
                <Field
                  name="solutionExplaination"
                  as={RichTextEditor}
                  value={values.solutionExplaination}
                  onChange={(data) => setFieldValue('solutionExplaination', data)}
                />

                <div css={[styles.divider]} />

                <MetaData values={values} />

                <div css={[styles.sectionBorder, spacer.mrT40]} />
                <div
                  css={[spacer.mrT20, spacer.mrLR15, spacer.mrB40]}
                  className="d-flex justify-content-end"
                >
                  <A
                    as="span"
                    small
                    workSans
                    semiBold
                    color="red"
                    onClick={() => handleQuestionDelete(questionData._id)}
                    className="m-0"
                  >
                    Delete question
                  </A>
                </div>

                <SubmissionBand isSubmitting={isSubmitting} submitForm={submitForm} />
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

GeoGebraQuestion.propTypes = {
  questionData: PropTypes.object,
  handleQuestionDelete: PropTypes.func.isRequired,
  waterfallSettings: PropTypes.object,
  initialState: PropTypes.object.isRequired
};

GeoGebraQuestion.defaultProps = {
  waterfallSettings: {},
  questionData: {}
};

export default GeoGebraQuestion;
