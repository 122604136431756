/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import NavsContainer from 'hooks/useNavs';
import * as styles from './SidebarShell.styles';

const SidebarShell = ({ children }) => {
  const { navHeight } = NavsContainer.useContainer();
  return (
    <Fragment>
      <div css={styles.wrapper({ top: navHeight })}>{children}</div>
      <div css={styles.fauxSidebar} />
    </Fragment>
  );
};

SidebarShell.propTypes = {
  children: PropTypes.node.isRequired
};

export default SidebarShell;
