/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

// import { misc, colors, mixins } from 'styles/utilities';

export const fileInModal = css`
  margin: 20px 0;
  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
`;
