import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ style, className, children, ...props }) => (
  <div className={`${className || ''} container`} style={style} {...props}>
    {children}
  </div>
);
Container.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node
};
Container.defaultProps = {
  style: {},
  className: '',
  children: null
};

const Row = ({ style, className, children, ...props }) => (
  <div className={`${className || ''} row`} style={style} {...props}>
    {children}
  </div>
);
Row.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node
};
Row.defaultProps = {
  style: {},
  className: '',
  children: null
};

const Col = ({ style, className, children, ...props }) => {
  let defaultColClass = 'col-12';

  if (
    className.includes('col-1') ||
    className.includes('col-2') ||
    className.includes('col-3') ||
    className.includes('col-4') ||
    className.includes('col-5') ||
    className.includes('col-6') ||
    className.includes('col-7') ||
    className.includes('col-8') ||
    className.includes('col-9') ||
    className.includes('col-10') ||
    className.includes('col-11')
  ) {
    defaultColClass = '';
  }

  return (
    <div className={`col-class ${className || ''} ${defaultColClass}`} style={style} {...props}>
      {children}
    </div>
  );
};
Col.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node
};
Col.defaultProps = {
  style: {},
  className: '',
  children: null
};

export { Container, Row, Col };
