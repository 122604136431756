/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, misc } from 'styles/utilities';

export const sidebarWrapper = css`
  background-color: ${colors.white};
  border-right: 1px solid ${colors.blackTransparent20};
  position: sticky;
  top: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: ${misc.overlayZindex};
  padding: 30px 0px;

  .main-nav {
    gap: 10px;
  }

  .logout {
    background-color: ${colors.lightBlue};
  }

  .link-wrapper,
  .logout {
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: ${misc.mediumBorderRadius};
    white-space: nowrap;

    &.active,
    &:hover {
      background-color: ${colors.lightBlue};
    }
  }

  &:hover {
    .expanded-nav {
      display: flex;
      visibility: visible;
    }
  }

  .sidebar-toggle {
    position: absolute;
    bottom: 50%;
    right: -20px;
    background-color: ${colors.white};
    border-radius: 50%;
    cursor: pointer;
    z-index: ${misc.overlayZindex + 1};
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px ${colors.blackTransparent20};

    &:hover {
      background-color: ${colors.lightBlue};
    }
  }
`;
