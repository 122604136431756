/** @jsxImportSource @emotion/react */
import React from 'react';
import { Global } from '@emotion/react';
import PropTypes from 'prop-types';

import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';

import * as styles from './SimpleDropdown.styles';

const SimpleDropdown = React.forwardRef(({ children, ...props }, ref) => (
  <Dropdown {...props} css={styles.dropdownContentStyles} ref={ref}>
    <Global styles={styles.simpleDropdownGlobalStyles} />
    {children}
  </Dropdown>
));

SimpleDropdown.propTypes = {
  children: PropTypes.node.isRequired
};

export default SimpleDropdown;
export { DropdownTrigger, DropdownContent };
