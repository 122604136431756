import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

const H5 = styled.h5`
  font-family: ${styledMap`
    gtWalsheim: ${typography.fonts.gtWalsheim.fontFamily};
    workSans: ${typography.fonts.workSans.fontFamily};
    default: ${typography.fonts.cooperBT.fontFamily};
    `};
  font-weight: ${(props) => {
    if (props.workSans) {
      if (props.semiBold) {
        return typography.fonts.workSans.fontWeight.semiBold;
      }
      return typography.fonts.workSans.fontWeight.regular;
    }
    if (props.gtWalsheim) {
      if (props.bold) {
        return typography.fonts.gtWalsheim.fontWeight.bold;
      }
      if (props.medium) {
        return typography.fonts.gtWalsheim.fontWeight.medium;
      }
      return typography.fonts.gtWalsheim.fontWeight.light;
    }
    return typography.fonts.cooperBT.fontWeight.light;
  }};
  line-height: 140%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['14px', typography.baseSizes.eta]
  })};
`;

export default H5;
