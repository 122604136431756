/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import { Field, useField } from 'formik';
import ToggleSwitch from 'components/ToggleSwitch';

import { Input, FieldError, FieldLabel } from 'components/htmlElements/Fields';

const FieldWithFormik = ({ label, children, as, ...props }) => {
  const [field, meta] = useField(props);

  let additionalMarkup;
  let hideLabel = false;

  const FieldItem = (
    <Field {...field} {...props} as={as} id={field.name}>
      {children}
    </Field>
  );

  switch (props.type) {
    case 'checkbox':
      additionalMarkup = field.value !== undefined && <ToggleSwitch state={field.value} />;
      break;
    case 'hidden':
      hideLabel = true;
      return FieldItem;
    default:
      break;
  }

  return (
    <div className={`${hideLabel ? 'd-none' : ''}`}>
      <FieldLabel className="placeholder" htmlFor={field.name} required={props.required}>
        {label}
      </FieldLabel>
      <FieldLabel className="w-100" htmlFor={field.name} required={props.required}>
        {FieldItem}
        {additionalMarkup}
      </FieldLabel>

      {meta.touched && meta.error ? <FieldError>{meta.error}</FieldError> : null}
    </div>
  );
};
FieldWithFormik.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  required: PropTypes.bool,
  as: PropTypes.any,
  type: PropTypes.string.isRequired
};
FieldWithFormik.defaultProps = {
  children: null,
  required: false,
  as: Input
};

export const FieldRenderer = ({ field, fieldName, ...props }) => (
  <FieldWithFormik
    // type={field.input.type}
    name={fieldName}
    label={field.text}
    {...props}
    {...field.input}
  />
);
FieldRenderer.propTypes = {
  field: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired
};

export default FieldWithFormik;
