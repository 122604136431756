/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Components
import H4 from 'components/htmlElements/H4';
import RichTextEditor from 'components/RichTextEditor';
import { Container, Row, Col } from 'components/Grid';
import RepeaterOptions from 'components/QuestionTypes/shared-components/RepeaterOptions';
import QuestionSettingsBand from 'components/QuestionTypes/shared-components/QuestionSettingsBand';
import SubmissionBand from 'components/QuestionTypes/shared-components/SubmissionBand';
import MetaData from 'components/QuestionTypes/shared-components/MetaData';
import { useUpdateQuestion } from 'api/questions';

// Styling
import { spacer } from 'styles/utilities';
import A from 'components/htmlElements/A';
import Checkbox from 'components/Checkbox';
import * as styles from '../QuestionTypes.styles';

import { getFormValues, formattedForAPI } from './helpers';
import InsertComprehensionImages from './InsertComprehensionImages';

const QuestionSchema = Yup.object().shape({
  // questionText: Yup.string().required('You cannot leave question empty'),
  // instructionText: Yup.string()
});

const TwoColumnComprehension = ({
  questionData,
  handleQuestionDelete,
  waterfallSettings,
  initialState
}) => {
  const [initialValues, updateInitialValues] = useState(
    getFormValues({
      questionObject: initialState
    })
  );
  useEffect(() => {
    if (initialState) {
      updateInitialValues(
        getFormValues({
          // questionDefaults: {},
          questionObject: initialState
        })
      );
    }
  }, [initialState]);

  useEffect(() => {
    // Get question info from api and call it here
    // `questionData.type === initialState.type` checks if the question on server is of same type.
    // If it is of same type, then only load data. Otherwise we assume the user is editing the question to a different type
    if (questionData && questionData.type === initialState.type) {
      updateInitialValues((prevState) =>
        getFormValues({
          questionDefaults: { ...prevState, ...waterfallSettings },
          questionObject: questionData
        })
      );
    }
  }, [initialState, initialState.type, questionData, waterfallSettings]);

  const { updateQuestion, isSuccess } = useUpdateQuestion();

  const setAnswerField = (values) => {
    const correctAnswers = values.answerOptions
      .filter((answerOption) => answerOption.isCorrect === true)
      .map((answerOption) => answerOption.shortId);

    const updatedValues = values;
    updatedValues.answer = correctAnswers;
    return updatedValues;
  };

  const handleSubmit = async (values, actions) => {
    actions.setValues(setAnswerField(values));

    actions.setSubmitting(true);

    const questionDataToSend = formattedForAPI(values);
    await updateQuestion({ questionId: questionData._id, questionData: questionDataToSend });

    actions.setSubmitting(false);

    if (isSuccess) return true;
    return null;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={QuestionSchema}
    >
      {({ values, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Container>
            <Row>
              <Col>
                <QuestionSettingsBand values={values} setFieldValue={setFieldValue}>
                  <Field
                    as={Checkbox}
                    name="allowZoom"
                    label="Allow zooming on images"
                    type="checkbox"
                  />
                </QuestionSettingsBand>

                <div css={[styles.divider]} />

                <InsertComprehensionImages setFieldValue={setFieldValue} values={values} />

                <Field
                  name="questionText"
                  as={RichTextEditor}
                  value={values.questionText}
                  onChange={(data) => setFieldValue('questionText', data)}
                />

                <div css={[styles.divider]} />

                <H4 workSans css={spacer.mrB20}>
                  Answer Options
                </H4>
                <RepeaterOptions setFieldValue={setFieldValue} values={values} />

                <div css={[styles.divider]} />

                <H4 workSans css={spacer.mrB20}>
                  Solution Explanation
                </H4>
                <Field
                  name="solutionExplaination"
                  as={RichTextEditor}
                  value={values.solutionExplaination}
                  onChange={(data) => setFieldValue('solutionExplaination', data)}
                />

                <div css={[styles.divider]} />

                <MetaData values={values} />

                <div css={[styles.sectionBorder, spacer.mrT40]} />
                <div
                  css={[spacer.mrT20, spacer.mrLR15, spacer.mrB40]}
                  className="d-flex justify-content-end"
                >
                  <A
                    as="span"
                    small
                    workSans
                    semiBold
                    color="red"
                    onClick={() => handleQuestionDelete(questionData._id)}
                    className="m-0"
                  >
                    Delete question
                  </A>
                </div>

                <SubmissionBand isSubmitting={isSubmitting} submitForm={submitForm} />
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

TwoColumnComprehension.propTypes = {
  questionData: PropTypes.object,
  handleQuestionDelete: PropTypes.func.isRequired,
  waterfallSettings: PropTypes.object,
  initialState: PropTypes.object.isRequired
};
TwoColumnComprehension.defaultProps = {
  waterfallSettings: {},
  questionData: {}
};

// TwoColumnComprehension.whyDidYouRender = true;
export default TwoColumnComprehension;
