/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { omit } from 'lodash-es';

import {
  FieldWrapper,
  FieldLabel,
  Input,
  FieldError,
  Select
} from 'components/htmlElements/Fields';
import H2 from 'components/htmlElements/H2';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';

import ManageUserContainer from 'hooks/useManageUsers';
import Modal from 'components/Modal/Modal';

import { UserInfoContainer } from 'api/auth';

import { spacer } from 'styles/utilities';
import * as modalStyles from 'components/Modal/Modal.styles';
import { useUserModalContext } from './AddUserModelContext';

const UserFormSchema = Yup.object().shape({
  name: Yup.string().required('User name required'),
  email: Yup.string().email('Enter a valid email').required('Field required'),
  password: Yup.string().required('Pasword required'),
  roles: Yup.string().required('Role required')
});

const EditUserSchema = Yup.object().shape({
  name: Yup.string().required('User name required'),
  roles: Yup.string().required('Role required')
});

const userRoles = ['admin', 'editor'];

const userRoleOptions = userRoles.map((role) => (
  <option key={role} value={role}>
    {role.charAt(0).toUpperCase() + role.slice(1)}
  </option>
));

const AddUserModal = () => {
  const { isOpen, closeModal, isEdit, userData } = useUserModalContext();
  const { addNewUser, updateSingleUser } = ManageUserContainer.useContainer();
  const currentUser = UserInfoContainer.useContainer();

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    let success;
    if (isEdit) {
      success = await updateSingleUser(omit(values, 'password', 'email'), { userId: userData._id });
    } else {
      success = await addNewUser(values);
    }
    if (success) {
      if (success._id === currentUser._id) currentUser.setUserInfo({ ...currentUser, ...success });
      closeModal();
    }
    actions.setSubmitting(false);
  };

  return (
    <Modal toggle={closeModal} isOpen={isOpen}>
      <Fragment>
        <div css={modalStyles.header}>
          <H2>{isEdit ? 'Update' : 'Add New'} User</H2>
        </div>
        <Formik
          validationSchema={isEdit ? EditUserSchema : UserFormSchema}
          initialValues={{
            name: userData ? userData.name : '',
            email: '',
            password: '',
            roles: userData ? userData.roles[0] : ''
          }}
          onSubmit={handleSubmit}
        >
          {({ errors, isSubmitting, touched }) => (
            <Form>
              <div css={[spacer.mrLR20, spacer.mrT20]}>
                <FieldWrapper>
                  <FieldLabel className="placeholder" htmlFor="user-name" required>
                    Name
                  </FieldLabel>
                  <Field name="name" id="user-name" as={Input} />
                  {errors.name && touched.name && <FieldError>{errors.name}</FieldError>}
                </FieldWrapper>

                {!isEdit && (
                  <FieldWrapper>
                    <FieldLabel className="placeholder" htmlFor="user-email" required>
                      Email
                    </FieldLabel>
                    <Field name="email" id="user-email" as={Input} type="email" />
                    {errors.email && touched.email && <FieldError>{errors.email}</FieldError>}
                  </FieldWrapper>
                )}

                {!isEdit && (
                  <FieldWrapper>
                    <FieldLabel className="placeholder" htmlFor="user-password" required>
                      Password
                    </FieldLabel>
                    <Field name="password" id="user-password" as={Input} type="password" />
                    {errors.password && touched.password && (
                      <FieldError>{errors.password}</FieldError>
                    )}
                  </FieldWrapper>
                )}

                <FieldWrapper>
                  <FieldLabel className="placeholder" htmlFor="user-role" required>
                    Select role
                  </FieldLabel>
                  <Field name="roles" id="user-role" as={Select} type="select">
                    <option value="" disabled defaultValue>
                      Select role
                    </option>
                    {userRoleOptions}
                  </Field>
                  {errors.roles && touched.roles && <FieldError>{errors.roles}</FieldError>}
                </FieldWrapper>

                <div className="d-flex justify-content-end align-items-center">
                  <A as="span" onClick={closeModal} css={spacer.mrR20}>
                    Cancel
                  </A>
                  <Button type="submit" loading={isSubmitting}>
                    {isEdit ? 'Update' : 'Add '}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Fragment>
    </Modal>
  );
};

AddUserModal.propTypes = {};
export default AddUserModal;
