/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';

import { UserInfoContainer } from 'api/auth';

import ManageUserContainer from 'hooks/useManageUsers';

import H2 from 'components/htmlElements/H2';
import { Container, Row, Col } from 'components/Grid';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';

import { spacer } from 'styles/utilities';
import { useUserModalContext } from 'components/AddUserModal/AddUserModelContext';
import * as styles from './page.styles';

const UserManagement = () => {
  const [overlay, setOverlay] = useState(false);

  const { openToAddUser, openToEditUser, setUserData } = useUserModalContext();

  const currentUser = UserInfoContainer.useContainer();
  const { users, deleteSingleUser } = ManageUserContainer.useContainer();

  const handleDeleteUser = async (userId) => {
    if (currentUser._id === userId) return;
    setOverlay(true);
    await deleteSingleUser(userId);
    setOverlay(false);
  };

  const handleEditUser = (e, user) => {
    e.preventDefault();
    setUserData({ ...user });
    openToEditUser();
  };

  return (
    <Fragment>
      <section css={spacer.mrBT60}>
        <Container>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <H2 workSans semiBold color="blackTransparent50">
                  Users ({users.length})
                </H2>
                <Button onClick={openToAddUser}>Add new User</Button>
              </div>
            </Col>
          </Row>

          <div css={[spacer.mrT40, styles.isRelative, styles.userTable]}>
            <table id="users" className="w-100">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {users &&
                  users.map((user) => (
                    <tr key={user._id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.roles[0].charAt(0).toUpperCase() + user.roles[0].slice(1)}</td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center">
                          {currentUser && currentUser._id !== user._id ? (
                            <A
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteUser(user._id);
                              }}
                              href="#"
                            >
                              Disable
                            </A>
                          ) : (
                            <span />
                          )}
                          <A href="#" onClick={(e) => handleEditUser(e, user)} css={spacer.mrR20}>
                            Edit details
                          </A>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {overlay && <LoaderOverlay />}
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

UserManagement.propTypes = {};

export default UserManagement;
