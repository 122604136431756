/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Components
import H4 from 'components/htmlElements/H4';
import H5 from 'components/htmlElements/H5';
import RichTextEditor from 'components/RichTextEditor';
import { Container, Row, Col } from 'components/Grid';
import QuestionSettingsBand from 'components/QuestionTypes/shared-components/QuestionSettingsBand';
import SubmissionBand from 'components/QuestionTypes/shared-components/SubmissionBand';
import MetaData from 'components/QuestionTypes/shared-components/MetaData';
import AnagramSorter from 'components/QuestionTypes/shared-components/AnagramSorter';
import { useUpdateQuestion } from 'api/questions';
import { Input, Select } from 'components/htmlElements/Fields';
import { isEqual } from 'lodash-es';

// Styling
import { spacer } from 'styles/utilities';
import A from 'components/htmlElements/A';
import * as styles from '../QuestionTypes.styles';

import { getFormValues, formattedForAPI } from './helpers';

const QuestionSchema = Yup.object().shape({
  // questionText: Yup.string().required('You cannot leave question empty'),
  // instructionText: Yup.string()
});

const ShuffledSentencesQuestion = ({
  questionData,
  handleQuestionDelete,
  waterfallSettings,
  initialState
}) => {
  const [initialValues, updateInitialValues] = useState(
    getFormValues({
      questionObject: initialState
    })
  );
  useEffect(() => {
    if (initialState) {
      updateInitialValues(
        getFormValues({
          // questionDefaults: {},
          questionObject: initialState
        })
      );
    }
  }, [initialState]);

  useEffect(() => {
    // Get question info from api and call it here
    // `questionData.type === initialState.type` checks if the question on server is of same type.
    // If it is of same type, then only load data. Otherwise we assume the user is editing the question to a different type
    if (questionData && questionData.type === initialState.type) {
      updateInitialValues((prevState) =>
        getFormValues({
          questionDefaults: { ...prevState, ...waterfallSettings },
          questionObject: questionData
        })
      );
    }
  }, [initialState, initialState.type, questionData, waterfallSettings]);

  const { updateQuestion, isSuccess } = useUpdateQuestion();

  const setAnswerField = (values) => {
    const updatedValues = values;

    if (typeof updatedValues.answer === 'string') {
      updatedValues.answer = [updatedValues.answer]; // Simply convert to array
    }
    return updatedValues;
  };

  // Function checks if `mixedWordOrder` is returned from API and uses it.
  // If it's not returned, we assume it's a new question and user can
  // type in the sentence in `anagramInCorrectSequence` field
  const formatMixedWordOrderForUI = (mixedWordOrder, anagramInCorrectSequence) => {
    const arrayFromMixedWordOrder = mixedWordOrder?.map((word) => word.text) || [];
    const arrayFromInputField = anagramInCorrectSequence?.split(' ') || [];

    // Clone array by value rather than reference
    // @ref: https://stackoverflow.com/a/7486130
    const sortedArrayFromMixedWordOrder = arrayFromMixedWordOrder.slice();
    const sortedArrayFromInputField = arrayFromInputField.slice();

    // console.info(arrayFromMixedWordOrder, arrayFromInputField);
    if (isEqual(sortedArrayFromMixedWordOrder.sort(), sortedArrayFromInputField.sort())) {
      return arrayFromMixedWordOrder;
    }
    if (arrayFromMixedWordOrder.length && !arrayFromInputField.length) {
      return arrayFromMixedWordOrder;
    }
    return arrayFromInputField;
  };

  // To send it back to the API
  // Formatted according to API specs
  const handleAnagramSorterCallback = (mixedWord, setFieldValue) => {
    const formattedMixedWordOrder = mixedWord.map((word) => ({
      text: word,
      type: 'alphabets'
    }));
    // console.info(mixedWord, formattedMixedWordOrder);
    setFieldValue('mixedWordOrder', formattedMixedWordOrder);
  };

  const setMixedWordOrderField = (values) => {
    const updatedValues = values;

    updatedValues.mixedWordOrder = formatMixedWordOrderForUI(
      updatedValues.mixedWordOrder,
      updatedValues.anagramInCorrectSequence
    ).map((word) => ({
      // Formatted according to API specs
      text: word,
      type: 'alphabets'
    }));

    return updatedValues;
  };

  const handleSubmit = async (values, actions) => {
    actions.setValues(setAnswerField(values));
    actions.setValues(setMixedWordOrderField(values));

    actions.setSubmitting(true);

    const questionDataToSend = formattedForAPI(values);
    await updateQuestion({ questionId: questionData._id, questionData: questionDataToSend });

    actions.setSubmitting(false);

    if (isSuccess) return true;
    return null;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={QuestionSchema}
    >
      {({ values, isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Container>
            <Row>
              <Col>
                <QuestionSettingsBand values={values} setFieldValue={setFieldValue} />

                <div css={[styles.divider]} />

                <Field
                  name="questionText"
                  as={RichTextEditor}
                  value={values.questionText}
                  onChange={(data) => setFieldValue('questionText', data)}
                />

                <Row>
                  <Col className="col-12" css={spacer.mrT20}>
                    <H5 workSans semiBold>
                      Sentence in correct sequence
                    </H5>
                    <Field
                      as={Input}
                      type="text"
                      name="anagramInCorrectSequence"
                      value={
                        values.anagramInCorrectSequence ||
                        formatMixedWordOrderForUI(
                          values.mixedWordOrder,
                          values.anagramInCorrectSequence
                        ).join(' ')
                      }
                    />

                    <H5 workSans semiBold css={spacer.mrT20}>
                      Sentence in mixed sequence (This will be shown to user)
                    </H5>
                    <AnagramSorter
                      wordArray={formatMixedWordOrderForUI(
                        values.mixedWordOrder,
                        values.anagramInCorrectSequence
                      )}
                      fluidCell
                      callbackFunction={(mixedWord) =>
                        handleAnagramSorterCallback(mixedWord, setFieldValue)
                      }
                    />
                  </Col>
                  <Col className="col-12" css={spacer.mrT20}>
                    <H5 workSans semiBold>
                      Extra word to remove
                    </H5>
                    <Field as={Select} name="answer">
                      <option disabled defaultValue="" label="Select word to remove" />
                      {formatMixedWordOrderForUI(
                        values.mixedWordOrder,
                        values.anagramInCorrectSequence
                      ).map((word) => (
                        <option
                          value={word}
                          label={word}
                          key={word}
                          selected={values.answer === word}
                        />
                      ))}
                    </Field>
                  </Col>
                </Row>

                <div css={[styles.divider]} />

                <H4 workSans css={spacer.mrB20}>
                  Solution Explanation
                </H4>
                <Field
                  name="solutionExplaination"
                  as={RichTextEditor}
                  value={values.solutionExplaination}
                  onChange={(data) => setFieldValue('solutionExplaination', data)}
                />

                <div css={[styles.divider]} />

                <MetaData values={values} />

                <div css={[styles.sectionBorder, spacer.mrT40]} />
                <div
                  css={[spacer.mrT20, spacer.mrLR15, spacer.mrB40]}
                  className="d-flex justify-content-end"
                >
                  <A
                    as="span"
                    small
                    workSans
                    semiBold
                    color="red"
                    onClick={() => handleQuestionDelete(questionData._id)}
                    className="m-0"
                  >
                    Delete question
                  </A>
                </div>

                <SubmissionBand isSubmitting={isSubmitting} submitForm={submitForm} />
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

ShuffledSentencesQuestion.propTypes = {
  questionData: PropTypes.object,
  handleQuestionDelete: PropTypes.func.isRequired,
  waterfallSettings: PropTypes.object,
  initialState: PropTypes.object.isRequired
};
ShuffledSentencesQuestion.defaultProps = {
  waterfallSettings: {},
  questionData: {}
};

// ShuffledSentencesQuestion.whyDidYouRender = true;
export default ShuffledSentencesQuestion;
