import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useNavs = () => {
  const [navHeight, updateNavHeight] = useState(0);

  return {
    navHeight,
    updateNavHeight
  };
};

const NavsContainer = createContainer(useNavs);
export default NavsContainer;
