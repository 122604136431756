/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import { Container } from 'components/Grid';
import H3 from 'components/htmlElements/H3';
import Icon from 'components/Icon';

import { spacer, bgColor } from 'styles/utilities';
import PreviewTextBasedQuestion from '../previewTemplates/PreviewTextBasedQuestion';

import * as styles from '../page.styles';

const QuestionPreviewSection = ({ questions, questionNumber }) => {
  const currentQuestion = questions[questionNumber];

  return (
    <div css={[spacer.padB80, styles.previewSection, bgColor.white]}>
      <Container>
        <div className="d-flex justify-content-end" css={spacer.mrT10}>
          <button
            type="button"
            aria-label="Close"
            className="close-btn"
            onClick={() => navigate(-1)}
          >
            <Icon icon="close" size={36} />
          </button>
        </div>
        <div css={spacer.mrT10}>
          {currentQuestion?.type === 'text' ? (
            <PreviewTextBasedQuestion questionNumber={questionNumber} questions={questions} />
          ) : (
            <div>
              <H3 workSans>This question type is not supported &nbsp;</H3>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

QuestionPreviewSection.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionNumber: PropTypes.number.isRequired
};

export default QuestionPreviewSection;
