/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import CreateTestContainer from 'hooks/useCreateTest';
import arrayMove from 'array-move';

import LoaderOverlay from 'components/LoaderOverlay';
import SidebarListItem from 'components/SidebarShell/SidebarListItem';
import SidebarSubSections from 'components/SubSectionSettingsForm/SidebarSubSections';
import { TEST_STRUCTURE } from 'globals/constants';
import useSearchQuery from 'hooks/useSearchQuery';
import { navigate } from 'components/Router';

const SortableItem = SortableElement(({ section }) => {
  const { testSettings } = CreateTestContainer.useContainer();
  const { setQueryParams, useParseQueryString } = useSearchQuery();
  const queryStringData = useParseQueryString();

  let subHeadingText = `${section.totalQuestions} Questions`;

  if (testSettings.questionStructure === TEST_STRUCTURE.subsections) {
    subHeadingText = `${section.subsections?.length || 0} subsections`;
  }

  let handleOnClick = false;
  if (testSettings.questionStructure === TEST_STRUCTURE.sections) {
    const queryString = setQueryParams(
      {
        sectionId: section._id
      },
      true
    );
    handleOnClick = () => {
      navigate(`?${queryString}`);
    };
  }

  return (
    <SidebarListItem
      heading={section.name}
      subHeading={subHeadingText}
      onClick={handleOnClick}
      isActive={
        testSettings.questionStructure === TEST_STRUCTURE.sections &&
        queryStringData.sectionId === section._id
      }
    >
      <SidebarSubSections parentSection={section} />
    </SidebarListItem>
  );
});

const SortableList = SortableContainer(({ sections }) => (
  <div>
    {sections.map((section, index) => (
      <SortableItem key={`item-${section._id}`} index={index} section={section} />
    ))}
  </div>
));

const SidebarSections = () => {
  const { testSettings, updateSectionsOrder } = CreateTestContainer.useContainer();

  const [sectionsList, updateSectionsList] = useState(testSettings.sections);
  // useEffect will update the state if any sections are updated
  useEffect(() => {
    updateSectionsList(testSettings.sections);
  }, [testSettings.sections]);

  const [overlay, toggleOverlay] = useState(false);

  const updateSectionOrder = async ({ oldIndex, newIndex }) => {
    const updatedSectionsList = arrayMove(testSettings.sections, oldIndex, newIndex);

    // Optimistic sections update
    updateSectionsList(updatedSectionsList);

    toggleOverlay(true);
    const data = await updateSectionsOrder(testSettings, updatedSectionsList);

    // If API call fails, set the sections to order returned by API
    if (data) updateSectionsList(data.sections);

    toggleOverlay(false);
  };

  return (
    <div className="is-relative">
      <SortableList
        useWindowAsScrollContainer
        axis="y"
        lockAxis="y"
        distance={2}
        sections={sectionsList}
        onSortEnd={updateSectionOrder}
      />
      {overlay && <LoaderOverlay />}
    </div>
  );
};

export default SidebarSections;
