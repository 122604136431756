import { useState, useCallback, useEffect } from 'react';
import { createContainer } from 'unstated-next';

import { useCreateQuestion, useDeleteQuestion } from 'api/questions';

export const initialSettings = [];

const useCreateQuestions = (initialstate = initialSettings) => {
  const [questions, setQuestions] = useState(initialstate);

  useEffect(() => {
    setQuestions(initialstate);
  }, [initialstate]);

  const { createQuestion } = useCreateQuestion();
  const { deleteQuestion } = useDeleteQuestion();

  const createNewQuestion = useCallback(
    async (settings) => {
      const { response } = await createQuestion(settings);
      const { data } = response;
      return data;
    },
    [createQuestion]
  );

  const deleteQuestion_ = useCallback(
    async (questionId) => {
      const { response } = await deleteQuestion(questionId);
      const { data } = response;

      if (data) {
        // Optimistic removal of question
        setQuestions(questions.filter((obj) => obj._id !== questionId));
      }
      return true;
    },
    [deleteQuestion, questions]
  );

  return {
    questions,
    setQuestions,
    createNewQuestion,
    deleteQuestion: deleteQuestion_
  };
};

const QuestionsContainer = createContainer(useCreateQuestions);

export default QuestionsContainer;
