//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc } from 'styles/utilities';

const settingsSummary = css`
  border: solid 2px ${colors.blackTransparent10};
  border-radius: ${misc.largeBorderRadius};
`;

export default settingsSummary;
