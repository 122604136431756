import { keyframes, css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, misc, mixins, colors } from 'styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

const rotate = keyframes`
  0% {
    transform: rotate(0deg) translate(-50%,-50%);
  }
  100% {
    transform: rotate(360deg) translate(-50%,-50%);
  }
`;

const loaderAnimation = css`
  ${rotate} 1s linear infinite;
`;

const baseButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'loading' && isPropValid(prop)
})`
  display: inline-block;
  vertical-align: middle;
  padding: ${(props) => {
    if (props.large) {
      return '16px 24px';
    }
    if (props.icon) {
      return '10px';
    }
    return `${misc.button.paddingVertical} ${misc.button.paddingHorizontal}`;
  }};
  line-height: ${(props) => {
    if (props.icon) {
      return '1';
    }
    return 'unset';
  }};
  text-align: center;
  border: ${styledMap`
    default : none;
    primary : none;
    secondary : none;
    outline: 1px solid ${colors.navyBlue};
  `};
  border-radius: 0;
  opacity: 1;
  margin: 0;
  border-radius: ${misc.smallBorderRadius};
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  ${mixins.fontHacks()}
  overflow: hidden;
  -webkit-appearance: none;
  transform: translateZ(0);
  ${mixins.transition()}
  width: ${styledMap`
    wide: 100%;
    default: auto;
  `};
  font-family: ${typography.fonts.workSans.fontFamily};
  font-size: ${typography.baseSizes.theta};
  font-weight: ${styledMap`
    medium: ${typography.fonts.workSans.fontWeight.medium};
    default: ${typography.fonts.workSans.fontWeight.regular};
  `};
  background-color: ${styledMap`
    secondary: ${colors.lightBlue};
    default: ${colors.lighterBlue};
    outline: transparent;
  `};
  color: ${styledMap`
    secondary: ${colors.navyBlue};
    default: ${colors.white};
    outline: ${colors.navyBlue};
  `};
  fill: ${styledMap`
    outline: ${colors.navyBlue};
    secondary: ${colors.navyBlue};
    default: ${colors.white};
  `};
  ${mixins.linkActiveStyles} {
    outline: none;
    text-decoration: none;
    color: ${styledMap`
      secondary: ${colors.navyBlue};
      outline : ${colors.navyBlue};
      default: ${colors.white};
    `};
    fill: ${styledMap`
      secondary: ${colors.navyBlue};
      default: ${colors.white};
      outline : ${colors.navyBlue};
    `};
  }
  &.active,
  &:hover {
    background-color: ${styledMap`
      active: ${colors.secondaryLinkHover};
      secondary: ${colors.secondaryLinkHover};
      default: ${colors.primaryLinkHover};
      outline: ${colors.lightBlue};
    `};
  }
  &:active {
    background-color: ${styledMap`
      secondary: ${colors.secondaryLinkActive};
      default: ${colors.primaryLinkActive};
      outline: ${colors.lightBlue};
    `};
  }
  &[disabled] {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: ${colors.white};
    fill: ${colors.white};
    border-color: transparent;
  }

  ${(props) => {
    // Loader
    if (props.loading) {
      return css`
        pointer-events: none;
        opacity: 0.8;
        color: transparent !important;
        fill: transparent !important;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: inherit;
          display: block;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transform-origin: 0% 0%;
          width: 1.5em;
          height: 1.5em;
          display: block;
          border: 2px solid ${props.outline ? colors.navyBlue : colors.white};
          border-left-color: transparent;
          border-radius: 50%;
          animation: ${loaderAnimation};
        }
      `;
    }
    return `

    `;
  }}
  &:after {
    ${() => css`
      animation: ${rotate} 1s linear infinite;
    `}
  }
`;

const Button = styled(baseButton)((props) =>
  breakPoints({
    fontSize: props.large
      ? ['16px', typography.baseSizes.theta]
      : ['14px', typography.baseSizes.theta]
  })
);

export default Button;
