/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import LoaderOverlay from 'components/LoaderOverlay';
import { useParams, navigate } from 'components/Router';

import * as styles from './SubmissionBand.styles';

const SubmissionBand = ({ isSubmitting, submitForm, formSubmitCallbackUrl }) => {
  const routeParams = useParams();

  const handleSaveAndExit = async (event) => {
    event.preventDefault();
    await submitForm();
    if (formSubmitCallbackUrl.length) {
      navigate(`${formSubmitCallbackUrl}`);
    } else {
      navigate(`/test/${routeParams.testId}`);
    }
  };
  return (
    <div
      css={styles.bandWrapper}
      className="d-flex align-items-center justify-content-between is-relative"
    >
      <A color="lighterBlue" semiBold onClick={handleSaveAndExit} href="#">
        Save and Exit
      </A>
      <div>
        <Button type="submit" loading={isSubmitting}>
          Save
        </Button>
      </div>
      {isSubmitting && <LoaderOverlay />}
    </div>
  );
};

SubmissionBand.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  formSubmitCallbackUrl: PropTypes.string
};

SubmissionBand.defaultProps = {
  formSubmitCallbackUrl: ''
};

export default SubmissionBand;
