import { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { api, TEST_STRUCTURE } from 'globals/constants';
import request from 'utils/request';
import { updateTimersInQuestion } from 'utils/questionHelpers';

const useGetQuestions = (levelId, level) => {
  const payload = {};

  if (levelId) {
    if (level === TEST_STRUCTURE.subsections) {
      payload.atLevelSubsectionId = levelId;
    } else if (level === TEST_STRUCTURE.sections) {
      payload.atLevelSectionId = levelId;
    } else {
      payload.testId = levelId;
    }
  }

  const { data, status, error } = useQuery(
    [
      'questions',
      {
        levelId
      }
    ],
    async () => {
      if (!levelId) return null;
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.questions.get,
        payload
      });
      return response;
    }
    // {
    //   enabled,
    //   cacheTime: 1000
    // }
  );

  return { data, status, error };
};

const useGetQuestion = (questionId) => {
  const { data, status, error } = useQuery(['getQuestion', { questionId }], async () => {
    if (!questionId) return null;
    const { response } = await request({
      method: 'GET',
      endpoint: api.endpoints.question.findOne(questionId)
    });
    return response;
  });

  const [questionData, updateQuestionData] = useState(data);
  useEffect(() => {
    if (data) {
      const updatedQuestionData = data;
      updatedQuestionData.data = updateTimersInQuestion(updatedQuestionData.data, {
        from: 'seconds',
        to: 'minutes'
      });
      updateQuestionData(updatedQuestionData);
    }
  }, [data]);

  const memoQuestionData = useMemo(() => questionData || {}, [questionData]);

  return { data: memoQuestionData, status, error };
};

const useCreateQuestion = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (questionSettings) => {
      // Updating timers here
      let updatedQuestionSettings = { ...questionSettings };
      updatedQuestionSettings = updateTimersInQuestion(updatedQuestionSettings, {
        from: 'minutes',
        to: 'seconds'
      });

      const response = await request({
        method: 'POST',
        endpoint: api.endpoints.question.create(),
        payload: updatedQuestionSettings
      });
      return response;
    },
    {
      onSettled(data, err, variables) {
        queryClient.removeQueries('questions');
        queryClient.removeQueries(['testSettings', { testId: variables.testId }]);
      }
    }
  );

  const { mutateAsync, data, error, status } = mutation;
  return { createQuestion: mutateAsync, data, error, status };
};

const useUpdateQuestion = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ questionId, questionData }) => {
      // Updating timers here
      let updatedQuestionData = { ...questionData };
      updatedQuestionData = updateTimersInQuestion(updatedQuestionData, {
        from: 'minutes',
        to: 'seconds'
      });

      const response = await request({
        method: 'PATCH',
        endpoint: api.endpoints.question.update(questionId),
        payload: updatedQuestionData
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        // queryClient.removeQueries('questions');

        queryClient.invalidateQueries([
          'getQuestion',
          {
            questionId: variables.questionId
          }
        ]);

        // const { response } = newData;
        // const { data } = response;

        // // Convert seconds to minutes again
        // const updatedQuestionData = data;
        // updatedQuestionData.data = updateTimersInQuestion(updatedQuestionData.data, {
        //   from: 'seconds',
        //   to: 'minutes'
        // });

        // queryClient.setQueryData(
        //   [
        //     'getQuestion',
        //     {
        //       questionId: variables.questionId
        //     }
        //   ],
        //   () => ({
        //     updatedQuestionData
        //   })
        // );
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { updateQuestion: mutateAsync, data, error, isSuccess };
};

const useReorderQuestion = () => {
  const mutation = useMutation(async ({ questionId, updatedQuestionsOrder }) => {
    const response = await request({
      method: 'POST',
      endpoint: api.endpoints.question.reorder(questionId),
      payload: {
        data: updatedQuestionsOrder
      }
    });
    return response;
  });

  const { mutateAsync, data, error, isSuccess, isLoading } = mutation;

  return { reorderQuestion: mutateAsync, data, error, isSuccess, isLoading };
};

const useDeleteQuestion = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (questionId) => {
      const response = await request({
        method: 'DELETE',
        endpoint: api.endpoints.question.delete(questionId)
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryClient.removeQueries('questions');

        queryClient.invalidateQueries([
          'getQuestion',
          {
            questionId: variables.questionId
          }
        ]);
      }
    }
  );

  const { mutateAsync, data, error, isSuccess } = mutation;
  return { deleteQuestion: mutateAsync, data, error, isSuccess };
};

export {
  useGetQuestions,
  useCreateQuestion,
  useGetQuestion,
  useUpdateQuestion,
  useDeleteQuestion,
  useReorderQuestion
};
