const misc = {
  viewport: {
    'a-vp': '320px',
    'b-vp': '480px',
    'c-vp': '600px',
    'd-vp': '768px',
    'e-vp': '992px',
    'f-vp': '1200px'
  },
  transitions: {
    duration: '0.1s',
    properties: 'all',
    easing: 'linear',
    delay: '0s'
  },
  dropdownZindex: 10,
  navZindex: 100,
  modalZindex: 1000,
  overlayZindex: 1500,
  layoutChangeBreakpoint() {
    return this.viewport['e-vp'];
  },
  containerPaddingBreakpoint() {
    return this.viewport['d-vp'];
  },
  smallerBorderRadius: '2px',
  smallBorderRadius: '4px',
  mediumBorderRadius: '6px',
  largeBorderRadius: '8px',
  defaultTextMargin: '5px 0',
  input: {
    paddingVertical: '12px',
    paddingHorizontal: '10px'
  },

  // added
  button: {
    paddingVertical: '8px',
    paddingHorizontal: '18px'
  },

  // Spacing vars
  gutterSpacings: {
    default: '15px',
    'max-c-vp': '25px'
  },
  rowColumnSpacing: '5px',

  // Site specific vars
  cardSpacing: {
    default: '30px',
    small: '20px'
  },
  border: {
    default: '2px solid rgba(0,0,0,0.2)',
    grey: 'solid 1px #e1e1e1',
    lightGrey: 'solid 2px rgba(0, 0, 0, 0.1)'
  },
  boxShadow: {
    modal: '0 0 5px 0 rgba(0, 0, 0, 0.17)'
  }
};

export default { ...misc };
