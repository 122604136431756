import { TEST_STRUCTURE } from 'globals/constants';

const sectionVisulaizerCardSettings = ({
  showSection = false,
  showSubSections = false,
  isSectionLocked = false,
  isFirstSectionSubSectionLocked = false,
  isSecondSectionFirstSubSectionLocked = false,
  isSecondSectionSecondSubSectionLocked = false,
  title,
  description,
  structure,
  category
} = {}) => ({
  structure,
  category,
  test: {
    section: {
      present: showSection,
      subSections: {
        present: showSubSections
      },
      first: {
        subSection: {
          isLocked: isFirstSectionSubSectionLocked
        }
      },
      second: {
        isLocked: isSectionLocked,
        subSection: {
          first: {
            isLocked: isSecondSectionFirstSubSectionLocked
          },
          second: {
            isLocked: isSecondSectionSecondSubSectionLocked
          }
        }
      }
    }
  },
  title,
  description
});

export const NO_SECTIONS = sectionVisulaizerCardSettings({
  structure: TEST_STRUCTURE.test,
  category: 'test-type',
  title: 'No sections',
  description: 'Directly start with questions; no formal segmentation in between.'
});
export const SINGLE_SECTIONS = sectionVisulaizerCardSettings({
  structure: TEST_STRUCTURE.sections,
  category: 'test-type',
  showSection: true,
  title: 'Single level of sections',
  description: 'Directly start with questions; no formal segmentation in between.'
});
export const SECTIONS_AND_SUBSECTIONS = sectionVisulaizerCardSettings({
  structure: TEST_STRUCTURE.subsections,
  category: 'test-type',
  showSection: true,
  showSubSections: true,
  title: 'Sections and Subsections',
  description: 'Directly start with questions; no formal segmentation in between.'
});
export const SECTION_LOCK = sectionVisulaizerCardSettings({
  category: 'locking-level',
  showSection: true,
  showSubSections: true,
  isSectionLocked: true,
  isSecondSectionFirstSubSectionLocked: true,
  isSecondSectionSecondSubSectionLocked: true,
  title: 'Sections and Subsections',
  description: 'Students can only go ahead after completing one section at a time'
});
export const SUBSECTION_LOCK = sectionVisulaizerCardSettings({
  category: 'locking-level',
  showSection: true,
  showSubSections: true,
  isSectionLocked: true,
  isFirstSectionSubSectionLocked: true,
  isSecondSectionFirstSubSectionLocked: true,
  isSecondSectionSecondSubSectionLocked: true,
  title: 'Sections and Subsections',
  description:
    'Students can only go ahead after completing one subsection from one section at a time'
});
export const SINGLE_SUBSECTION_LOCK = sectionVisulaizerCardSettings({
  category: 'locking-level',
  showSection: true,
  showSubSections: true,
  isFirstSectionSubSectionLocked: true,
  isSecondSectionSecondSubSectionLocked: true,
  title: 'Sections and Subsections',
  description:
    'Students can completed one subsection from each section and then other subsections would open up'
});

// Course
export const COURSE_NO_SECTIONS = sectionVisulaizerCardSettings({
  structure: TEST_STRUCTURE.test,
  category: 'test-type',
  title: 'No sections',
  description: 'Directly start with questions; no formal segmentation in between.'
});
export const COURSE_SINGLE_SECTIONS = sectionVisulaizerCardSettings({
  structure: TEST_STRUCTURE.sections,
  category: 'test-type',
  showSection: true,
  title: 'Single level of sections',
  description: 'Directly start with questions; no formal segmentation in between.'
});
export const COURSE_SECTIONS_AND_SUBSECTIONS = sectionVisulaizerCardSettings({
  structure: TEST_STRUCTURE.subsections,
  category: 'test-type',
  showSection: true,
  showSubSections: true,
  title: 'Sections and Subsections',
  description: 'Directly start with questions; no formal segmentation in between.'
});
