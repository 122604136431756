//* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const container = css`
  display: flex;
  flex-direction: column;
`;

export const tab = css`
  color: ${colors.blueGrey};
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
  transition: all 0.1s ease-in;
  cursor: pointer;
  &.active {
    color: ${colors.lighterBlue};
    border-bottom: 2px solid ${colors.lighterBlue};
  }
  &:hover {
    color: ${colors.checkBoxHover};
  }
  &:active {
    color: ${colors.tabPressed};
  }
`;

export const contentContainer = css`
  ${
    '' /* display: flex;
  justify-content: center; */
  }
`;
