/** @jsxImportSource @emotion/react */
import React from 'react';

import * as styles from './LoaderOverlay.styles';

const LoaderOverlay = () => <div css={styles.loaderOverlay} className="loader-overlay" />;

export const loaderOverlayClass = 'is-relative';

export default LoaderOverlay;
