/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { mix } from 'polished';
import { toRgba } from 'styles/helpers/mixins';
import { colors, misc } from 'styles/utilities';

export const bandContainer = (props) => css`
  border-radius: ${misc.largeBorderRadius};
  outline: 1px solid ${colors.blackTransparent20};
  background: ${colors.white};
  overflow: hidden;
  gap: 20px;
  box-shadow: 0px 1px 2px 0px ${colors.blackTransparent10};

  ${props?.customColor &&
  css`
    background: ${toRgba(mix('0.2', props.customColor, '#ffffff'), 0.5)};
  `};

  &:hover {
    .CTA {
      background: ${colors.navyBlue};
      color: ${colors.white};
    }
  }

  .CTA {
    border: 1px solid ${colors.navyBlue};
  }
`;
