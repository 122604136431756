/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const header = css`
  border-bottom: 1px solid ${colors.blackTransparent10};
  padding: 15px;
`;

export const sidebarContentsWrapper = css`
  overflow-y: auto;
  flex-grow: 1;
`;
