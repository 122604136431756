// import 'utils/wdyr';

import React from 'react';
import ReactDOM from 'react-dom';

// Sentry & Logrocket logging
import 'utils/logging';

import App from './App';

const rootElement = document.getElementById('app');
ReactDOM[rootElement.hasChildNodes() ? 'hydrate' : 'render'](<App />, rootElement);
