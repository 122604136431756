/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc, mixins } from 'styles/utilities';

export const questionBand = (props) => css`
  height: 40px;
  width: 40px;
  margin: 0 5px 5px 0;
  border-radius: ${misc.mediumBorderRadius};
  border: 2px solid ${colors.lighterBlue};
  color: ${props.active ? colors.white : colors.lighterBlue};
  background-color: ${props.active ? colors.lighterBlue : colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  ${mixins.transition()};
  &:hover {
    background-color: ${colors.lighterBlue};
    color: ${colors.white};
    svg * {
      fill: ${colors.white} !important;
    }
  }
`;
