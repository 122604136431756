/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

// components
import Button from 'components/htmlElements/Button';
import A from 'components/htmlElements/A';
import MediaThumbnail from 'components/MediaThumbnail';
import Icon from 'components/Icon';

import Modal, { useModal } from 'components/Modal';
import MediaUploader from 'components/MediaUploader';

import { FieldArray, Field } from 'formik';

// styling
import { spacer, colors } from 'styles/utilities';

const styles = {
  mediaImageWrapper: css`
    position: relative;
    .remove-image {
      position: absolute;
      top: 0;
      right: 0;
    }
  `
};

const InsertComprehensionImages = ({ values }) => {
  const { isOpen, openModal, toggle } = useModal();

  const handleButtonClick = () => {
    openModal();
  };

  const handleInsertMedia = (url, push) => {
    push({ url });
    toggle();
  };

  return (
    <div css={spacer.mrB20}>
      <FieldArray name="questionImages">
        {({ remove, push }) => (
          <Fragment>
            <div className="d-flex">
              {values.questionImages.length > 0 &&
                values.questionImages.map((questionImage, index) => (
                  <div key={index} css={[styles.mediaImageWrapper, spacer.mrB10, spacer.mrR10]}>
                    <MediaThumbnail
                      url={questionImage.url.concat('?auto=format&w=100')}
                      width="100px"
                    />
                    <Field name={`questionImages.${index}.url`} type="hidden" />

                    <A onClick={() => remove(index)} as="span" className="remove-image">
                      <Icon icon="close" color={colors.navyBlue} />
                    </A>
                  </div>
                ))}
            </div>
            <Button onClick={handleButtonClick} type="button">
              Select Images
            </Button>
            <Modal isOpen={isOpen} toggle={toggle} large>
              <MediaUploader
                handleMediaClick={(url) => {
                  handleInsertMedia(url, push);
                }}
              />
            </Modal>
          </Fragment>
        )}
      </FieldArray>
    </div>
  );
};
InsertComprehensionImages.propTypes = {
  values: PropTypes.object.isRequired
};

export default InsertComprehensionImages;
