import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import ResponseEditing from './ResponseEditing';
import ResponseUI from './ResponseUI';

export default class ResponsePlugin extends Plugin {
  static get requires() {
    return [ResponseEditing, ResponseUI];
  }
}
