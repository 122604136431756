/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import P from 'components/htmlElements/P';
import Icon from 'components/Icon/Icon';

// Constants
import { spacer, colors } from 'styles/utilities';
import * as visualizerCard from './variants/variants';

// Styling
import * as styles from './VisualizerCard.styles';

const VisualizerCard = ({ variant, selected, course }) => {
  const showSections = variant.test.section.present;
  const showSubSections = variant.test.section.subSections.present;
  const firstSection = variant.test.section.first;
  const secondSection = variant.test.section.second;

  return (
    <div
      css={[
        styles.container,
        spacer.padT10,
        spacer.padB20,
        spacer.padLR10,
        selected ? styles.selected : null
      ]}
      className="card-container h-100"
    >
      <div
        className="test-tile"
        css={[styles.testTile, spacer.padT10, spacer.padB10, spacer.padLR10, spacer.mrB15]}
      >
        <div css={spacer.mrB10}>
          <P workSans semiBold color="blueGrey" className="text">
            {course ? 'Course' : 'Test'}
          </P>
          {(variant === visualizerCard.NO_SECTIONS ||
            variant === visualizerCard.COURSE_NO_SECTIONS) && (
            <P workSans color="blueGrey" className="text absolute">
              {course ? 'Lessons' : 'Questions'}
            </P>
          )}
        </div>

        {/* first section tile */}
        <div
          className={`${showSections ? '' : 'hide'}`}
          css={[styles.testTile, spacer.padBT10, spacer.padLR10, spacer.mrB10]}
        >
          <div css={spacer.mrB10}>
            <P workSans semiBold color="blueGrey" className="text">
              Section 1
            </P>
            {(variant === visualizerCard.COURSE_SINGLE_SECTIONS ||
              variant === visualizerCard.SINGLE_SECTIONS) && (
              <P workSans color="blueGrey" className="text absolute">
                {course ? 'Lessons' : 'Questions'}
              </P>
            )}
          </div>

          {/* subsection tile */}
          <div
            className={`subsection-tile ${showSubSections ? '' : 'hide'}`}
            css={[styles.testTile, spacer.padBT10, spacer.padLR10, spacer.mrB10]}
          >
            <div>
              <P workSans semiBold color="blueGrey" className="text">
                Subsection 1.1
              </P>
              <P workSans color="blueGrey" className="text">
                {course ? 'Lessons' : 'Questions'}
              </P>
            </div>
          </div>
          {/* subsection tile */}
          <div
            className={`subsection-tile ${showSubSections ? '' : 'hide'}`}
            css={[styles.testTile, spacer.padBT10, spacer.padLR10]}
          >
            <div>
              <P workSans semiBold color="blueGrey" className="text">
                Subsection 1.2
              </P>
              <P workSans color="blueGrey" className="text">
                {course ? 'Lessons' : 'Questions'}
              </P>
            </div>
            {firstSection.subSection.isLocked && (
              <Icon icon="lock" color={colors.lighterBlue} size="37" />
            )}
          </div>
        </div>

        {/* second section tile */}
        <div
          className={`${showSections ? '' : 'hide'}`}
          css={[styles.testTile, spacer.padBT10, spacer.padLR10]}
        >
          <div className="locked-section" css={spacer.mrB10}>
            <div>
              <P workSans semiBold color="blueGrey" className="text">
                Section 2
              </P>
              {(variant === visualizerCard.COURSE_SINGLE_SECTIONS ||
                variant === visualizerCard.SINGLE_SECTIONS) && (
                <P workSans color="blueGrey" className="text absolute">
                  {course ? 'Lessons' : 'Questions'}
                </P>
              )}
            </div>
            {secondSection.isLocked && <Icon icon="lock" color={colors.lighterBlue} />}
          </div>
          {/* subsection tile */}
          <div
            className={`subsection-tile ${showSubSections ? '' : 'hide'}`}
            css={[styles.testTile, spacer.padBT10, spacer.padLR10, spacer.mrB10]}
          >
            <div>
              <P workSans semiBold color="blueGrey" className="text">
                Subsection 2.1
              </P>
              <P workSans color="blueGrey" className="text">
                {course ? 'Lessons' : 'Questions'}
              </P>
            </div>
            {secondSection.subSection.first.isLocked && (
              <Icon icon="lock" color={colors.lighterBlue} size="37" />
            )}
          </div>
          {/* subsection tile */}
          <div
            className={`subsection-tile ${showSubSections ? '' : 'hide'}`}
            css={[styles.testTile, spacer.padBT10, spacer.padLR10]}
          >
            <div>
              <P workSans semiBold color="blueGrey" className="text">
                Subsection 2.2
              </P>
              <P workSans color="blueGrey" className="text">
                {course ? 'Lessons' : 'Questions'}
              </P>
            </div>
            {secondSection.subSection.second.isLocked && (
              <Icon icon="lock" color={colors.lighterBlue} size="37" />
            )}
          </div>
        </div>
      </div>
      <div css={styles.cardBottomSection}>
        <P workSans medium className="m-0" color={`${selected ? 'lighterBlue' : 'black'}`}>
          {variant.title}
        </P>
        <div css={spacer.mrB20}>
          <P workSans medium small color="blackTransparent60" className="m-0 text-center">
            {variant.description}
          </P>
        </div>
      </div>
    </div>
  );
};

VisualizerCard.propTypes = {
  variant: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  course: PropTypes.bool
};

VisualizerCard.defaultProps = {
  course: false
};

export default VisualizerCard;
