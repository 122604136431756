/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { colors, mixins, misc, typography, spacer } from 'styles/utilities';

// new color codes [not defined in the site]

export const previewPill = css`
  width: 10px;
  height: 20px;
  background-color: #606060;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  ${mixins.transition()}
  &.current {
    background-color: ${colors.green};
  }
`;

export const questionFooter = css`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 10px solid ${colors.green};
  background-color: ${colors.white};
  padding: 7px 0;
  ${spacer.padLR20}
  z-index: ${misc.navZindex};
`;

export const navBtn = css`
  border: 2px solid #54a65d;
  padding: 6px 0;
  ${spacer.padLR20}
  border-radius: ${misc.largeBorderRadius};
  background-color: #dff9f2;
  color: #222;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #88d8c2;
  }
  &.hide {
    pointer-events: none;
    opacity: 0;
  }
`;

export const previewSection = css`
  ${'' /* 62px = height of site navbar */}
  min-height: calc(100vh - 62px);
  border: 10px solid ${colors.green};
  border-bottom: none;
  .close-btn {
    border: none;
    ouline: none;
    background: transparent;
    width: auto;
  }
`;

export const inlineDiv = css`
  display: inline;
`;

export const questionText = css`
  p {
    font-family: ${typography.fonts.workSans.fontFamily};
  }
`;

export const clearfix = css`
  &.clearfix {
    clear: both;
  }
`;

export const correctAnswers = css`
  p {
    font-family: ${typography.fonts.workSans.fontFamily};
    font-weight: bold;
    display: inline;
    font-size: ${typography.baseSizes.eta};
  }
  p.correct-title {
    font-weight: normal;
    display: inline;
  }
`;

export const optionStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  border: 4px solid #ff5200;
  border-radius: ${misc.smallBorderRadius};
  ${spacer.padBT10}
  ${spacer.padLR10}
  height: 90px;
  min-width: 100px;
  cursor: pointer;
  &.correct {
    border: 4px solid #2ecc71;
    background-color: rgba(46, 204, 113, 0.5);
  }
  p {
    font-family: ${typography.fonts.workSans.fontFamily};
  }
`;

export const solutionExplanation = css`
  p {
    font-size: ${typography.baseSizes.iota};
    font-family: ${typography.fonts.workSans.fontFamily};
  }
`;
