/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { omit } from 'lodash-es';
import * as Yup from 'yup';

import { useUpdateUser } from 'api/user';
import { UserInfoContainer } from 'api/auth';

import H2 from 'components/htmlElements/H2';
import { FieldWrapper, FieldLabel, Input, FieldError } from 'components/htmlElements/Fields';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
// import StatusPill from 'components/StatusPill';

import { spacer } from 'styles/utilities';
import * as modalStyles from 'components/Modal/Modal.styles';
import * as styles from './ProfileModal.styles';

const ProfileSchema = Yup.object().shape({
  name: Yup.string().required('Name required')
});

const ProfileModal = ({ closeModal, userInfo }) => {
  const { updateUser } = useUpdateUser();
  const { setUserInfo } = UserInfoContainer.useContainer();

  const handleSubmit = async (values, actions) => {
    const userId = userInfo._id;
    const dataToSend = omit(values, 'email', 'role');
    const updatedUserData = { userId, dataToSend };
    const { response } = await updateUser(updatedUserData);
    if (response) {
      setUserInfo({ ...userInfo, name: response.data.name });
      actions.setSubmitting(false);
      closeModal();
    }
  };

  const getCapitalizedString = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div>
      <div css={modalStyles.header}>
        <H2>Update Profile</H2>
      </div>
      <Formik
        validationSchema={ProfileSchema}
        initialValues={{
          name: userInfo.name,
          email: userInfo.email,
          role: getCapitalizedString(userInfo.roles[0])
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting, touched }) => (
          <Form>
            <div css={[spacer.mrLR20, spacer.mrT20]}>
              <FieldWrapper>
                <FieldLabel className="placeholder" htmlFor="user-name" required>
                  Name
                </FieldLabel>
                <Field name="name" id="user-name" as={Input} />
                {errors.name && touched.name && <FieldError>{errors.name}</FieldError>}
              </FieldWrapper>

              <FieldWrapper>
                <FieldLabel className="placeholder" required>
                  Email
                </FieldLabel>
                <Field name="email" id="user-name" as={Input} disabled css={styles.noBorder} />
              </FieldWrapper>

              <FieldWrapper>
                <FieldLabel className="placeholder" htmlFor="user-name" required>
                  Role
                </FieldLabel>
                <Field name="role" id="user-name" as={Input} disabled css={styles.noBorder} />
              </FieldWrapper>

              <div className="d-flex justify-content-end align-items-center">
                <A as="span" onClick={closeModal} css={spacer.mrR20}>
                  Cancel
                </A>
                <Button type="submit" loading={isSubmitting}>
                  Update
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ProfileModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired
};

export default ProfileModal;
