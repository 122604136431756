/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc } from 'styles/utilities';

export const simpleDropdownGlobalStyles = css`
  .dropdown {
    display: inline-block;
  }

  .dropdown__content {
    display: none;
    position: absolute;
  }

  .dropdown--active .dropdown__content {
    display: block;
  }
`;

export const dropdownContentStyles = css`
  width: 100%;
  position: relative;
  .dropdown__content {
    background-color: ${colors.white};
    border-radius: ${misc.mediumBorderRadius};
    ${'' /* border: 2px solid ${colors.blackTransparent20}; */}
    box-shadow: 0 0 5px 0 ${colors.blackTransparent20};
    overflow: hidden;
    min-width: 100%;
    &.left-aligned {
      left: 0;
    }
    &.right-aligned {
      right: 0;
    }
  }
  .dropdown-segment {
    padding: 15px;
    cursor: pointer;
    .dropdown--active & {
      display: block;
    }
    &:hover {
      background-color: ${colors.lightGrey};
    }
    &:not(:last-child) {
      border-bottom: 2px solid ${colors.blackTransparent20};
    }
  }
`;

export const simpleDropdownStyledItem = css`
  display: block;
  white-space: nowrap;
`;
