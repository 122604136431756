// import styled from '@emotion/styled';
import styled from '@emotion/styled';
import facepaint from 'facepaint';
import styledMap from 'styled-map';
import isPropValid from '@emotion/is-prop-valid';
import { typography, colors, mixins, misc } from 'styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

const H3 = styled('h3', {
  shouldForwardProp: (prop) => isPropValid(prop)
})`
  font-family: ${styledMap`
  gtWalsheim: ${typography.fonts.gtWalsheim.fontFamily};
  workSans: ${typography.fonts.workSans.fontFamily};
  default: ${typography.fonts.cooperBT.fontFamily};
  `};
  font-weight: ${(props) => {
    if (props.workSans) {
      return typography.fonts.workSans.fontWeight.semiBold;
    }
    if (props.gtWalsheim) {
      if (props.bold) {
        return typography.fonts.gtWalsheim.fontWeight.bold;
      }
      if (props.medium) {
        return typography.fonts.gtWalsheim.fontWeight.medium;
      }
      return typography.fonts.gtWalsheim.fontWeight.light;
    }
    return typography.fonts.cooperBT.fontWeight.light;
  }};
  line-height: 135%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['20px', typography.baseSizes.delta]
  })};
`;

export default H3;
