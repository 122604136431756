/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import Layout from 'layouts';
import H1 from 'components/htmlElements/H1';
import H4 from 'components/htmlElements/H4';
import Button from 'components/htmlElements/Button';

import { spacer, colors } from 'styles/utilities';
// import notFoundImage from 'images/not-found.png';

const styles = {
  container: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  image: css`
    width: 100%;
    max-width: 250px;
    margin-bottom: 30px;
  `
};

export default () => (
  <Layout>
    <div css={[styles.container, spacer.padBT80]}>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center">
            <div className="col-6">
              <H1>
                <H1 as="span" color={colors.red}>
                  404 Error
                </H1>
                <br /> Page Not Found
              </H1>
              <br />
              <H4 gtWalsheim>This could be because either:</H4>
              <br />
              <H4 gtWalsheim>The link you&apos;ve put in is wrong. Try checking the spelling.</H4>
              <hr />
              <H4 gtWalsheim>The link address has been updated or changed.</H4>
              <br />
              <Button as="a" href="/">
                Go Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
