import styled from '@emotion/styled';
import styledMap from 'styled-map';
import { colors } from 'styles/utilities';

const FieldLabel = styled.label`
  font-size: 12px;
  text-align: left;
  color: ${colors.blackTransparent50};
  padding: 0;
  position: ${styledMap`
    absolute: absolute;
    default: relative;
  `};
  top: ${styledMap`
    absolute: 100%;
    default: auto;
  `};
  left: ${styledMap`
    absolute: 0;
    default: auto;
  `};

  ${(props) => {
    // Loader
    if (props.required) {
      return `
        &:after {
          content: ' *';
          color: ${colors.red};
        }
      `;
    }
    return `

    `;
  }}
`;

export default FieldLabel;
