/* eslint-disable import/prefer-default-export */
import { keyframes, css } from '@emotion/react';

import { colors, mixins, typography } from 'styles/utilities';

const checkmarkCheckAnimation = keyframes`
  0% {
    stroke-dashoffset: 48;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

export const label = css`
  .label-text {
    font-family: ${typography.fonts.workSans.fontFamily};
    font-weight: ${typography.fonts.workSans.fontWeight.medium};
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    display: none;
    &:checked,
    &:indeterminate {
      + .checkbox-box {
        border-color: ${colors.primaryLinkActive};
        background-color: ${colors.primaryLinkActive};
      }
    }
    &:checked {
      &:hover {
        + .checkbox-box {
          background-color: ${colors.checkBoxHover};
          border-color: ${colors.checkBoxHover};
        }
      }
      + .checkbox-box .checkmark_check {
        opacity: 1;
        stroke: ${colors.white};
        animation: ${checkmarkCheckAnimation} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0s forwards;
      }
    }
    &:indeterminate {
      + .checkbox-box {
        &:after {
          content: '';
          width: 10px;
          height: 2px;
          background-color: ${colors.white};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .checkmark_check {
          display: none;
        }
      }
    }
  }
`;

export const checkboxBox = css`
  width: 18px;
  height: 18px;
  border: 2px solid ${colors.blackTransparent50};
  border-radius: 3px;
  display: inline-block;
  position: relative;
  ${mixins.transition()}

  &:hover {
    border-color: ${colors.blackTransparent20};
  }
`;

export const checkmark = css`
  overflow: visible;
  position: absolute;
  top: 50%;
  left: 2px;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  .checkmark_check {
    stroke-dasharray: 48;
    opacity: 0;
    stroke-width: 5px;
    transform-origin: center;
    fill: transparent;
    ${mixins.transition()}
  }
`;
